import FormDual from "@/forms/FormDual";

export default class CreateFormDual
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        this.v.$store.commit('lensReturn/setFormDual', new FormDual());

        return true;
    }
}
