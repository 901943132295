import { merge } from 'lodash-es';
import axios from '@/axios';
import constants from '@/constants/constants';
import UnknownPrototypeError from '@/errors/UnknownPrototypeError';
import Order from './Order';
import ReturnQuestionnaire from './ReturnQuestionnaire';

/**
 * @class
 */
export default class Return
{
    pricing                     = false;

    order                       = null;
    return_by                   = null;
    id                          = null;
    ide                         = null;
    reference                   = null;
    return_reason_code          = null;
    return_exception_code       = null;
    rdate                       = null;
    price                       = null;
    special_price               = null;
    currency_code               = null;
    price_message               = null;
    status                      = null;
    ecom                        = null;
    icom                        = null;
    user_id                     = null;
    user_app_code               = null;
    position                    = null;
    return_questionnaire        = null;

    // ------------------------------------------------------------------------- CONSTRUCTOR

    constructor(order, attributes = {})
    {
        this.order = order;
        this.return_by = order.order_by;
        this.return_questionnaire = new ReturnQuestionnaire(this.return_by, this.order.article.return_questionnaire_code);

        if(attributes)
        {
            this.setAttributes(attributes);
        }
    }

    //-------------------------------------------------------------------------- ATTRIBUTES

    setAttributes(attributes)
    {
        for(let key in attributes)
        {
            if(this[key] !== undefined && ['order', 'return_by', 'return_questionnaire'].indexOf(key) == -1)
            {
                this[key] = attributes[key];
            }
            else if(key == 'return_questionnaire_answers')
            {
                // @todo
            }
        }
    }

    //-------------------------------------------------------------------------- RETURN QUESTIONNAIRE

    getReturnQuestionnaire()
    {
        return this.return_questionnaire;
    }

    //-------------------------------------------------------------------------- ORDER

    getOrder()
    {
        return this.order;
    }

    //-------------------------------------------------------------------------- TEMPLATE

    template(isInitialTemplate = false)
    {
        if(isInitialTemplate)
        {
            // @todo
        }

        const returnQuestionnaire = this.getReturnQuestionnaire();

        return returnQuestionnaire.template().then(response =>
        {
            const values = returnQuestionnaire.getValues();
            this.setAttributes(values);

            return Promise.resolve(response);
        });
    }

    //-------------------------------------------------------------------------- PRICE

    resetPrice()
    {
        this.setAttributes({
            price:         null,
            price_message: null,
        });
    }

    fetchPrice()
    {
        console.log('Pricing start');

        // Set "validating" status
        this.pricing = true;

        // Build URL
        const url = ('/api/return/price/:eid')
            .replace(':eid', this.return_by);

        // Get parameter values.
        const data = {
            returns: [this.toApi()],
            options: {},
        };

        return axios.post(url, data)
            .then(response =>
            {
                console.log('Pricing: response from CS Orders:', response.data);

                this.setAttributes(response.data[0]);

                return Promise.resolve(response);
            })
            .catch(error =>
            {
                console.log('Pricing error', error);

                this.resetPrice();

                return Promise.reject(error);
            })
            .finally(() =>
            {
                this.pricing = false;
            });
    }

    //-------------------------------------------------------------------------- SAVE

    //-------------------------------------------------------------------------- API

    toApi()
    {
        let apiReturn = {
            id:                           this.id,
            ide:                          this.ide,
            reference:                    this.reference,
            return_reason_code:           this.return_reason_code,
            return_exception_code:        this.return_exception_code,
            rdate:                        this.rdate,
            price:                        this.price,
            special_price:                this.special_price,
            currency_code:                this.currency_code,
            ecom:                         this.ecom,
            icom:                         this.icom,
            user_id:                      this.user_id,
            user_app_code:                this.user_app_code,
            order_reference:              this.order.reference,
            position:                     this.position,
            return_questionnaire_answers: [],
        };

        const parameters = this.getReturnQuestionnaire().getParameters();

        parameters.forEach((parameter, code) =>
        {
            if(parameter.data_section == 'return_question')
            {
                apiReturn.return_questionnaire_answers.push({
                    question_code: code,
                    answer:        parameter.getValue(),
                });
            }
        });

        return apiReturn;
    }
}
