import axios from '@/axios';
import Order from '@/models/Order';
import Return from '@/models/Return';
import LensReturnUrlManager from '../LensReturnUrlManager';

export default class FetchOrderByReference
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        /** @type {LensReturnUrlManager} */
        const lensReturnUrlManager = this.v.lensReturnUrlManager;

        // Skip unless there's an order reference in the URL
        if(!lensReturnUrlManager.hasOrderReference())
        {
            console.log(`No order reference in URL`);
            return true;
        }

        const urlOrderReference = lensReturnUrlManager.getOrderReference();
        console.log(`There is an order reference in URL: ${urlOrderReference}`);

        return axios.get(`/api/order/reference/${urlOrderReference}`)
            .then(response =>
            {
                const order = new Order(response.data);

                // If an order is returned but there is no prototype code, order is not a lens.
                if(!order.article_data.hasOwnProperty('PROTOTYPE_CODE'))
                {
                    this.v.orderIdentifierFormError = this.v.$t('return.form.error.not_a_lens_order');

                    console.log(`Given order reference is not a lens order`);

                    return false;
                }

                if(order.has_been_returned)
                {
                    this.v.orderIdentifierFormError = this.v.$t('return.form.error.already_returned');

                    return false;
                }

                this.v.leftReturn = new Return(order);

                console.log(`Main return has been set`);

                return true;
            })
            .catch(error =>
            {
                this.v.leftReturn = null;
                this.v.orderIdentifierFormError = this.v.$t('return.form.error.not_found');

                return false;
            });
    }
}
