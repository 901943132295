<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->
<!-- eslint-disable vue/no-v-html -->

<template>
    <div class="card">
        <div class="card-header">
            <div class="card-header__icon-wrapper">
                <mdi-icon icon="mdiStar" class="card-header__icon" />
            </div>

            <div class="card-header__title">
                {{ $t('home.widgets.shortcuts.title') }}
            </div>
        </div>

        <div class="shortcuts">
            <router-link :to="{ name: 'order-lenses', params: $route.params }" class="shortcut">
                <div class="shortcut__title" v-html="$t('home.widgets.shortcuts.shortcut.order_lenses.label')"></div>
            </router-link>

            <router-link v-can="{ permission: 'price-list:view' }" :to="{ name: 'price-list', params: $route.params }" class="shortcut">
                <div class="shortcut__title" v-html="$t('home.widgets.shortcuts.shortcut.price_list.label')"></div>
            </router-link>

            <router-link :to="{ name: 'my-account', params: $route.params }" class="shortcut">
                <div class="shortcut__title" v-html="$t('my_account.page_title')"></div>
            </router-link>

            <div class="shortcut-spacer"></div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
export default
{
    name: 'ShortcutsWidget',
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.card
{
    min-height: 300px;
    @apply w-1/2 mb-8 mr-8;
}

.shortcuts
{
    @apply w-full flex flex-wrap justify-evenly p-4;
}

.shortcut
{
    @apply w-full flex items-center border-primary-100 border rounded-md mb-4 px-4 py-2 text-sm text-primary-500 cursor-pointer;
}

.shortcut-spacer
{
    width: 120px;
}

.shortcut:hover
{
    @apply bg-primary-10;
}

.shortcut__title
{
    @apply text-center leading-relaxed;
}
</style>
