import { clone } from 'lodash-es';

export default class Parameter
{
    // Parameter properties
    code                   = null;
    data_section           = null;
    default_value          = null;
    force_defined_value    = false;
    defined_value          = null;
    defined_value_label    = null;
    form_display           = null;
    form_label             = null;
    form_splitted_display  = null;
    formatters             = null;
    grouping_key           = null;
    has_access             = null;
    help                   = null;
    is_enabled             = null;
    is_grouped             = null;
    is_required            = null;
    list_items             = [];
    suggested_items        = [];
    max                    = null;
    min                    = null;
    nb_decimals            = null;
    note                   = null;
    prefix                 = null;
    suffix                 = null;
    priced_article_code    = null;
    required_article_codes = [];
    section                = null;
    tab                    = null;
    line                   = null;
    sort_order             = null;
    step                   = null;
    unit                   = null;
    value_type             = null;

    value = null;

    oldValue = null; // Value is copied here after every template() call.

    error = null;

    // ------------------------------------------------------------ CONSTRUCTOR

    constructor(properties = {})
    {
        this.setProperties(properties);
    }

    // ------------------------------------------------------------ PARAMETER PROPERTIES

    setProperties(properties)
    {
        const oldDefaultValue = this.default_value;

        for(const key in properties)
        {
            if(this[key] === undefined)
            {
                continue;
            }

            this[key] = properties[key];

            // If current property is "list_items" and is not empty.
            if(key === 'list_items' && this[key].length)
            {
                const enabledItems = [];

                // Check that the current parameter value exists in list items
                // and that is not equal to a disabled item. If not found or
                // equal to a disabled item, set current value to NULL.
                let found = false;

                for(const item of properties[key])
                {
                    if(item.is_enabled)
                    {
                        enabledItems.push(item);
                    }

                    if(item.ovalue == this.value && item.is_enabled)
                    {
                        found = true;
                        break;
                    }
                };

                if(!found) // If current value was not found in enabled items => reset it.
                {
                    this.setValue(null);
                }

                if(enabledItems.length === 1) // If there is only one enabled item => select it.
                {
                    this.setValue(enabledItems[0].ovalue);
                }
            }

            // If a "defined value" is given, force replacement of value.
            if(key === 'defined_value' && this.force_defined_value)
            {
                this.setValue(properties[key], true);
            }

            // If a "default value" is given and value is "NULL", set value with default value.
            if(key === 'default_value' && this.value === null && properties[key] !== null)
            {
                this.setValue(properties[key]);
            }
        }

        // If default value has changed and current value is equal to old default value
        // => Set value to new default value
        if(this.value === oldDefaultValue)
        {
            this.setValue(this.default_value);
        }
    }

    // ------------------------------------------------------------ VALUE

    setValue(value, bypassDefinedValueCheck = false)
    {
        if(value === null || value === '' || value === ' ')
        {
            this.value = null;
        }
        else
        {
            this.value = this.castValue(value);
        }
    }

    getValue()
    {
        return this.value;
    }

    castValue(value)
    {
        switch(this.value_type)
        {
            case 'boolean':
                return !!value;

            case 'integer':
                return parseInt(value);

            case 'float':
                return parseFloat(value);

            case 'string':
                return `${value}`;

            case 'json':
                return value;

            default:
                return value;
        }
    }

    // ------------------------------------------------------------ OLD VALUE

    setOldValueFromValue()
    {
        if(typeof this.value === 'object')
        {
            this.oldValue = clone(this.value);
        }
        else
        {
            this.oldValue = this.value;
        }
    }

    getOldValue()
    {
        return this.oldValue;
    }

    // ------------------------------------------------------------ DEFAULT VALUE

    getDefaultValue()
    {
        return this.default_value;
    }

    // ------------------------------------------------------------ SECTION

    getSectionCode()
    {
        return this.section ?? 'NONE';
    }

    // ------------------------------------------------------------ LINE

    getLine()
    {
        return this.line;
    }

    // ------------------------------------------------------------ ERROR

    setError(error)
    {
        this.error = error;
    }

    getError()
    {
        return this.error;
    }

    clearError()
    {
        this.error = null;
    }
}
