<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <main-layout>
        <div class="sl-page w-4/5">
            <!-- Page Header -->
            <div class="sl-page__header">
                <div class="sl-page__title">
                    {{ $t('price-list.title') }}
                </div>
            </div>

            <div v-for="category in pdfLinks" :key="category" class="w-full mb-3 p-3 border-2 border-primary-200 text-gray-600 text-sm text-center">
                {{ $t(`price-list.new.${category}`) }} <a target="_blank" class="text-primary-500" :href="`/catalog/entity/new/pdf/${cEntity.id}/${category}`">{{ $t('common.here') }}</a>.
            </div>

            <!-- Page Content -->
            <div class="sl-page__content">
                <!-- Filters -->
                <div class="sl-price-list__filters">
                    <!-- Text Search -->
                    <div class="sl-price-list__search-box">
                        <mdi-icon icon="mdiMagnify" />

                        <input
                            ref="searchField"
                            v-model="searchText"
                            type="text"
                            class="sl-price-list__search-field"
                            tabindex="0"
                            :placeholder="$t('common.search_ellipsis')"
                        >
                    </div>

                    <div class="flex justify-between gap-1">
                        <div class="flex flex-wrap justify-start items-center gap-1">
                            <!-- Level 1 (like secondary menu) -->
                            <div
                                v-for="filter in availableFilters.level1"
                                :key="filter"
                                class="sl-price-list__tag-filter sl-price-list__tag-filter--group-1"
                                :class="{ 'sl-price-list__tag-filter--active': selectedTags.level1 === filter }"
                                tabindex="0"
                                @click="toggleFilter('level1', filter)"
                                @keypress.enter="toggleFilter('level1', filter)"
                                @keypress.space.prevent="toggleFilter('level1', filter)"
                            >
                                {{ getTagLabel(filter) }}
                            </div>

                            <div class="w-full"></div>

                            <div
                                v-has-active-articles-by-tag="'SUPPLEMENT'"
                                class="sl-price-list__tag-filter sl-price-list__tag-filter--group-1"
                                :class="{ 'sl-price-list__tag-filter--active': selectedTags.level1 === 'SUPPLEMENT' }"
                                tabindex="0"
                                @click="toggleFilter('level1', 'SUPPLEMENT')"
                                @keypress.enter="toggleFilter('level1', 'SUPPLEMENT')"
                                @keypress.space.prevent="toggleFilter('level1', 'SUPPLEMENT')"
                            >
                                {{ $t('articles.type.SUPPLEMENT.label', 2) }}
                            </div>

                            <div
                                v-has-active-articles-by-tag="'CLEANING_PRODUCT'"
                                class="sl-price-list__tag-filter sl-price-list__tag-filter--group-1"
                                :class="{ 'sl-price-list__tag-filter--active': selectedTags.level1 === 'CLEANING_PRODUCT' }"
                                tabindex="0"
                                @click="toggleFilter('level1', 'CLEANING_PRODUCT')"
                                @keypress.enter="toggleFilter('level1', 'CLEANING_PRODUCT')"
                                @keypress.space.prevent="toggleFilter('level1', 'CLEANING_PRODUCT')"
                            >
                                {{ $t('price-list.subtitles.cleaning_products') }}
                            </div>

                            <div
                                v-has-active-articles-by-tag="'ACCESSORY'"
                                class="sl-price-list__tag-filter sl-price-list__tag-filter--group-1"
                                :class="{ 'sl-price-list__tag-filter--active': selectedTags.level1 === 'ACCESSORY' }"
                                tabindex="0"
                                @click="toggleFilter('level1', 'ACCESSORY')"
                                @keypress.enter="toggleFilter('level1', 'ACCESSORY')"
                                @keypress.space.prevent="toggleFilter('level1', 'ACCESSORY')"
                            >
                                {{ $t('price-list.subtitles.accessories') }}
                            </div>

                            <div
                                v-has-active-articles-by-tag="'TOPOGRAPHER'"
                                class="sl-price-list__tag-filter sl-price-list__tag-filter--group-1"
                                :class="{ 'sl-price-list__tag-filter--active': selectedTags.level1 === 'TOPOGRAPHER' }"
                                tabindex="0"
                                @click="toggleFilter('level1', 'TOPOGRAPHER')"
                                @keypress.enter="toggleFilter('level1', 'TOPOGRAPHER')"
                                @keypress.space.prevent="toggleFilter('level1', 'TOPOGRAPHER')"
                            >
                                {{ $t('price-list.subtitles.topographers') }}
                            </div>
                        </div>

                        <div class="w-[10vw]"></div>

                        <div class="flex flex-wrap justify-end items-center gap-1">
                            <!-- Level 2 -->
                            <div
                                v-for="filter in availableFilters.level2"
                                :key="filter"
                                class="sl-price-list__tag-filter sl-price-list__tag-filter--group-2"
                                :class="{ 'sl-price-list__tag-filter--active': selectedTags.level2 === filter }"
                                tabindex="0"
                                @click="toggleFilter('level2', filter)"
                            >
                                {{ getTagLabel(filter) }}
                            </div>

                            <div class="w-full"></div>

                            <!-- Level 3 -->
                            <div
                                v-for="filter in availableFilters.level3"
                                :key="filter"
                                class="sl-price-list__tag-filter sl-price-list__tag-filter--group-3"
                                :class="{ 'sl-price-list__tag-filter--active': selectedTags.level3 === filter }"
                                tabindex="0"
                                @click="toggleFilter('level3', filter)"
                            >
                                {{ getTagLabel(filter) }}
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Price Sections -->
                <div
                    v-for="(articles, section) in articlesBySection"
                    :key="section"
                    class="price-section"
                >
                    <div
                        class="section-title"
                        :class="{ 'section-title--open': show[section] }"
                        @click="toggleSection(section)"
                    >
                        <!-- Expand/Collapse Icon -->
                        <mdi-icon
                            icon="mdiChevronRight"
                            class="inline-block align-middle w-5 h-5 transform transition"
                            :class="{ 'rotate-90': show[section] }"
                        />

                        <!-- Subtitle -->
                        <span class="inline-block align-middle">
                            {{ $t(`price-list.subtitles.${section}`) }}
                        </span>
                    </div>

                    <template v-if="articles.length">
                        <!-- Articles -->
                        <div v-if="show[section]" class="mb-6">
                            <div v-for="article in articles" :key="article.id" class="article flex">
                                <div class="article-label">
                                    {{ article.label }}
                                </div>

                                <div class="article-price">
                                    {{ article.price.toFixed(2) }}&nbsp;{{ article.currency_code }}
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-else>
                        <!-- No Articles -->
                        <div v-if="show[section]" class="mb-6">
                            <div class="article">
                                <div class="text-gray-300 text-sm italic">
                                    {{ $t('common.search.no_result') }}
                                </div>
                            </div>
                        </div>
                    </template>
                </div>

                <!-- Price Groups -->
                <div v-is-staff class="sl-price-groups">
                    <div class="sl-price-groups__title">
                        {{ $t('common.price_groups') }}
                    </div>

                    <table>
                        <tr v-for="priceGroup in priceGroups" :key="priceGroup.id">
                            <td class="sl-price-groups__label">
                                {{ priceGroup.label }}

                                <template v-if="!priceGroup.label.includes(priceGroup.currency_code)">
                                    [{{ priceGroup.currency_code }}]
                                </template>
                            </td>

                            <td class="sl-price-groups__code">
                                {{ priceGroup.code }}
                            </td>
                        </tr>
                    </table>
                </div>

                <!-- Price List Warning -->
                <div class="mx-4 mt-10 mb-4 text-gray-500 leading-relaxed text-sm text-center">
                    {{ $t('price-list.price_list_warning') }}
                </div>
            </div>
        </div>
    </main-layout>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import Fuse       from 'fuse.js';
import axios      from '@/axios';
import constants  from '@/constants/constants';
import MainLayout from '@/layouts/MainLayout.vue';

/** Articles with these tags can appear in the price list even if they're inactive. */
const ALLOWED_INACTIVE_ARTICLE_TAGS = [
    'NIGHTFLEX',
];

export default
{
    name: 'PriceList',

    components:
    {
        MainLayout,
    },

    data()
    {
        return {
            selectedTags: {
                level1: null,
                level2: null,
                level3: null,
            },
            searchText: '',
            show:
            {
                lenses:            true,
                cleaning_products: true,
                accessories:       true,
                supplements:       true,
                topographers:      true,
            },
            priceGroups: [],
            pdfLinks:    [],
        };
    },

    computed:
    {
        cEntity()
        {
            return this.$store.state.account.cEntity;
        },

        availableFilters()
        {
            const filters = {
                level1: [],
                level2: [],
                level3: [],
            };

            // Only offer filters for which the user has active articles
            for(const level in constants.lensFilters)
            {
                for(const filter of constants.lensFilters[level])
                {
                    if(this.$store.getters['catalog/hasActiveArticlesByTag'](filter))
                    {
                        filters[level].push(filter);
                    }
                }
            }

            return filters;
        },

        articlesBySection()
        {
            // Apply tag filters
            const allArticlesFiltered = Object.values(this.selectedTags)
                .flatMap(selectedTags => selectedTags)
                .filter(t => t !== null)
                .reduce(
                    (carry, selectedTag) => carry.filter(a => a.tags.some(t => t.code === selectedTag)),
                    this.$store.state.catalog.articles
                        .filter(
                            a => a.status !== constants.articles.status.INACTIVE ||
                                a.tags.some(t => ALLOWED_INACTIVE_ARTICLE_TAGS.includes(t.code))
                        )
                );

            // Apply text search
            const allArticlesSearched = (this.searchText.length
                ? new Fuse(allArticlesFiltered, {
                    shouldSort:     false,
                    threshold:      0.1,
                    ignoreLocation: true,
                    keys:           ['label'],
                }).search(this.searchText).map(r => r.item)
                : allArticlesFiltered)
                .sort((a, b) => a.label < b.label ? -1 : (a.label > b.label ? 1 : 0));

            // Retrieve all articles
            const articleArticles = allArticlesSearched.filter(a => a.type === constants.articles.type.ARTICLE);

            // Retrieve all supplements
            const supplementArticles = allArticlesSearched.filter(a => a.type === constants.articles.type.SUPPLEMENT);

            // Prepare the "Lenses" section, with filters applied
            const lenses = articleArticles.filter(a => a.tags.some(t => t.code === 'LENS'));

            // Prepare the "Supplements" section, with filters applied
            const supplements = supplementArticles;

            // Prepare the "Cleaning Products" section, with filters applied
            const cleaning_products = articleArticles.filter(a => a.tags.some(t => t.code === 'CLEANING_PRODUCT'));

            // Prepare the "Accessories" section, with filters applied
            const accessories = articleArticles.filter(a => a.tags.some(t => t.code === 'ACCESSORY'));

            // Prepare the "Topographers" section, with filters applied
            const topographers = articleArticles.filter(a => a.tags.some(t => t.code === 'TOPOGRAPHER'));

            return {
                lenses,
                supplements,
                cleaning_products,
                accessories,
                topographers,
            };
        },
    },

    created()
    {
        if(this.$store.getters['account/isStaff'])
        {
            this.fetchPriceGroups();
        }

        this.fetchNewPriceListPdfLinks();
    },

    mounted()
    {
        // Autofocus search field
        this.$nextTick(() =>
        {
            this.$refs.searchField.focus();
        });
    },

    methods:
    {
        toggleFilter(level, tag)
        {
            this.selectedTags[level] = (this.selectedTags[level] === tag) ? null : tag;
        },

        toggleSection(section)
        {
            if(typeof this.show[section] === 'undefined')
            {
                return;
            }

            this.show[section] = !this.show[section];
        },

        fetchPriceGroups()
        {
            const eid = this.cEntity.id;
            axios.get(`/api/entity/${eid}/price-group`)
                .then(({ data: priceGroups }) =>
                {
                    // Sort price groups by label
                    priceGroups.sort(({ label: a }, { label: b }) => a < b ? -1 : (b < a ? 1 : 0));

                    this.priceGroups = priceGroups;
                })
                .catch(error =>
                {
                    // todo: handle error
                });

        },

        fetchNewPriceListPdfLinks()
        {
            const eid = this.cEntity.id;
            axios.get(`/api/catalog/entity/new/${eid}`)
                .then(({ data: links }) =>
                {
                    this.pdfLinks = links;
                })
                .catch(error =>
                {
                    // todo: handle error
                });
        },

        getTagLabel(tagCode, defaultValue = '')
        {
            return this.$store.getters['prototypes/allTags'].find(t => t.code === tagCode)?.label || defaultValue;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
// Tag Filters
.sl-price-list__filters
{
    @apply mb-4 p-2 space-y-2 bg-primary-100 text-sm select-none;
}

.sl-price-list__tag-filter
{
    @apply cursor-pointer flex-1 border border-gray-300 rounded px-2 py-1 bg-white text-gray-400 text-center transition outline-none;

    &:hover,
    &:focus-visible,
    &.sl-price-list__tag-filter--active
    {
        @apply border-primary-500 text-primary-500;
    }

    &.sl-price-list__tag-filter--active
    {
        @apply bg-primary-500 text-white;
    }

    &.sl-price-list__tag-filter--active:focus-visible
    {
        @apply ring-1 ring-inset ring-white;
    }
}

// Text Filter
.sl-price-list__search-box
{
    @apply relative w-full;

    > .mdi-icon
    {
        @apply absolute top-1.5 left-2.5 w-[22px] h-[22px] text-gray-400 pointer-events-none;
    }
}

.sl-price-list__search-field
{
    @apply rounded-full border border-gray-300 pl-[34px] w-full h-8 text-primary-500 outline-none transition-all;

    &:hover,
    &:focus,
    &:active
    {
        @apply border-primary-300;
    }
}

// Sections
.section-title
{
    @apply cursor-pointer text-primary-500 mb-2 select-none
        hover:text-primary-700;
}

.section-title .mdi-icon
{
    @apply text-primary-300;
}

// Catalog Entries (article/supplement prices)
.article
{
    @apply flex py-1 px-5 cursor-default;
}

.article:hover,
.supplement:hover
{
    @apply bg-gray-100;
}

.article-label,
.supplement-label
{
    @apply w-2/3 text-sm text-gray-500;
}

.article-price,
.supplement-price
{
    @apply w-1/3 text-sm text-gray-500 text-right;
}

// Price Groups
.sl-price-groups
{
    @apply mt-4 p-4 bg-gray-50 text-gray-500;
}

.sl-price-groups__title
{
    @apply text-lg text-gray-600 mb-2;
}

.sl-price-groups table
{
    @apply w-auto;

    tr > td
    {
        @apply py-1 transition-colors;
    }

    tr > td:nth-child(1)
    {
        @apply pr-3;
    }

    tr:not(:hover) > td:nth-child(2)
    {
        @apply text-gray-300;
    }
}
</style>
