// =================
// Lens Order Module
// =================

import { isEmpty } from 'lodash-es';

export function setupLensOrderModule(i18n, VueHoneybadger)
{
    return {
        namespaced: true,


        // ------------------------------------------------------------ STATE

        state()
        {
            return {
                fittings:
                {
                    right: [null, null],
                    left:  [null, null],
                    current:
                    {
                        right: 1,
                        left:  1,
                    },
                    draw:
                    {
                        right: [1],
                        left:  [1],
                    },
                },
                viewAs: '', // either "CUSTOMER" or not
            };
        },


        // ------------------------------------------------------------ GETTERS

        getters:
        {
            getFitting: state => (side, id = null) =>
            {
                if(id === null)
                    id = state.fittings.current[side];

                return state.fittings[side]?.[id] ?? null;
            },

            getPrototype: (state, getters) => (side, id = null) =>
            {
                return getters.getFitting(side, id)?.getPrototype() || null;
            },

            getOfg: (state, getters) => (side, id = null) =>
            {
                return getters.getFitting(side, id)?.getOfg() || null;
            },

            viewAsCustomer: state => state.viewAs === 'CUSTOMER',
        },


        // ------------------------------------------------------------ MUTATIONS

        mutations:
        {
            resetFitting(state, side = null)
            {
                if(!side)
                {
                    state.fittings.right = [null, null];
                    state.fittings.left  = [null, null];
                    state.fittings.current.right = 1;
                    state.fittings.current.left  = 1;
                    state.fittings.draw.right = [1];
                    state.fittings.draw.left  = [1];
                }
                else
                {
                    state.fittings[side] = [null, null];
                    state.fittings.current[side] = 1;
                    state.fittings.draw[side] = [1];
                }
            },

            setFitting(state, { side, id = null, fitting })
            {
                if(side !== 'right' && side !== 'left')
                {
                    throw new TypeError(`Invalid value for side: ${side}`);
                }

                if(id === null)
                    id = state.fittings.current[side];

                if(typeof id !== 'number')
                {
                    throw new TypeError(`Invalid value for id: ${id}`);
                }

                if(id < 0)
                {
                    throw new RangeError(`Value out of bounds for id: ${id}; expected >= 0`);
                }

                state.fittings[side][id] = fitting;
            },

            setCurrent(state, { side, id })
            {
                if(side !== 'right' && side !== 'left')
                {
                    throw new TypeError(`Invalid value for side: ${side}`);
                }

                if(typeof id !== 'number')
                {
                    throw new TypeError(`Invalid value for id: ${id}`);
                }

                const maxId = state.fittings[side].length;
                if(id < 0 || id >= maxId)
                {
                    throw new RangeError(`Value out of bounds for id: ${id}; expected 0-${maxId}`);
                }

                state.fittings.current[side] = id;
            },

            removeFitting(state, { side, id })
            {
                if(side !== 'right' && side !== 'left')
                {
                    throw new TypeError(`Invalid value for side: ${side}`);
                }

                if(typeof id !== 'number')
                {
                    throw new TypeError(`Invalid value for id: ${id}`);
                }

                const maxId = state.fittings[side].length;
                if(id < 2 || id >= maxId) // cannot remove lenses 0 & 1
                {
                    throw new RangeError(`Value out of bounds for id: ${id}; expected 2-${maxId}`);
                }

                // Remove the fitting
                state.fittings[side].splice(id, 1);

                // Reset the `current` pointer
                const current = state.fittings.current[side];
                let newCurrent = current;
                if(current > id)
                {
                    newCurrent -= 1;
                }

                while(!state.fittings[side][newCurrent] && newCurrent > 1)
                {
                    newCurrent -= 1;
                }

                if(newCurrent !== current)
                {
                    state.fittings.current[side] = newCurrent;
                }
            },

            selectForDrawing(state, { side, id = null })
            {
                if(side !== 'right' && side !== 'left')
                {
                    throw new TypeError(`Invalid value for side: ${side}`);
                }

                if(id === null)
                    id = state.fittings.current[side];

                if(!state.fittings[side][id])
                {
                    throw new RangeError(`Fitting ${id} is empty on ${side} side`);
                }

                const index = state.fittings.draw[side].findIndex(i => i === id);
                if(index !== -1)
                {
                    return; // prevent duplicate selection
                }

                const newSelection = state.fittings.draw[side].slice();
                newSelection.push(id);
                newSelection.sort();
                state.fittings.draw[side] = newSelection;
            },

            unselectForDrawing(state, { side, id = null })
            {
                if(side !== 'right' && side !== 'left')
                {
                    throw new TypeError(`Invalid value for side: ${side}`);
                }

                if(id === null)
                    id = state.fittings.current[side];

                const index = state.fittings.draw[side].findIndex(i => i === id);
                if(index === -1)
                {
                    return;
                }

                state.fittings.draw[side].splice(index, 1);
            },

            setPrototypeValue(state, { side, id = null, paramCode, paramValue })
            {
                if(!side && id !== null)
                {
                    const rid = state.fittings.current.right;
                    const lid = state.fittings.current.left;

                    if(rid !== id || lid !== id)
                    {
                        const dbg = `{ side: ${side}, id: ${id}, rid: ${rid}, lid: ${lid} }`;
                        throw new Error(`Cannot update both sides when one of them is not current (${dbg})`);
                        // "current" means "the fitting being currently pointed at by state.fittings.current[side]"
                    }
                }

                if(side === 'right' || !side)
                {
                    const rid = (id === null) ? state.fittings.current.right : id;
                    state.fittings.right[rid].getPrototype().setValue(paramCode, paramValue);
                }

                if(side === 'left' || !side)
                {
                    const lid = (id === null) ? state.fittings.current.left : id;
                    state.fittings.left[lid].getPrototype().setValue(paramCode, paramValue);
                }
            },

            setOfgValue(state, { side, id = null, paramCode, paramValue })
            {
                if(!side && id !== null)
                {
                    const rid = state.fittings.current.right;
                    const lid = state.fittings.current.left;

                    if(rid !== id || lid !== id)
                    {
                        const dbg = `{ side: ${side}, id: ${id}, rid: ${rid}, lid: ${lid} }`;
                        throw new Error(`Cannot update both sides when one of them is not current (${dbg})`);
                        // "current" means "the fitting being currently pointed at by state.fittings.current[side]"
                    }
                }

                if(side === 'right' || !side)
                {
                    const rid = (id === null) ? state.fittings.current.right : id;
                    state.fittings.right[rid].getOfg().setValue(paramCode, paramValue);
                }

                if(side === 'left' || !side)
                {
                    const lid = (id === null) ? state.fittings.current.left : id;
                    state.fittings.left[lid].getOfg().setValue(paramCode, paramValue);
                }
            },

            setOfgOpen(state, { side, id = null, ofgOpen, ofg })
            {
                if(id === null)
                    id = state.fittings.current[side];

                if(ofgOpen)
                {
                    if(!ofg && !state.fittings[side].getOfg())
                    {
                        throw new Error(`Cannot open panel without an OFG`);
                    }

                    if(ofg)
                    {
                        state.fittings[side][id].setOfg(ofg);
                    }
                }

                state.fittings[side][id].setOfgOpen(ofgOpen);
            },

            setViewAs(state, viewAs)
            {
                state.viewAs = viewAs;
            },
        },


        // ------------------------------------------------------------ ACTIONS

        actions:
        {
            resetFitting({ commit }, side = null)
            {
                return new Promise((resolve, reject) =>
                {
                    commit('resetFitting', side);
                    resolve();
                });
            },

            cloneFitting({ commit, state, getters }, { side, id })
            {
                return new Promise((resolve, reject) =>
                {
                    const nextId = state.fittings[side].length;
                    const fitting = getters.getFitting(side, id).clone();

                    commit('setFitting', { side, id: nextId, fitting });
                    resolve(nextId);
                });
            },

            removeFitting({ commit }, { side, id })
            {
                return new Promise((resolve, reject) =>
                {
                    commit('removeFitting', { side, id });
                    commit('unselectForDrawing', { side, id });
                    resolve();
                });
            },

            selectForDrawing({ commit }, { side, id = null })
            {
                return new Promise((resolve, reject) =>
                {
                    commit('selectForDrawing', { side, id });
                    resolve();
                });
            },

            unselectForDrawing({ commit }, { side, id = null })
            {
                return new Promise((resolve, reject) =>
                {
                    commit('unselectForDrawing', { side, id });
                    resolve();
                });
            },

            template({ state, rootState }, { side = null, id = null, initValues = {}, target })
            {
                if(!side && !isEmpty(initValues))
                {
                    console.log('INIT VALUES', initValues, !side && initValues, side);

                    throw new Error(`When "initValues" are given during templating, "side" is mandatory.`);
                }

                if(!side && id !== null)
                {
                    const rid = state.fittings.current.right;
                    const lid = state.fittings.current.left;

                    if(rid !== id || lid !== id)
                    {
                        const dbg = `{ side: ${side}, id: ${id}, rid: ${rid}, lid: ${lid} }`;
                        throw new Error(`Cannot template both sides when one of them is not current (${dbg})`);
                        // "current" means "the fitting being currently pointed at by state.fittings.current[side]"
                    }
                }

                const requests = [];
                const eid = rootState.account.cEntity.id;
                const getTarget = (target === 'ofg' ? 'getOfg' : 'getPrototype');

                if(state.fittings.right && (side === 'right' || !side))
                {
                    const rid = (id === null) ? state.fittings.current.right : id;
                    requests.push(
                        state.fittings.right[rid][getTarget]()
                            .template(eid, initValues, state.viewAs)
                    );
                }

                if(state.fittings.left && (side === 'left' || !side))
                {
                    const lid = (id === null) ? state.fittings.current.left : id;
                    requests.push(
                        state.fittings.left[lid][getTarget]()
                            .template(eid, initValues, state.viewAs)
                    );
                }

                return Promise.all(requests);
            },

            templateAndValidate({ state, rootState }, { side = null, id = null, options = {} })
            {
                if(!side && id !== null)
                {
                    const rid = state.fittings.current.right;
                    const lid = state.fittings.current.left;

                    if(rid !== id || lid !== id)
                    {
                        const dbg = `{ side: ${side}, id: ${id}, rid: ${rid}, lid: ${lid} }`;
                        throw new Error(`Cannot template both sides when one of them is not current (${dbg})`);
                        // "current" means "the fitting being currently pointed at by state.fittings.current[side]"
                    }
                }

                const requests = [];
                const eid = rootState.account.cEntity.id;
                const rid = (id === null) ? state.fittings.current.right : id;
                const lid = (id === null) ? state.fittings.current.left : id;

                if(state.fittings.right[rid] && (side === 'right' || !side))
                {
                    requests.push(
                        state.fittings.right[rid].getPrototype()
                            .templateAndValidate(eid, options, state.viewAs)
                    );
                }

                if(state.fittings.left[lid] && (side === 'left' || !side))
                {
                    requests.push(
                        state.fittings.left[lid].getPrototype()
                            .templateAndValidate(eid, options, state.viewAs)
                    );
                }

                return Promise.all(requests);
            },

            openOfg({ commit, getters, rootGetters }, { side, id = null, formCode })
            {
                return new Promise((resolve, reject) =>
                {
                    const options = {
                        side,
                        id,
                        ofgOpen: true,
                    };

                    // Only set the form if it has changed
                    if(formCode !== getters.getOfg(side, id)?.code)
                    {
                        options.ofg = rootGetters['forms/getOfgByCode'](formCode);
                        if(!options.ofg)
                        {
                            throw new Error(`OFG with code \"${formCode}\" not found.`);
                        }
                    }

                    commit('setOfgOpen', options);
                    resolve();
                });
            },

            closeOfg({ commit }, { side, id = null })
            {
                commit('setOfgOpen', { side, id, ofgOpen: false });
                return Promise.resolve();
            },

            toggleViewAsCustomer({ commit, state, rootState }, viewAs)
            {
                if(state.viewAs === 'CUSTOMER')
                {
                    commit('setViewAs', rootState.account.cUser.role);
                }
                else
                {
                    commit('setViewAs', 'CUSTOMER');
                }
            },
        },
    };
};
