import axios from '@/axios';
import Order from '@/models/Order';
import Return from '@/models/Return';
import LensReturnUrlManager from '../LensReturnUrlManager';

export default class FetchOrderByLensOemNumber
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        /** @type {LensReturnUrlManager} */
        const lensReturnUrlManager = this.v.lensReturnUrlManager;

        // Skip unless there's a lens OEM number in the URL
        if(!lensReturnUrlManager.hasOemNumber())
        {
            console.log(`No lens OEM number in URL for `);
            return true;
        }

        const urlOemNumber = lensReturnUrlManager.getOemNumber();
        console.log(`There is a lens OEM number in URL: ${urlOemNumber}`);

        return axios.get(`/api/order/lens-oem/${urlOemNumber}`)
            .then(response =>
            {
                const order = new Order(response.data);

                if(order.has_been_returned)
                {
                    this.v.orderIdentifierFormError = this.v.$t('return.form.error.already_returned');

                    return false;
                }

                this.v.leftReturn = new Return(order);

                console.log(`Main return has been set`);

                return true;
            })
            .catch(err =>
            {
                this.v.leftReturn = null;
                this.v.orderIdentifierFormError = this.v.$t('return.form.error.not_found');

                return false;
            });
    }
}
