import { isEmpty } from 'lodash-es';

export const ID_TYPES = Object.freeze({
    LENS_SN:         'sn',
    LENS_OEM_NUMBER: 'oem',
    ORDER_REFERENCE: 'order-reference',
    BASKET_LINE_ID:  'basket-line-id',
});

const PROTOTYPE_CODE_KEY = 'prototype-code';

const SIDE_RIGHT = 'right';
const SIDE_LEFT  = 'left';

export default class LensOrderUrlManager
{
    /** @type {?string} */
    lang = null;

    /** @type {?string} */
    entity_id = null;

    right = {
        /** @type {?string} */
        identifierType: null,

        /** @type {?string} */
        orderIdentifier: null,

        /** @type {?string} */
        prototypeCode: null,
    };

    left = {
        /** @type {?string} */
        identifierType: null,

        /** @type {?string} */
        orderIdentifier: null,

        /** @type {?string} */
        prototypeCode: null,
    };

    /** @type {string} */
    action = null;

    /** @type {Record<string, string>} */
    queryParams = {};


    // ------------------------------------------------------------ CONSTRUCTOR

    /**
     * Construct a new LensOrderUrlManager.
     *
     * @param {?Object} route The Vuex route.
     */
    constructor(route = null)
    {
        if(route)
        {
            this.setFromRoute(route);
        }
    }


    // ------------------------------------------------------------ METHODS

    /**
     * Initialize attributes from the current route.
     *
     * @param {Object} route The Vuex route.
     */
    setFromRoute(route)
    {
        this.setLanguage(route.params.lang);
        this.setEntity(route.params.entity_id);

        const sides = route.params.sides || [];
        this.parseSideParams(SIDE_RIGHT, sides);
        this.parseSideParams(SIDE_LEFT, sides);

        if(route.path.includes('order/lenses/edit'))
        {
            this.setAction('edit');
        }
        else
        {
            this.setAction(null);
        }

        this.queryParams = route.queryParams;
    }

    /**
     * @param {"right"|"left"} side
     * @param {Array} sides
     * @returns {void}
     */
    parseSideParams(side, sides)
    {
        const start = sides.indexOf(side);
        if(start === -1)
        {
            this.setIdentifierType(side, null);
            this.setOrderIdentifier(side, null);
            this.setPrototypeCode(side, null);

            return;
        }

        const end = sides.indexOf(this.getOtherSide(side));
        if(end > start)
        {
            sides = sides.slice(start + 1, end);
        }
        else
        {
            sides = sides.slice(start + 1);
        }

        const validKeys = Object.values(ID_TYPES);
        for(let i = 0; i < sides.length; i += 2)
        {
            const key   = sides[i];
            const value = sides[i + 1];

            if(validKeys.includes(key))
            {
                this.setIdentifierType(side, key);
                this.setOrderIdentifier(side, value);
            }
            else if(key === PROTOTYPE_CODE_KEY)
            {
                this.setPrototypeCode(side, value);
            }
        }
    }


    // ------------------------------------------------------------ PATH

    /**
     * Build the path from the internal state.
     *
     * @returns {string}
     */
    getPath()
    {
        const parts = ['', this.lang, this.entity_id, 'order', 'lenses'];
        const params = [];

        if(this.action)
        {
            parts.push('edit');
        }

        for(const side of [SIDE_RIGHT, SIDE_LEFT])
        {
            if(this.has(side))
            {
                parts.push(side);

                if(this.hasOrderIdentifier(side))
                {
                    parts.push(this.getIdentifierType(side));
                    parts.push(this.getOrderIdentifier(side));
                }

                if(this.hasPrototypeCode(side))
                {
                    parts.push(PROTOTYPE_CODE_KEY);
                    parts.push(this.getPrototypeCode(side));
                }
            }
        }

        let url = parts.join('/');
        if(!isEmpty(this.queryParams))
        {
            for(const key in this.queryParams)
            {
                params.push(key + '=' + this.queryParams[key]);
            }

            url += '?' + params.join('&');
        }

        return url;
    }


    // ------------------------------------------------------------ LANGUGAGE

    /**
     * Set the language.
     *
     * @param {string} lang
     */
    setLanguage(lang)
    {
        this.lang = lang;
    }


    // ------------------------------------------------------------ ENTITY

    /**
     * Set the entity ID.
     *
     * @param {string} hashedEid
     */
    setEntity(hashedEid)
    {
        this.entity_id = hashedEid;
    }


    // ------------------------------------------------------------ ACTION

    /**
     * Set the action.
     *
     * @param {?string} action
     */
    setAction(action)
    {
        this.action = action;
    }


    // ------------------------------------------------------------ SIDE

    /**
     * Determine whether there's a fitting on a specific side.
     *
     * @param {"right" | "left"} side
     * @returns {Boolean}
     */
    has(side)
    {
        return (
            this.getIdentifierType(side) !== null &&
            this.getOrderIdentifier(side) !== null
        ) || this.getPrototypeCode(side) !== null;
    }


    // ------------------------------------------------------------ ORDER IDENTIFIER

    /**
     * Determine whether there's an order identifier on a specific side.
     *
     * @param {"right"|"left"} side
     * @returns {Boolean}
     */
    hasOrderIdentifier(side)
    {
        return this.getOrderIdentifier(side) !== null;
    }

    /**
     * Retrieve the order identifier for a specific side.
     *
     * @param {"right"|"left"} side
     * @returns {null|number|string}
     */
    getOrderIdentifier(side)
    {
        const orderIdentifier = this[side].orderIdentifier;
        if(orderIdentifier === null)
        {
            return null;
        }

        const type = this.getIdentifierType(side);
        switch(type)
        {
            // Integers
            case ID_TYPES.LENS_SN:
            case ID_TYPES.BASKET_LINE_ID:
                return parseInt(orderIdentifier);

            // Strings
            case ID_TYPES.LENS_OEM_NUMBER:
            case ID_TYPES.ORDER_REFERENCE:
                return orderIdentifier;

            default:
                throw new RangeError(`Invalid order identifier type: "${type}"; raw value: ${orderIdentifier}`);
        }
    }

    /**
     * Set the order identifier for a side.
     *
     * @param {"right"|"left"} side
     * @param {null|number|string} identifier
     */
    setOrderIdentifier(side, identifier)
    {
        this[side].orderIdentifier = identifier;
    }

    /**
     * Retrieve the identifier type for a side.
     *
     * @param {"right"|"left"} side
     * @returns {string}
     */
    getIdentifierType(side)
    {
        return this[side].identifierType;
    }

    /**
     * Set the identifier type for a side.
     *
     * @param {"right"|"left"} side
     * @param {string} identifierType
     */
    setIdentifierType(side, identifierType)
    {
        this[side].identifierType = identifierType;
    }


    // ------------------------------------------------------------ LENS SN

    /**
     * Determine whether there's a lens serial number on a side.
     *
     * @param {"right"|"left"} side
     * @returns {Boolean}
     */
    hasLensSn(side)
    {
        return this.getIdentifierType(side) === ID_TYPES.LENS_SN &&
            this.getOrderIdentifier(side) !== null;
    }

    /**
     * Retrieve the lens serial number for a side.
     *
     * @param {"right"|"left"} side
     * @returns {?number}
     */
    getLensSn(side)
    {
        return this.getOrderIdentifier(side);
    }

    /**
     * Set the lens serial number for a side.
     *
     * @param {"right"|"left"} side
     * @param {?number} identifier
     */
    setLensSn(side, identifier)
    {
        this.setIdentifierType(side, ID_TYPES.LENS_SN);
        this.setOrderIdentifier(side, identifier);
    }


    // ------------------------------------------------------------ LENS OEM NUMBER

    /**
     * Determine whether there's a lens OEM number on a side.
     *
     * @param {"right"|"left"} side
     * @returns {Boolean}
     */
    hasOemNumber(side)
    {
        return this.getIdentifierType(side) === ID_TYPES.LENS_OEM_NUMBER &&
            this.getOrderIdentifier(side) !== null;
    }

    /**
     * Retrieve the lens OEM number for a side.
     *
     * @param {"right"|"left"} side
     * @returns {?string}
     */
    getOemNumber(side)
    {
        return this.getOrderIdentifier(side);
    }

    /**
     * Set the lens OEM number for a side.
     *
     * @param {"right"|"left"} side
     * @param {?string} identifier
     */
    setOemNumber(side, identifier)
    {
        this.setIdentifierType(side, ID_TYPES.LENS_OEM_NUMBER);
        this.setOrderIdentifier(side, identifier);
    }


    // ------------------------------------------------------------ ORDER REFERENCE

    /**
     * Determine whether there's an order reference on a side.
     *
     * @param {"right"|"left"} side
     * @returns {Boolean}
     */
    hasOrderReference(side)
    {
        return this.getIdentifierType(side) === ID_TYPES.ORDER_REFERENCE &&
            this.getOrderIdentifier(side) !== null;
    }

    /**
     * Retrieve the order reference for a side.
     *
     * @param {"right"|"left"} side
     * @returns
     */
    getOrderReference(side)
    {
        return this.getOrderIdentifier(side);
    }

    /**
     * Set the order reference for a side.
     *
     * @param {"right"|"left"} side
     * @param {?string} identifier
     */
    setOrderReference(side, identifier)
    {
        this.setIdentifierType(side, ID_TYPES.ORDER_REFERENCE);
        this.setOrderIdentifier(side, identifier);
    }


    // ------------------------------------------------------------ BASKET LINE ID

    /**
     * Determine there's a basket line ID on a side.
     *
     * @param {"right"|"left"} side
     * @returns {Boolean}
     */
    hasBasketLineId(side)
    {
        return this.getIdentifierType(side) === ID_TYPES.BASKET_LINE_ID && this.getOrderIdentifier(side) !== null;
    }

    /**
     * Retrieve the basket line ID for a side.
     *
     * @param {"right"|"left"} side
     * @returns {?number}
     */
    getBasketLineId(side)
    {
        return this.getOrderIdentifier(side);
    }

    /**
     * Set the basket line ID for a side.
     *
     * @param {"right"|"left"} side
     * @param {?number} identifier
     */
    setBasketLineId(side, identifier)
    {
        this.setIdentifierType(side, ID_TYPES.BASKET_LINE_ID);
        this.setOrderIdentifier(side, identifier);
    }


    // ------------------------------------------------------------ PROTOTYPE CODE

    /**
     * Determine whether there's a prototype code on a side.
     *
     * @param {"right"|"left"} side
     * @returns {Boolean}
     */
    hasPrototypeCode(side)
    {
        return this.getPrototypeCode(side) !== null;
    }

    /**
     * Retrieve the prototype code for a side.
     *
     * @param {"right"|"left"} side
     * @returns {string}
     */
    getPrototypeCode(side)
    {
        return this[side].prototypeCode;
    }

    /**
     * Set the prototype code for a side.
     *
     * @param {"right"|"left"} side
     * @param {string} prototypeCode
     */
    setPrototypeCode(side, prototypeCode)
    {
        this[side].prototypeCode = prototypeCode;
    }


    // ------------------------------------------------------------ QUERY PARAMS

    /**
     * Indicate whether the entity should be forced.
     *
     * @param {Boolean} [bypass]
     * @returns {LensOrderUrlManager} `this`
     */
    bypassEntityCheck(bypass = true)
    {
        this.queryParams.force_current_entity = bypass ? 1 : 0;

        return this;
    }


    // ------------------------------------------------------------ RESET

    /**
     * Reset the internal state for a side.
     *
     * @param {"right"|"left"} side
     */
    reset(side)
    {
        this[side] = {
            identifierType:  null,
            orderIdentifier: null,
            prototypeCode:   null,
        };
    }


    // ------------------------------------------------------------ HELPERS

    /**
     * Get the opposite side.
     *
     * @param {"right"|"left"} side
     * @returns {"left"|"right"}
     */
    getOtherSide(side)
    {
        return side === SIDE_LEFT ? SIDE_RIGHT : SIDE_LEFT;
    }

    /**
     * Create a deep copy of this object.
     *
     * @returns {LensOrderUrlManager}
     */
    clone()
    {
        const cloned = new LensOrderUrlManager();

        cloned.lang      = this.lang;
        cloned.entity_id = this.entity_id;

        cloned.right.identifierType  = this.right.identifierType;
        cloned.right.orderIdentifier = this.right.orderIdentifier;
        cloned.right.prototypeCode   = this.right.prototypeCode;

        cloned.left.identifierType  = this.left.identifierType;
        cloned.left.orderIdentifier = this.left.orderIdentifier;
        cloned.left.prototypeCode   = this.left.prototypeCode;

        cloned.action = this.action;

        for(const key in this.queryParams)
        {
            cloned.queryParams[key] = this.queryParams[key];
        }

        return cloned;
    }

    /**
     * Invert the left and right properties.
     */
    invert()
    {
        [this.right, this.left] = [this.left, this.right];
    }
}
