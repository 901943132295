import FormField from "./FormField";
import { i18n } from '@/main';

export default class FormFieldInfo extends FormField
{
    getDisplayedValue()
    {
        if(this.getDefinedValueLabel() !== null)
        {
            return this.getDefinedValueLabel();
        }

        if(this.getDefinedValue() !== null)
        {
            return this.getDefinedValue();
        }

        const val = this.getValue();
        const tkey = `parameters.${this.getCode()}.values.${val}.label`;
        const translation = i18n.global.t(tkey);

        if(translation !== tkey)
        {
            return translation;
        }

        if(val === null)
        {
            return null;
        }
        console.log('DISPLAYED VALUE', val);
        return this.formatValue(val);
    }
}
