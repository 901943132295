/**
 * @typedef {{x: number, y: number}} Point
 */

import Objects from '@/utils/Objects';

export default class AbstractSurfaceFilter
{
    constructor(options = {})
    {
        // To be overridden.
    }

    /**
     * Run the filter.
     *
     * @param {Point[]} points
     * @returns {Point[]}
     */
    run(points)
    {
        return points;
    }
}
