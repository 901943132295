// ==============
// Catalog Module
// ==============

import { clone } from 'lodash-es';
import axios     from '@/axios';
import constants from '@/constants/constants';

export function setupCatalogModule(VueHoneybadger)
{
    return {
        namespaced: true,

        // ------------------------------------------------------------------------- STATE

        state()
        {
            return {
                articles: [],
            };
        },

        // ------------------------------------------------------------------------- GETTERS

        getters:
        {
            getByCode: state => code => state.articles.find(a => a.code === code),

            getLabelByCode: state => code => state.articles.find(a => a.code === code)?.label,

            getAllByCode: state => code => state.articles.filter(a => a.code === code),

            isSupplement: state => code => state.articles.find(a => a.code === code)?.type === constants.articles.type.SUPPLEMENT,

            getByTag: state => code => state.articles.filter(a => a.tags.some(t => t.code === code)),

            getTagLabelByCode: state => code => state.articles.find(a => a.tags.some(t => t.code === code))?.tags.find(t => t.code === code)?.label,

            hasActiveArticlesByTag: state => tags => state.articles.some(article =>
            {
                if(article.status !== constants.articles.status.ACTIVE)
                {
                    return false;
                }

                if(!Array.isArray(tags))
                {
                    tags = [tags];
                }

                const articleTags = article.tags.map(t => t.code);

                return tags.every(t => articleTags.includes(t));
            }),
        },

        // ------------------------------------------------------------------------- MUTATIONS

        mutations:
        {
            setCatalog(state, catalog)
            {
                state.articles = catalog;

                VueHoneybadger.setContext({ catalog: state.articles });
            },

            /**
             * Assign stored articles their photos, matching on the `photo_id` field.
             * @param {{}} state
             * @param {[]} photos
             */
            setImages(state, photos)
            {
                for(const photo of photos)
                {
                    const matchingArticleCodes = state.articles.filter(a => a.photo_id === photo.photo_id).map(a => a.code);
                    for(const articleIndex in state.articles)
                    {
                        const article = clone(state.articles[articleIndex]);
                        if(matchingArticleCodes.includes(article.code))
                        {
                            article.photos = photo.photos;
                            state.articles.splice(articleIndex, 1, article);
                        }
                    }
                }
            },
        },

        // ------------------------------------------------------------------------- ACTIONS

        actions:
        {
            /**
             *
             * @param {Object} dispatch
             * @param {Object} commit
             */
            fetchCatalog({ commit, rootState })
            {
                const url = '/api/catalog/entity/:eid'
                    .replace(':eid', rootState.account.cEntity.id);

                return axios.get(url)
                    .then(response =>
                    {
                        commit('setCatalog', response.data);
                    })
                    .catch(error =>
                    {
                        console.log(error);
                    });
            },

            fetchImagesByTagCode({ commit }, tagCode)
            {
                return new Promise((resolve, reject) =>
                {
                    axios.get(`/api/catalog/tag/${tagCode}/photos/T`)
                        .then(response =>
                        {
                            // Commit photos to state
                            commit('setImages', response.data);
                            resolve(response.data);
                        })
                        .catch(error =>
                        {
                            console.log(error);
                            reject(error);
                        });
                });
            },

            fetchImagesByArticleCodes({ commit }, { eid, codes })
            {
                return new Promise((resolve, reject) =>
                {
                    axios.post(`/api/catalog/entity/${eid}/photos/T`, { codes })
                        .then(response =>
                        {
                            // Commit photos to state
                            commit('setImages', response.data);
                            resolve(response.data);
                        })
                        .catch(error =>
                        {
                            console.log(error);
                            reject(error);
                        });
                });
            },
        },
    };
};
