/**
 * @typedef {Meridian[]} Surface
 * @typedef {{dia: number, sag: number}} GraphPoint
 */

import Meridian from '@/models/Meridian';
import { deg2rad } from '@/utils/Formulas';

export default function useGraphComposable()
{
    // ------------------------------------------------------------ CONSTANTS

    /** The resolution for cornea point calculation requests. */
    const CORNEA_RESOLUTION = 0.01;

    /** The resolution for back surface point calculation requests. */
    const BACK_SURFACE_RESOLUTION = 0.005;

    /** The minimum thickness of the tear film. */
    const MIN_TEAR = 0.004;


    // ------------------------------------------------------------ METHODS

    /**
     * Get the meridian at `angle`.
     *
     * @param {Surface} surface
     * @param {number} angle
     * @returns {Meridian?}
     */
    function getMeridian(surface, angle)
    {
        if(!Array.isArray(surface))
        {
            throw new TypeError('getMeridian: surface is not an array');
        }

        if(isNaN(angle))
        {
            throw new TypeError('getMeridian: angle is not a number');
        }
        angle %= 360;

        if(!surface?.length)
        {
            return null;
        }

        const meridianIndex = surface.findIndex(m => m.angle === angle);
        if(meridianIndex !== -1)
        {
            // The meridian exists;
            // just return it.
            return surface[meridianIndex];
        }

        // The meridian doesn't exist;
        // find the closest two angles and interpolate the values
        // of each of their points.
        const prev = getPreviousMeridian(surface, angle);
        const next = getNextMeridian(surface, angle);
        if(prev === null || next === null)
        {
            return null;
        }

        if(prev.resolution !== next.resolution)
        {
            // @todo find the greatest common divisor, use it as resolution, and interpolate values
            throw new Error(
                'prev and next have different resolutions: ' +
                `${prev.resolution} != ${next.resolution}`
            );
        }

        const addAngle = next.angle < prev.angle ? 360 : 0;
        const delta = Math.abs(addAngle + next.angle - prev.angle);
        const alpha = angle - prev.angle;
        const beta  = addAngle + next.angle - angle;

        const alphaProp = alpha * 90 / delta;
        const betaProp  = beta * 90 / delta;

        const cos2a = Math.cos(deg2rad(alphaProp)) ** 2;
        const cos2b = Math.cos(deg2rad(betaProp)) ** 2;

        const meridian = new Meridian({
            angle,
            resolution: prev.resolution,
        });

        // Interpolate the points
        for(let i = 0; i < prev.points.length; i++)
        {
            const prevSag = prev.points[i];
            const nextSag = next.points[i];

            meridian.points.push(prevSag * cos2a + nextSag * cos2b);
        }

        // Interpolate the zones
        for(const z in prev.zones)
        {
            const { dia: prevDia, sag: prevSag } = prev.zones[z];
            const { dia: nextDia, sag: nextSag } = next.zones[z];

            meridian.zones[z] = {
                // todo: linear interpolation between prevDia/nextDia
                dia: prevDia,
                sag: prevSag * cos2a + nextSag * cos2b,
            };
        }

        return meridian;
    }

    /**
     * Get the first meridian before the one at `angle`.
     *
     * @param {Surface} surface
     * @param {number} angle
     * @returns {Meridian?}
     * @fixme
     */
    function getPreviousMeridian(surface, angle)
    {
        if(!Array.isArray(surface))
        {
            throw new TypeError('getMeridian: surface is not an array');
        }

        if(!surface?.length)
        {
            return null;
        }

        angle %= 360;
        const meridians = surface.slice().sort((a, b) => a.angle - b.angle);

        return meridians.findLast(m => m.angle < angle) ?? meridians.at(-1);
    }

    /**
     * Get the first meridian after the one at `angle`.
     *
     * @param {Surface} surface
     * @param {number} angle
     * @returns {Meridian?}
     * @fixme
     */
    function getNextMeridian(surface, angle)
    {
        if(!Array.isArray(surface))
        {
            throw new TypeError('getMeridian: surface is not an array');
        }

        if(!surface?.length)
        {
            return null;
        }

        angle %= 360;
        const meridians = surface.slice().sort((a, b) => a.angle - b.angle);

        return meridians.find(m => m.angle > angle) ?? meridians[0];
    }

    /**
     * Find the highest distance between the cornea and the contact lens.
     * Does *NOT* take a `MIN_TEAR` value into account.
     *
     * @param {Surface} cornea
     * @param {Surface} backSurface
     * @param {number}  angle
     * @returns {number}
     */
    function getApex(cornea, backSurface, angle)
    {
        angle %= 360;

        const kMeridian  = getMeridian(cornea, angle);
        const clMeridian = getMeridian(backSurface, angle);

        if(!kMeridian)
            throw new Error(`getApex: kMeridian not found at ${angle}°`);

        if(!clMeridian)
            throw new Error(`getApex: kMeridian not found at ${angle}°`);

        if(kMeridian.resolution % clMeridian.resolution !== 0)
            throw new Error(
                `getApex: CL resolution must be a divisor of K resolution`
            );

        const resProp = Math.round(kMeridian.resolution / clMeridian.resolution);

        let maxDeltaSag = 0;
        for(let i = 0; i < kMeridian.points.length; i++)
        {
            const j = i * resProp;
            if(j >= clMeridian.points.length)
                break;

            const kSag  = kMeridian.points[i];
            const clSag = clMeridian.points[j];
            const deltaSag = clSag - kSag;
            if(maxDeltaSag < deltaSag)
                maxDeltaSag = deltaSag;
        }

        return maxDeltaSag;
    }

    /**
     * Find the highest distance between the cornea and the contact lens, on all
     * 360 meridians.
     * Does *NOT* take a `MIN_TEAR` value into account.
     *
     * @param {Surface} cornea
     * @param {Surface} backSurface
     * @returns {number}
     */
    function getApex360(cornea, backSurface)
    {
        let apex = 0;
        for(let angle = 0; angle < 360; angle++)
        {
            apex = Math.max(apex, getApex(cornea, backSurface, angle));
        }

        return apex;
    }

    /**
     * Alternative way of finding the highest distance between the cornea and
     * the contact lens, on all 360 meridians.
     * Does *NOT* take a `MIN_TEAR` value into account.
     *
     * @param {Surface} cornea
     * @param {Surface} backSurface
     * @returns {number}
     */
    function getApexAlt360(cornea, backSurface)
    {
        let minDeltaSag = 0;
        for(let angle = 0; angle < 360; angle++)
        {
            const kMeridian  = getMeridian(cornea, angle);
            const clMeridian = getMeridian(backSurface, angle);
            if(kMeridian.resolution % clMeridian.resolution !== 0)
                throw new Error(
                    `getApexAlt360: CL resolution must be a divisor of K resolution at ${angle}°`
                );

            const resProp = Math.round(kMeridian.resolution / clMeridian.resolution);

            for(let i = 0; i < kMeridian.points.length; i++)
            {
                const j = i * resProp;
                if(j >= clMeridian.points.length)
                    break;

                const kSag  = kMeridian.points[i];
                const clSag = clMeridian.points[j];
                const deltaSag = kSag - clSag;
                if(minDeltaSag > deltaSag)
                    minDeltaSag = deltaSag;
            }
        }

        return Math.abs(minDeltaSag);
    }

    /**
     * Get computed background colors for drawings.
     *
     * @returns {string[]}
     */
    function getAllFittingColors()
    {
        const colors = [];
        for(let i = 0; i <= 3; i++)
        {
            // Create a hidden div with the color class
            const div = document.createElement('div');
            const className = `sl-fitting-selector__color--${i}`;
            div.classList.add(className);
            div.style.display = 'none';
            document.body.appendChild(div);

            // Retrieve its color
            const bgColor = getComputedStyle(div).backgroundColor;
            colors.push(bgColor);

            // Delete the div (cleanup)
            div.remove();
        }

        return colors;
    }


    // ------------------------------------------------------------ EXPORT

    return {
        // Constants
        CORNEA_RESOLUTION,
        BACK_SURFACE_RESOLUTION,
        MIN_TEAR,

        // Methods
        getMeridian,
        getPreviousMeridian,
        getNextMeridian,
        getApex,
        getApex360,
        getApexAlt360,
        getAllFittingColors,
    };
}
