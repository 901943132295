<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="sl-form-dual__form-line sl-form-dual__form-line-inline">

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

export default
{
    name: 'FormLineInline',

    props:
    {
        formLine:
        {
            type:     Object,
            required: true,
        },
    },

    computed:
    {

    },

    methods:
    {

    },
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">

</style>
