import { ONLY_NUMBERS } from '@/utils/Regexes';

export default class SilentlySetUrlManagerParameter
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        const lensReturnUrlManager = this.v.lensReturnUrlManager;
        const orderIdentifier = this.v.orderIdentifier;

        // Detect if the serial number is from SwissLens or an OEM
        if(ONLY_NUMBERS.test(orderIdentifier))
        {
            if(orderIdentifier < 30_000_000)
            {
                // Only numbers < 30'000'000 => SwissLens Serial Number
                lensReturnUrlManager.setLensSn(orderIdentifier);
            }
            else
            {

                // Only numbers > 30'000'000 => SwissLens Order reference
                lensReturnUrlManager.setOrderReference(orderIdentifier);
            }
        }
        else
        {
            // Not only numbers => OEM Number
            lensReturnUrlManager.setOemNumber(orderIdentifier);
        }

        lensReturnUrlManager.setReturnBothLenses(this.v.returnBothLenses);

        this.v.dispatchOnRouteChange = false;

        this.v.$router.replace({ path: lensReturnUrlManager.getPath() })
            .then(() =>
            {
                console.log('URL has been updated (URL params watcher disabled)');

                this.v.dispatchOnRouteChange = true;
            });

        return true;
    }
}
