/**
 * Convert an angle from degrees to radians.
 *
 * @param {number} deg The angle in degrees.
 * @returns {number} The angle in radians.
 */
export function deg2rad(deg)
{
    return deg * (Math.PI / 180);
}

/**
 * Convert an angle from radians to degrees.
 *
 * @param {number} rad The angle in radians.
 * @returns {number} The angle in degrees.
 */
export function rad2deg(rad)
{
    return rad * 180 / Math.PI;
}

/**
 * Calculate the sagittal difference between 2 diameters.
 * DASAG: Differential Asphere Sagittal
 *
 * @param {number} r  The radius.
 * @param {number} e  The eccentricity.
 * @param {number} d1 The starting diameter.
 * @param {number} d2 The ending diameter.
 * @returns {number} The calculated sagittal.
 */
export function daSag(r, e, d1, d2)
{
    d1 /= 2;
    d2 /= 2;

    const k = kOfEx(e);

    const sqStart = (1 + k) * (d2 ** 2) / (r ** 2);
    const sqEnd   = (1 + k) * (d1 ** 2) / (r ** 2);

    if(sqStart >= 1 || sqEnd >= 1)
    {
        throw new RangeError(
            'Combination of radius and eccentricity exceed the diameter' +
            `(r: ${r.toFixed(2)}, e: ${e.toFixed(2)}, d: ${d2.toFixed(2)})`
        );
    }

    return ((d2 ** 2) / (r * (1 + Math.sqrt(1 - sqStart)))) -
           ((d1 ** 2) / (r * (1 + Math.sqrt(1 - sqEnd))));
}

/**
 * K from Ex | progressive eccentricity.
 *
 * @param {number} e The eccentricity.
 * @returns {number} The calculated K.
 */
export function kOfEx(e)
{
    // Prevent division by zero
    if(e === 1)
    {
        e = 0.999;
    }

    if(e >= 0)
    {
        // Conic constant
        return -1 * (e ** 2);
    }

    // Negative eccentricity => Prolate ellipse with k > 0
    return 1 / ((1 - e) ** 2) - 1;
}

/**
 * Provide the radius of an ellipse in function of a selected eccentricity,
 * given the targeted difference of SAG and the two diameters.
 *
 * @param {number} deltaSag The difference in sagittal.
 * @param {number} ecc      The eccentricity.
 * @param {number} d1       The starting diameter.
 * @param {number} d2       The ending diameter.
 * @returns {number}
 */
export function radiusDeltaSagEx(deltaSag, ecc, d1, d2)
{
    d1 /= 2;
    d2 /= 2;

    if(ecc === 1)
    {
        ecc = 0.99;
    }

    const y = (d2 ** 2) - (d1 ** 2);
    const e = 1 - (ecc ** 2);

    const firstX = (y / (2 * deltaSag * e)) + deltaSag / 2;

    return Math.sqrt((e ** 2) * (firstX ** 2) + (d1 ** 2) * e);
}

/**
 * Calculate the eccentricity of a lens.
 *
 * @param {number} rad The radius.
 * @param {number} sag The sagittal.
 * @param {number} dia The diameter.
 * @returns {number}
 */
export function exSRDbis(rad, sag, dia)
{
    dia /= 2;

    const T1 = (dia ** 2 - (sag * rad)) / sag;
    const Ex = - (T1 ** 2 - rad ** 2) / (dia ** 2);

    let kk = Ex - 1;

    if(Math.abs(kk) < 0.0001)
    {
        kk = 0;
    }

    if(rad < dia)
    {
        return 0;
    }

    if(kk <= 0)
    {
        return Math.sqrt(-kk);
    }

    return -Math.sqrt(kk / (kk + 1));
}

/**
 * Calculate the radius of the best sphere for a cornea.
 *
 * @param {number}  dia  The corneal diameter.
 * @param {number}  rad1 The corneal radius on the 1st meridian.
 * @param {number}  ecc1 The corneal eccentricity on the 1st meridian.
 * @param {?number} rad2 The corneal radius on the 2nd meridian.
 *                       Defaults it `rad1` if `null`.
 * @param {?number} ecc2 The corneal eccentricity on the 2nd meridian.
 *                       Defaults it `ecc1` if `null`.
 * @param {?number} rad3 The corneal radius on the 3rd meridian.
 *                       Defaults it `rad1` if `null`.
 * @param {?number} ecc3 The corneal eccentricity on the 3rd meridian.
 *                       Defaults it `ecc1` if `null`.
 * @param {?number} rad4 The corneal radius on the 4th meridian.
 *                       Defaults it `rad2` if `null`.
 * @param {?number} ecc4 The corneal eccentricity on the 4th meridian.
 *                       Defaults it `ecc2` if `null`.
 * @returns
 */
export function bestSphereRadius(
    dia,
    rad1,
    ecc1,
    rad2 = null,
    ecc2 = null,
    rad3 = null,
    ecc3 = null,
    rad4 = null,
    ecc4 = null
)
{
    if(typeof rad1 === 'undefined')
        throw new Error('bestSphereRadius: missing required parameter "rad1"');

    if(typeof ecc1 === 'undefined')
        throw new Error('bestSphereRadius: missing required parameter "ecc1"');

    if(rad2 === null) rad2 = rad1;
    if(ecc2 === null) ecc2 = ecc1;

    if(rad3 === null) rad3 = rad1;
    if(ecc3 === null) ecc3 = ecc1;

    if(rad4 === null) rad4 = rad2;
    if(ecc4 === null) ecc4 = ecc2;

    const stepRad = 0.01;
    const stepDia = 0.1;
    const maxDiaStep = dia / stepDia;
    const maxRad = Math.round((rad1 + ecc1) * 100) / 100;

    let TestSquare = 0;
    for(let j = 0; j <= 1000; j++)
    {
        const TestRad = maxRad - j * stepRad;
        let TestSumSquare = 0;

        for(let k = 1; k <= maxDiaStep; k++)
        {
            const baseSAG = daSag(TestRad, 0, 0, k * stepDia);

            const Square1 = (daSag(rad1, ecc1, 0, k * stepDia) - baseSAG) ** 2;
            const Square2 = (daSag(rad2, ecc2, 0, k * stepDia) - baseSAG) ** 2;
            const Square3 = (daSag(rad3, ecc3, 0, k * stepDia) - baseSAG) ** 2;
            const Square4 = (daSag(rad4, ecc4, 0, k * stepDia) - baseSAG) ** 2;

            TestSumSquare += Square1 + Square2 + Square3 + Square4;
        }

        if(TestSumSquare > TestSquare && j > 1)
        {
            return Math.round((TestRad + 0.01) * 100) / 100;
        }

        TestSquare = TestSumSquare;
    }

    throw new Error('best sphere radius not found: ' + JSON.stringify({
        dia,
        rad1, ecc1,
        rad2, ecc2,
        rad3, ecc3,
        rad4, ecc4,
    }));
}
