<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div
        class="sl-form-dual__section" :class="{
            'sl-form-dual__section--open': currentSection.isOpen,
            'sl-form-dual__section--closed': !currentSection.isOpen
        }"
    >

        <!-- Section Heading -->
        <div v-if="!isHiddenSection" class="sl-form-dual__section-heading" @click="currentSection.isOpen = !currentSection.isOpen">
            <mdi-icon icon="mdiTriangle" />
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="formSectionLabel" />
            <mdi-icon icon="mdiTriangle" />
        </div>

        <!-- Section Content -->
        <div class="sl-form-dual__section-content">
            <component
                :is="getFormLineComponent(formLine)"
                v-for="(formLine, index) in formLines"
                :key="index"
                :index="index + 1"
                :form-line="formLine"
                @update="update"
            />
        </div>

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import FormLineGrouped from '@/forms/form-lines/FormLineGrouped';
import FormLineInline from '@/forms/form-lines/FormLineInline';
import FormLineSplit from '@/forms/form-lines/FormLineSplit';
import FormLineGroupedComponent from '@/components/forms/form-dual/form-sections/form-lines/grouped/FormLineGrouped';
import FormLineInlineComponent from '@/components/forms/form-dual/form-sections/form-lines/inline/FormLineInline';
import FormLineSplitComponent from '@/components/forms/form-dual/form-sections/form-lines/split/FormLineSplit';

export default
{
    name: 'FormSection',

    props:
    {
        formSection:
        {
            type:     Object,
            required: true,
        },
    },

    emits: ['update'],

    data()
    {
        return {
            currentSection: this.formSection,
        };
    },

    computed:
    {
        formSectionLabel()
        {
            const tkey = `parameters.sections.${this.currentSection.code}.label`;
            const label = this.$t(tkey);

            return (label !== tkey)
                ? label
                : this.currentSection.code;
        },

        /**
         * A "hidden" section is when section code is 'NONE' => meaning no label.
         */
        isHiddenSection()
        {
            return this.currentSection.code == 'NONE';
        },

        formLines()
        {
            console.log('Form sections has changed !!!');
            return this.currentSection.formLines;
        },
    },

    watch:
    {
        formSection(formSection)
        {
            this.currentSection = formSection;
        },
    },

    methods:
    {
        getFormLineComponent(formLine)
        {
            if(formLine instanceof FormLineSplit)
            {
                return FormLineSplitComponent;
            }
            else if(formLine instanceof FormLineGrouped)
            {
                return FormLineGroupedComponent;
            }
            else if(formLine instanceof FormLineInline)
            {
                return FormLineInlineComponent;
            }
            else
            {
                throw new Error(`There is no form line component for form line object "${formLine.constructor.name}".`);
            }
        },

        update(paramCode, paramValue, side)
        {
            console.log('FormSection', 'update', paramCode, paramValue, side);
            this.$emit('update', paramCode, paramValue, side);
        },
    },
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">

</style>
