export default class LinkReturnQuestionnairesToFormDual
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        const formDual = this.v.$store.state.lensReturn.formDual;
        const leftReturn = this.v.$store.getters['lensReturn/getReturn']('left');
        const rightReturn = this.v.$store.getters['lensReturn/getReturn']('right');

        if(leftReturn)
        {
            formDual.setParameters('left', leftReturn.getReturnQuestionnaire().getParameters());
        }

        if(rightReturn)
        {
            formDual.setParameters('right', rightReturn.getReturnQuestionnaire().getParameters());
        }

        return true;
    }
}
