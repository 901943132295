import FormLine from './FormLine.js';

export default class FormLineInline extends FormLine
{
    code = null;

    parameters = {
        left:  new Map(),
        right: new Map(),
    };

    fields = {
        left:  [],
        right: [],
    };

    constructor(code)
    {
        this.code = code;
    }

    getCode()
    {
        return this.code;
    }

    addParameter(side, parameter)
    {
        this.parameters[side].set(parameter.code, parameter);
    }

    getFields()
    {

    }
}
