export default class Meridian
{
    // ------------------------------------------------------------ MEMBERS

    /** @type {number} The angle of the meridian. */
    angle = 0;

    /** @type {number} The step/distance between each point. */
    resolution = 0;

    /**
     * The zone definitions of the meridian.
     * @type {Record<string, { dia: number, sag: number }>}
     */
    zones = {};

    /** @type {number[]} The sagittals of the meridian. */
    points = [];

    /** @type {boolean} Whether the meridian was artificially created. */
    calculated = false;


    // ------------------------------------------------------------ CONSTRUCTOR

    constructor({
        angle      = 0,
        resolution = 0,
        zones      = {},
        points     = [],
        calculated = false,
    } = {})
    {
        this.angle      = angle;
        this.resolution = resolution;
        this.zones      = JSON.parse(JSON.stringify(zones));
        this.points     = points.slice();
        this.calculated = calculated;
    }


    // ------------------------------------------------------------ METHODS

    maxDia()
    {
        return this.resolution * (this.points.length - 1) * 2;
    }

    maxSag()
    {
        return Math.max(0, ...this.points);
    }

    /**
     * Get the diameter at a specific point index.
     *
     * @param {number} i
     * @returns {number}
     */
    dia(i)
    {
        return i * this.resolution * 2;
    }

    /**
     * Get the sagittal at a specific diameter.
     * If the diameter isn't a multiple of the resolution, the return value is
     * interpolated from the previous and next points.
     *
     * @param {number} dia
     * @returns {number}
     */
    sag(dia)
    {
        if(isNaN(dia))
            throw new TypeError(`dia is not a number: ${dia}`);

        const maxDia = this.maxDia();
        if(dia < 0 || dia > this.maxDia())
            throw new RangeError(`dia is out of bounds: ${dia}/${maxDia}`);

        dia = dia / 2;
        const i = Math.round(dia / this.resolution);
        if(dia % this.resolution === 0)
        {
            // The diameter is a multiple of the resolution.
            // Use the calculated index.
            return this.points[i];
        }

        // The diameter is in-between two points.
        // Interpolate the SAG from the previous and next points.
        const prevI = Math.floor(dia / this.resolution);
        const nextI = Math.ceil(dia / this.resolution);

        /** The diameter of the previous point. */
        const x1 = prevI * this.resolution;

        /** The sagittal of the previous point. */
        const y1 = this.points[prevI];

        /** The diameter of the next point. */
        const x2 = nextI * this.resolution;

        /** The sagittal of the next point. */
        const y2 = this.points[nextI];

        if(x2 - x1 === 0)
        {
            return (y1 + y2) / 2;
        }

        return y1 + ((dia - x1) * (y2 - y1)) / (x2 - x1);
        // return y1 + ((dia - x1) / (x2 - x1)) * (y2 - y1);
    }

    /**
     * Get the diameter of the last point.
     *
     * @returns {number}
     */
    lastDia()
    {
        if(!this.resolution || !this.points.length)
            return NaN;

        return this.resolution * (this.points.length - 1) * 2;
    }

    /**
     * Get the sagittal of the last point.
     *
     * @returns {number}
     */
    lastSag()
    {
        if(!this.points.length)
            return NaN;

        return this.points.at(-1);
    }

    /**
     * Get the zone at a specific diameter.
     *
     * @param {number} dia
     * @returns {{code: string, dia: number, sag: number}}
     */
    zone(dia)
    {
        if(isNaN(dia))
            throw new TypeError(`dia is not a number: ${dia}`);

        const maxDia = this.maxDia();
        if(dia < 0 || dia > this.maxDia())
            throw new RangeError(`dia is out of bounds: ${dia}/${maxDia}`);

        const zoneNames = Object.keys(this.zones);
        let prev = null;
        for(let i = 0; i < zoneNames.length; i++)
        {
            const name = zoneNames[i];
            const zone = this.zones[name];
            if(i === 0)
            {
                if(dia <= zone.dia)
                {
                    return {
                        code: name,
                        ...zone,
                    };
                }

                continue;
            }

            prev = this.zones[zoneNames[i - 1]];
            if(dia > prev.dia && dia <= zone.dia)
            {
                return {
                    code: name,
                    ...zone,
                };
            }
        }

        return {
            code: 'UNKNOWN',
            dia:  0,
            sag:  0,
        };
    }
}
