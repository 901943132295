export default class TemplateReturnQuestionnaire
{
    /**
     * Vue Object
     */
    v = null;

    /**
     * {string} "left" or "right"
     */
    side =  null;

    constructor(v, side)
    {
        this.v = v;
        this.side = side;
    }

    async handle()
    {
        if(this.v.$store.state.lensReturn.returns[this.side])
        {
            return this.v.$store.dispatch('lensReturn/template', {
                side:              this.side,
                isInitialTemplate: true,
            });
        }

        return true;
    }
}
