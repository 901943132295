<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-main">
        <div class="sl-page sl-page--full">
            <div class="sl-page__header">
                <div class="sl-page__jumbotron">
                    {{ $t('return_confirmation.jumbotron') }}
                </div>

                <div v-if="isConfirmationEmailSent" class="m-auto max-w-md text-center text-gray-600 leading-snug">
                    <p>{{ $t('return_confirmation.got_your_return') }}</p>
                    <br>
                    {{ $t('return_confirmation.your_confirmation_email') }}:

                    <div v-for="email in emails" :key="email.id" class="text-primary-500 py-1">
                        {{ email.cvalue }}
                    </div>

                    <span class="text-gray-500 text-sm">
                        {{ $t('return_confirmation.check_spam_folder') }}
                    </span>
                </div>

                <div v-else class="m-auto max-w-md text-center text-gray-600 leading-snug">
                    <p>{{ $t('return_confirmation.got_your_return') }}</p>
                    <br>
                </div>
            </div>

            <div class="sl-page__content">
                <div class="text-xl text-primary-500 mt-5 mb-8 ml-3">
                    {{ $t('return_confirmation.your_returns') }}
                </div>

                <return-table
                    class="sl-return-table--confirmation"
                    :returns="returns"
                    context="return-confirmation"
                />

                <div class="mt-10 mx-4 text-gray-500 leading-relaxed text-sm text-center">
                    {{ $t('return_confirmation.price_list_warning') }}
                </div>

                <div class="flex mt-12">
                    <div class="w-1/3"></div>

                    <div class="w-1/3"></div>

                    <div class="w-1/3 flex justify-end">
                        <div>
                            <div class="pb-4 text-gray-600 text-center">
                                {{ $t('return_confirmation.question') }}
                            </div>

                            <div class="flex items-center py-3 px-6 text-primary-500 text-sm border border-b-0 border-gray-300">
                                <mdi-icon icon="mdiPhone" class="sl-contact-icon" />
                                <a :href="'tel:' + $cc.phone()">{{ $cc.phone() }}</a>
                            </div>

                            <div class="flex items-center py-3 px-6 text-primary-500 text-sm border border-gray-300 tracking-wider">
                                <mdi-icon icon="mdiEmailOutline" class="sl-contact-icon" />
                                <a :href="'mailto:' + $cc.email()">{{ $cc.email() }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import ReturnTable from '@/components/return/ReturnTable.vue';

export default
{
    components:
    {
        ReturnTable,
    },

    computed:
    {
        emails()
        {
            return this.$store.state.account.cEntity.contacts.filter(
                contact => contact.type === 'EMAIL' && (contact.notification_distributions || '').indexOf('ORDERS_CONFIRMATION') !== -1
            );
        },

        returns()
        {
            return this.$store.state.lensReturn.lastReturns;
        },

        isConfirmationEmailSent()
        {
            return false;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-total-line
{
    @apply flex flex-wrap mt-4;
}

.sl-total-line__label
{
    @apply grow mr-4 text-gray-600 text-right;
}

.sl-total-line__amount
{
    width: 120px;
    @apply mr-3 text-right text-primary-500;
}

.sl-total-line__text
{
    @apply w-full mr-3 mt-3 text-xs text-right text-gray-500;
}

.sl-contact-icon
{
    @apply inline-block align-middle mr-5 w-5 h-5 text-gray-600;
}
</style>
