<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="sl-form-dual__line-side" :class="`sl-form-dual__line-side-${side}`">
        <div class="sl-dimmer"></div>
    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

export default
{
    name: 'FormFieldNone',

    props:
    {
        side:
        {
            type:     String,
            required: true,
        },
    },
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">

.sl-dimmer
{
    @apply absolute inset-y-0 w-2/5 bg-gray-100;
}

</style>
