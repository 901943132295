/**
 * @typedef {import('@/composables/GraphComposable.js').Surface}  Surface
 */

import ColoringStrategy2D from './ColoringStrategy2D';
import useGraphComposable from '@/composables/GraphComposable.js';
import constants from '@/constants/constants';
import Meridian from '@/models/Meridian';

const DEFAULT_COLOR_SCHEME = 'DEFAULT';

const colors = constants.colorScales.SAGITTTAL;

/**
 * Color the squares using their absolute elevations.
 */
export default class SagittalColoringStrategy2D extends ColoringStrategy2D
{
    /**
     * Get the color for the specified point.
     *
     * @param {Meridian} meridian The meridian being evalutated.
     * @param {number}   dia      The diameter being evaluated.
     * @param {string}   scheme   The color scheme to use.
     * @returns {string} The evaluated color code.
     */
    getColor(meridian, dia, scheme = DEFAULT_COLOR_SCHEME)
    {
        const sag = meridian.sag(dia) * 1000;

        return colors[scheme].findLast(({ value }) => sag > value)?.color
            ?? '#fff';
    }
}
