export default class ValidateReturns
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        const requests        = [];
        const options         = {};
        const rightReturn = this.v.$store.getters['lensReturn/getReturn']('right');
        const leftReturn = this.v.$store.getters['lensReturn/getReturn']('left');

        // Set overlay text
        this.v.$store.commit('overlay/setText', this.v.$t('overlay.validating'));

        // Validate right side questionnaire
        if(rightReturn)
        {
            console.log('Validate right side questionnaire');
            requests.push(rightReturn.getReturnQuestionnaire().validate(options));
        }

        // Validate left side questionnaire
        if(leftReturn)
        {
            console.log('Validate left side questionnaire');
            requests.push(leftReturn.getReturnQuestionnaire().validate(options));
        }

        return Promise.all(requests)
            .then(response =>
            {
                console.log('Validation OK !');
                return Promise.resolve(response);
            })
            .catch(error =>
            {
                console.log('Questionnaire validation error:', error);
                return Promise.reject(error);
            });
    }
}
