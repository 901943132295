/**
 * @typedef {import('@/composables/GraphComposable.js').Surface}  Surface
 */

import Meridian from '@/models/Meridian';

const DEFAULT_COLOR_SCHEME = 'DEFAULT';

/**
 * Paint it black.
 * @see https://www.youtube.com/watch?v=O4irXQhgMqg
 */
export default class ColoringStrategy2D
{
    /**
     * Get the color for the specified point.
     *
     * @param {Meridian} meridian The meridian being evalutated.
     * @param {number}   dia      The diameter being evaluated.
     * @param {string}   scheme   The color scheme to use.
     * @returns {string} The evaluated color code.
     */
    getColor(meridian, dia, scheme = DEFAULT_COLOR_SCHEME)
    {
        return '#000';
    }
}
