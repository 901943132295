export default Object.freeze({
    AXIAL_RADIUS: Object.freeze({
        OCULUS: Object.freeze([
            { value: 0.00,  color: '#000000' },
            { value: 3.80,  color: '#323232' },
            { value: 4.00,  color: '#4b4b4b' },
            { value: 4.20,  color: '#646464' },
            { value: 4.40,  color: '#7d7d7d' },
            { value: 4.60,  color: '#969696' },
            { value: 4.80,  color: '#afafaf' },
            { value: 5.00,  color: '#c8c8c8' },
            { value: 5.20,  color: '#e1c8e1' },
            { value: 5.40,  color: '#ffafff' },
            { value: 5.80,  color: '#ff7dff' },
            { value: 6.00,  color: '#ff46ff' },
            { value: 6.20,  color: '#ff00ff' },
            { value: 6.40,  color: '#e100c8' },
            { value: 6.60,  color: '#c300af' },
            { value: 6.70,  color: '#a00096' },
            { value: 6.80,  color: '#8c007d' },
            { value: 6.90,  color: '#820050' },
            { value: 7.00,  color: '#960032' },
            { value: 7.10,  color: '#af0019' },
            { value: 7.20,  color: '#c80000' },
            { value: 7.30,  color: '#e10000' },
            { value: 7.40,  color: '#fa0000' },
            { value: 7.50,  color: '#fa4b00' },
            { value: 7.60,  color: '#fa6400' },
            { value: 7.70,  color: '#fa7d00' },
            { value: 7.80,  color: '#fa9600' },
            { value: 7.90,  color: '#faaf00' },
            { value: 8.00,  color: '#fac800' },
            { value: 8.10,  color: '#fae100' },
            { value: 8.20,  color: '#ffff00' },
            { value: 8.30,  color: '#e1ff00' },
            { value: 8.40,  color: '#c8ff00' },
            { value: 8.50,  color: '#96ff00' },
            { value: 8.60,  color: '#4beb00' },
            { value: 8.70,  color: '#00d700' },
            { value: 8.80,  color: '#00cd00' },
            { value: 8.90,  color: '#00b400' },
            { value: 9.00,  color: '#00aa00' },
            { value: 9.10,  color: '#00a000' },
            { value: 9.20,  color: '#009600' },
            { value: 9.30,  color: '#008200' },
            { value: 9.40,  color: '#006e00' },
            { value: 9.50,  color: '#006432' },
            { value: 9.60,  color: '#005a4b' },
            { value: 9.70,  color: '#005a64' },
            { value: 9.80,  color: '#005a78' },
            { value: 9.90,  color: '#004b87' },
            { value: 10.00, color: '#003296' },
            { value: 10.10, color: '#0019a5' },
            { value: 10.20, color: '#0000b9' },
            { value: 10.30, color: '#0000dc' },
            { value: 10.50, color: '#0000ff' },
            { value: 13.50, color: '#0064ff' },
            { value: 16.50, color: '#0087ff' },
            { value: 19.50, color: '#00afff' },
            { value: 22.50, color: '#00c8ff' },
            { value: 25.50, color: '#00dcff' },
            { value: 28.50, color: '#00f0ff' },
            { value: 31.50, color: '#00ffff' },
            { value: 34.50, color: '#96ffff' },
        ]),
    }),

    SAGITTTAL: Object.freeze({
        DEFAULT: Object.freeze([
            { value: 50,   color: '#323232' },
            { value: 100,  color: '#4b4b4b' },
            { value: 150,  color: '#646464' },
            { value: 200,  color: '#7d7d7d' },
            { value: 250,  color: '#969696' },
            { value: 300,  color: '#afafaf' },
            { value: 350,  color: '#c8c8c8' },
            { value: 400,  color: '#e1c8e1' },
            { value: 450,  color: '#ffafff' },
            { value: 500,  color: '#ff7dff' },
            { value: 550,  color: '#ff46ff' },
            { value: 600,  color: '#ff00ff' },
            { value: 650,  color: '#e100c8' },
            { value: 700,  color: '#c300af' },
            { value: 750,  color: '#a00096' },
            { value: 800,  color: '#8c007d' },
            { value: 850,  color: '#820050' },
            { value: 900,  color: '#960032' },
            { value: 950,  color: '#af0019' },
            { value: 1000, color: '#c80000' },
            { value: 1050, color: '#e10000' },
            { value: 1100, color: '#fa0000' },
            { value: 1150, color: '#fa4b00' },
            { value: 1200, color: '#fa6400' },
            { value: 1250, color: '#fa7d00' },
            { value: 1300, color: '#fa9600' },
            { value: 1350, color: '#faaf00' },
            { value: 1400, color: '#fac800' },
            { value: 1450, color: '#fae100' },
            { value: 1500, color: '#ffff00' },
            { value: 1550, color: '#e1ff00' },
            { value: 1600, color: '#c8ff00' },
            { value: 1650, color: '#96ff00' },
            { value: 1700, color: '#4beb00' },
            { value: 1750, color: '#00d700' },
            { value: 1800, color: '#00cd00' },
            { value: 1850, color: '#00b400' },
            { value: 1900, color: '#00aa00' },
            { value: 1950, color: '#00a000' },
            { value: 2000, color: '#009600' },
            { value: 2050, color: '#008200' },
            { value: 2100, color: '#006e00' },
            { value: 2150, color: '#006432' },
            { value: 2200, color: '#005a4b' },
            { value: 2250, color: '#005a64' },
            { value: 2300, color: '#005a78' },
            { value: 2350, color: '#004b87' },
            { value: 2400, color: '#003296' },
            { value: 2450, color: '#0019a5' },
            { value: 2500, color: '#0000b9' },
            { value: 2550, color: '#0000dc' },
            { value: 2600, color: '#0000ff' },
            { value: 2650, color: '#0064ff' },
            { value: 2700, color: '#0087ff' },
            { value: 2750, color: '#00afff' },
            { value: 2800, color: '#00c8ff' },
            { value: 2850, color: '#00dcff' },
            { value: 2900, color: '#00f0ff' },
            { value: 2950, color: '#00ffff' },
            { value: 3000, color: '#96ffff' },
        ]),
    }),

    BEST_SPHERE: Object.freeze({
        OCULUS: Object.freeze([
            { value: -150, color: '#000000' },
            { value: -145, color: '#323232' },
            { value: -140, color: '#4b4b4b' },
            { value: -135, color: '#646464' },
            { value: -130, color: '#7d7d7d' },
            { value: -125, color: '#969696' },
            { value: -120, color: '#afafaf' },
            { value: -115, color: '#c8c8c8' },
            { value: -110, color: '#e1c8e1' },
            { value: -105, color: '#ffafff' },
            { value: -100, color: '#ff7dff' },
            { value: -95,  color: '#ff46ff' },
            { value: -90,  color: '#ff00ff' },
            { value: -85,  color: '#e100c8' },
            { value: -80,  color: '#c300af' },
            { value: -75,  color: '#a00096' },
            { value: -70,  color: '#8c007d' },
            { value: -65,  color: '#820050' },
            { value: -60,  color: '#960032' },
            { value: -55,  color: '#af0019' },
            { value: -50,  color: '#c80000' },
            { value: -45,  color: '#e10000' },
            { value: -40,  color: '#fa0000' },
            { value: -35,  color: '#fa4b00' },
            { value: -30,  color: '#fa6400' },
            { value: -25,  color: '#fa7d00' },
            { value: -20,  color: '#fa9600' },
            { value: -15,  color: '#faaf00' },
            { value: -10,  color: '#fac800' },
            { value: -5,   color: '#fae100' },
            { value: 0,    color: '#ffff00' },
            { value: 5,    color: '#e1ff00' },
            { value: 10,   color: '#c8ff00' },
            { value: 15,   color: '#96ff00' },
            { value: 20,   color: '#4beb00' },
            { value: 25,   color: '#00d700' },
            { value: 30,   color: '#00cd00' },
            { value: 35,   color: '#00b400' },
            { value: 40,   color: '#00aa00' },
            { value: 45,   color: '#00a000' },
            { value: 50,   color: '#009600' },
            { value: 55,   color: '#008200' },
            { value: 60,   color: '#006e00' },
            { value: 65,   color: '#006432' },
            { value: 70,   color: '#005a4b' },
            { value: 75,   color: '#005a64' },
            { value: 80,   color: '#005a78' },
            { value: 85,   color: '#004b87' },
            { value: 90,   color: '#003296' },
            { value: 95,   color: '#0019a5' },
            { value: 100,  color: '#0000b9' },
            { value: 105,  color: '#0000dc' },
            { value: 110,  color: '#0000ff' },
            { value: 115,  color: '#0064ff' },
            { value: 120,  color: '#0087ff' },
            { value: 125,  color: '#00afff' },
            { value: 130,  color: '#00c8ff' },
            { value: 135,  color: '#00dcff' },
            { value: 140,  color: '#00f0ff' },
            { value: 145,  color: '#00ffff' },
            { value: 150,  color: '#96ffff' },
        ]),
    }),

    TEAR_FILM: Object.freeze({
        FLUO_GREEN: Object.freeze([
            { value: -100, color: '#000' },
            { value: 0,    color: '#000' },
            { value: 7,    color: '#010' },
            { value: 13,   color: '#020' },
            { value: 20,   color: '#030' },
            { value: 27,   color: '#040' },
            { value: 33,   color: '#050' },
            { value: 40,   color: '#060' },
            { value: 47,   color: '#070' },
            { value: 53,   color: '#080' },
            { value: 60,   color: '#090' },
            { value: 67,   color: '#0A0' },
            { value: 73,   color: '#0B0' },
            { value: 80,   color: '#0C0' },
            { value: 87,   color: '#0D0' },
            { value: 93,   color: '#0E0' },
            { value: 100,  color: '#0F0' },
            { value: 500,  color: '#0F0' },
        ]),
    }),
});
