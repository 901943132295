import FormLine from './FormLine.js';

export default class FormLineSplit extends FormLine
{
    parameters = {
        left:  null,
        right: null,
    };

    fields = {
        left:  null,
        right: null,
    };

    constructor(leftParameter, rightParameter)
    {
        super();
        this.parameters.left = leftParameter;
        this.parameters.right = rightParameter;
    }

    render()
    {
        this.fields.left = this.getFormField(this.parameters.left);
        this.fields.right = this.getFormField(this.parameters.right);

        console.log('Left form field:', this.fields.left);
        console.log('Right form field:', this.fields.right);
    }
}
