export default class ValidateLensOrderIdentifier
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        let orderIdentifier = this.v.orderIdentifier;

        if(orderIdentifier == '' || orderIdentifier == null)
        {
            this.v.orderIdentifierFormError = this.v.$t('return.form.error.empty');
            return false;
        }

        return true;
    }
}
