import app         from './app';
import articles    from './articles';
import colorScales from './color-scales';
import entities    from './entities';
import icons       from './icons';
import lensFilters from './lens-filters';
import tags        from './tags';
import orders      from './orders';

/**
 * The application's constants.
 */
export default Object.freeze(
    {
        app,
        articles,
        colorScales,
        entities,
        icons,
        lensFilters,
        tags,
        orders,
    }
);
