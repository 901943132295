import axios from '@/axios';
import Order from '@/models/Order';
import Return from '@/models/Return';
import LensReturnUrlManager from '../LensReturnUrlManager';

export default class FetchPairedOrderByReference
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        /** @type {LensReturnUrlManager} */
        const lensReturnUrlManager = this.v.lensReturnUrlManager;

        // Check if both lenses must be returned
        if(lensReturnUrlManager.getReturnBothLenses())
        {
            console.log(`Paired lens order must be retrieved`);

            const leftReturn = this.v.leftReturn;

            return axios.get(`/api/order/paired/reference/${leftReturn.order.reference}`)
                .then(response =>
                {
                    const order = new Order(response.data);

                    if(order.has_been_returned)
                    {
                        this.v.orderIdentifierFormError = this.v.$t('return.form.error.paired_already_returned');

                        return false;
                    }

                    this.v.rightReturn = new Return(order);

                    console.log(`Paired return has been set`);

                    return true;
                })
                .catch(error =>
                {
                    this.v.rightReturn = null;

                    // @todo : display "paired return not found" modal

                    return false;
                });
        }
        else
        {
            console.log(`Paired lens order must NOT be retrieved`);
        }

        return true;
    }
}
