import FormLine from './FormLine.js';

export default class FormLineGrouped extends FormLine
{
    parameters = {
        left:  null,
        right: null,
    };

    fields = {
        left:  null,
        right: null,
    };

    constructor(leftParameter, rightParameter)
    {
        this.parameters.left = leftParameter;
        this.parameters.right = rightParameter;
    }
}
