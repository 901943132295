import FormField from "./FormField";

export default class FormFieldNumber extends FormField
{
    getHelper()
    {
        const { min, max, step } = this.parameter;

        if(min === null || max === null || step === null)
        {
            return null;
        }

        return this.formatValue(min)
            + '&nbsp;&rarr;&nbsp;'
            + this.formatValue(max)
            + '<br>&plusmn;&nbsp;'
            + (this.formatValue(step) + '').replace(/\+|-/g, '');
    }
}
