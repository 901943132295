<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-main">
        <div v-if="!initializing" class="sl-page sl-page--full sl-page--paddless">
            <!-- Eyes Header (Right and Left) -->
            <eyes-header />

            <template v-if="showPrototypesSelection">
                <!-- Renewal Form (Right and Left) -->
                <div class="flex px-4 mt-4">
                    <renewal-form
                        v-model:orderIdentifier="sides.right.orderIdentifier"
                        :other-side-order-identifier="sides.left.orderIdentifier"
                        :error="sides.right.error"
                        side="right"
                        class="w-1/2 mr-2"
                        @next="next"
                    />

                    <renewal-form
                        v-model:orderIdentifier="sides.left.orderIdentifier"
                        :other-side-order-identifier="sides.right.orderIdentifier"
                        :error="sides.left.error"
                        side="left"
                        class="w-1/2 ml-2"
                        @next="next"
                    />
                </div>

                <!-- "or" Text Line -->
                <div class="flex items-center my-3">
                    <div class="w-1/2 text-primary-500 text-center">
                        {{ $t('common.or') }}
                    </div>

                    <!-- "Next" Button -->
                    <div v-if="!editPrototypesSelection" class="flex justify-center">
                        <button class="lsn-btn" :class="nextButtonClass" @click="next">
                            {{ $t('common.next') }}
                        </button>
                    </div>

                    <div class="w-1/2 text-primary-500 text-center">
                        {{ $t('common.or') }}
                    </div>
                </div>

                <!-- Prototypes Lists (Right and Left) -->
                <div class="flex px-4">
                    <prototypes-list
                        :prototype-code="sides.right.prototypeCode"
                        side="right"
                        class="w-1/2 mr-2"
                        @prototype-code:set="onPrototypeSet"
                    />

                    <prototypes-list
                        :prototype-code="sides.left.prototypeCode"
                        side="left"
                        class="w-1/2 ml-2"
                        @prototype-code:set="onPrototypeSet"
                    />
                </div>

                <!-- "Next" Button -->
                <div v-if="!editPrototypesSelection" class="flex justify-center my-6">
                    <button class="lsn-btn" :class="nextButtonClass" @click="next">
                        {{ $t('common.next') }}
                    </button>
                </div>

                <!-- Bottom Buttons -->
                <div v-if="editPrototypesSelection" class="flex justify-center my-6">
                    <!-- Cancel -->
                    <button class="lsn-btn lsn-btn--gray-outline mr-4" @click="editPrototypesSelection = false">
                        {{ $t('common.cancel') }}
                    </button>

                    <!-- Edit -->
                    <button
                        class="lsn-btn lsn-btn--primary sl-order__submit"
                        :class="isEditButtonActive
                            ? 'lsn-btn--primary sl-order__submit'
                            : 'lsn-btn--gray-outline lsn-btn--disabled'"
                        @click="next"
                    >
                        {{ $t('common.edit') }}
                    </button>
                </div>
            </template>

            <template v-if="showLensForms">
                <!-- Prototypes Display (Right and Left) -->
                <div class="flex items-center justify-center">
                    <selected-prototypes @edit-prototype-selection="editPrototypesSelection = true" />
                </div>

                <lens-order-form />

                <!-- "Add to Basket" Button -->
                <div v-if="!isOrderEdition" class="my-6">
                    <div class="flex justify-center w-full">
                        <button class="lsn-btn lsn-btn--primary sl-order__submit" :tabindex="3" @click="addToBasket">
                            {{ addToBasketButtonLabel }}
                        </button>
                    </div>
                </div>

                <!-- "Edit" Button -->
                <template v-else>
                    <div class="my-6">
                        <div class="flex justify-center w-full">
                            <button class="lsn-btn lsn-btn--primary sl-order__submit" :tabindex="3" @click="updateOrders">
                                {{ $t('common.save') }}
                            </button>
                        </div>
                    </div>
                </template>

            </template>
        </div>

        <!-- Loader (while initializing or loading) -->
        <div v-if="initializing || loading" class="mt-8 mx-auto text-center">
            <img class="inline" src="/img/loader-5.gif">
        </div>

        <!-- Re-order: not same entity modal warning -->
        <choose-entity-modal
            :is-open="showChooseEntityModal"
            :sides="sides"
            @close="showChooseEntityModal = false"
            @next="dispatch"
        />

        <!-- Re-order: not same patient modal warning -->
        <choose-patient-modal
            :is-open="showChoosePatientModal"
            :sides="sides"
            @close="showChoosePatientModal = false"
            @next="dispatch"
        />

        <!-- Re-order: side inconsistency warning -->
        <choose-side-modal
            :is-open="showChooseSideModal"
            :sides="sides"
            @close="showChooseSideModal = false"
            @next="dispatch"
            @invert="invertAndDispatch"
        />

        <!-- Dispatch Error -->
        <teleport to="body">
            <lsn-modal :is-open="dispatchError !== null">
                <div class="sl-prompt sl-prompt--danger text-center">
                    <div class="sl-prompt__contents">
                        <div class="sl-prompt__title">
                            {{ dispatchErrorTitle }}
                        </div>

                        <div class="sl-prompt__text">
                            {{ dispatchErrorText }}
                        </div>
                    </div>

                    <div class="sl-prompt__actions">
                        <button class="sl-prompt__button sl-prompt__button--white" @click="dispatchError = null">
                            {{ $t('common.close') }}
                        </button>
                    </div>
                </div>
            </lsn-modal>
        </teleport>

        <!-- Pricing Error -->
        <teleport to="body">
            <lsn-modal :is-open="showPricingError">
                <div class="sl-prompt sl-prompt--info text-center">
                    <div class="sl-prompt__contents">
                        <div class="sl-prompt__title">
                            {{ $t('order.errors.pricing.article_unavailable.title') }}
                        </div>

                        <div class="sl-prompt__text">
                            {{ $t('order.errors.pricing.article_unavailable.description') }}
                        </div>
                    </div>

                    <div class="sl-prompt__actions">
                        <button class="sl-prompt__button sl-prompt__button--white" @click="showPricingError = false">
                            {{ $t('common.close') }}
                        </button>
                    </div>
                </div>
            </lsn-modal>
        </teleport>

        <!-- Fitting Selector Button -->
        <teleport to="body">
            <div
                v-if="showLensForms"
                v-tippy="{ placement: 'right' }"
                :content="$t(fittingsPanelOpen ? 'common.close' : 'fitting_selector.title')"
                class="sl-fittings-button"
                @click="toggleFittingsPanel"
            >
                <mdi-icon
                    icon="mdiSwapHorizontalCircleOutline"
                    class="transform transition-transform duration-300"
                    :class="fittingsPanelOpen ? 'rotate-180' : 'rotate-0'"
                />
            </div>
        </teleport>

        <!-- Calculators Button -->
        <teleport to="body">
            <div
                v-if="showLensForms"
                v-tippy="{ placement: 'left' }"
                :content="$t(calculatorsPanelOpen ? 'common.close' : 'calculators.calculators')"
                class="sl-calculators-button"
                @click="toggleCalculatorsPanel"
            >
                <mdi-icon :icon="calculatorsPanelOpen ? $const.icons.mdiClose : $const.icons.mdiCalculator" />
            </div>
        </teleport>

        <!-- View as Customer Button (aka "Ninja Mode" or "Cheat Code") -->
        <teleport to="body">
            <div
                v-if="showLensForms && $store.getters['account/isStaff']"
                v-tippy="{ placement: 'left' }"
                :content="$t(viewAsCustomer ? 'No Cheating' : 'Cheat Code')"
                class="sl-view-as-customer-button"
                :class="{ 'sl-view-as-customer-button--active': viewAsCustomer }"
                @click="toggleViewAsCustomer"
            >
                <mdi-icon
                    :icon="viewAsCustomer
                        ? $const.icons.mdiNinja
                        : $const.icons.mdiNinja"
                />
            </div>
        </teleport>

        <!-- Right OFG (Online Fitting Guide) Panel -->
        <lsn-sider v-if="showLensForms" :is-open="rightOfgPanelOpen" side="left" class="sl-widgets sl-ofg-panel w-96">
            <sl-ofg side="right" @close="closeRightOfgPanel" />
        </lsn-sider>

        <!-- Left OFG Panel -->
        <lsn-sider v-if="showLensForms" :is-open="leftOfgPanelOpen" class="sl-widgets sl-ofg-panel w-96">
            <sl-ofg side="left" @close="closeLeftOfgPanel" />
        </lsn-sider>

        <!-- Over-Refraction Panel -->
        <lsn-sider v-if="showLensForms" :is-open="overRefractionPanelOpen" class="sl-widgets w-[22rem]">
            <sl-over-refraction-calculator @close="closeOverRefractionPanel" />
        </lsn-sider>

        <!-- Fitting Selector Panel -->
        <lsn-sider v-if="showLensForms" :is-open="fittingsPanelOpen" side="left" class="sl-widgets w-80">
            <sl-fitting-selector class="sl-widget" @close="closeFittingsPanel" />
        </lsn-sider>

        <!-- Calculators Panel -->
        <lsn-sider v-if="showLensForms && hasCalculators" :is-open="calculatorsPanelOpen" class="sl-widgets w-80">
            <sl-calculators @close="closeCalculatorsPanel" />
        </lsn-sider>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import EyesHeader                                from './EyesHeader.vue';
import RenewalForm                               from './RenewalForm.vue';
import SelectedPrototypes                        from './SelectedPrototypes.vue';
import PrototypesList                            from './PrototypesList.vue';
import ChooseEntityModal                         from './ChooseEntityModal.vue';
import ChoosePatientModal                        from './ChoosePatientModal.vue';
import ChooseSideModal                           from './ChooseSideModal.vue';
import LensOrderForm                             from './form-lines/FormLines.vue';
import SlOfg                                     from './ofg/SlOfg.vue';
import SlOverRefractionCalculator                from './OverRefractionCalculator.vue';
import SlFittingSelector                      from './FittingSelector.vue';
import SlCalculators                             from './Calculators.vue';
import {
    default as LensOrderUrlManager,
    ID_TYPES,
}                                                from './LensOrderUrlManager';
import { ONLY_NUMBERS }                          from '@/utils/Regexes';
import UnknownPrototypeError                     from '@/errors/UnknownPrototypeError';
import useFittingComposable                   from '@/composables/FittingComposable';

// Chain of responsibility handlers: "dispatch"
import ResetErrors                               from './handlers/ResetErrors';
import ResetSideFittingOnConditions           from './handlers/ResetSideFittingOnConditions';
import FetchPreviousOrderByReference             from './handlers/FetchPreviousOrderByReference';
import FetchPreviousOrderByLensSn                from './handlers/FetchPreviousOrderByLensSn';
import FetchPreviousOrderByLensOemNumber         from './handlers/FetchPreviousOrderByLensOemNumber';
import CheckForSomethingOrStop                   from './handlers/CheckForSomethingOrStop';
import ValidatePreviousOrdersEntitiesConsistency from './handlers/ValidatePreviousOrdersEntitiesConsistency';
import ValidatePreviousOrdersPatientsConsistency from './handlers/ValidatePreviousOrdersPatientsConsistency';
import ValidateLensSidesConsistency              from './handlers/ValidateLensSidesConsistency';
import SilentlySetPrototypeCodesInUrl            from './handlers/SilentlySetPrototypeCodesInUrl';
import CreateFittingIfNotExists               from './handlers/CreateFittingIfNotExists';
import SetFittingPrototype                    from './handlers/SetFittingPrototype';
import SetFittingValuesByPreviousOrder        from './handlers/SetFittingValuesByPreviousOrder';
import SetFittingValuesByBasketLine           from './handlers/SetFittingValuesByBasketLine';
import SetPrototypePreviousValuesByPreviousOrder from './handlers/SetPrototypePreviousValuesByPreviousOrder';
import TemplatePrototypeForms                    from './handlers/TemplatePrototypeForms';
import SetPrototypesSide                         from './handlers/SetPrototypesSide';
import CompleteGroupedParameterValues            from './handlers/CompleteGroupedParameterValues';

// Chain of responsibility handlers: "add to basket" / "update order"
import ValidateLenses                            from './handlers/ValidateLenses';
import PriceLenses                               from './handlers/PriceLenses';
import SetLensPairId                             from './handlers/SetLensPairId';
import AddToBasket                               from './handlers/AddToBasket';
import NavigateToBasket                          from './handlers/NavigateToBasket';
import UpdateOrders                              from './handlers/UpdateOrders';
import NavigateToWelcome                         from './handlers/NavigateToWelcome';
import RefreshWaitingForReviewOrdersList         from './handlers/RefreshWaitingForReviewOrdersList';

// Chain of responsibility maps (used as queues)
const dispatchQueue    = new Map();
const addToBasketQueue = new Map();
const updateOrderQueue = new Map();

// Constants
const SIDE_RIGHT = 'right';
const SIDE_LEFT  = 'left';

/** @constant {Array} SUPPORTED_ID_TYPES List of order identifier types supported by `RenewalForm`. */
const SUPPORTED_ID_TYPES = [
    ID_TYPES.LENS_SN,
    ID_TYPES.LENS_OEM_NUMBER,
];

export const PROTOTYPES_WITHOUT_CALCULATORS = [
    // NightFlex
    'NIGHTFLEX_SYMMETRICAL_MYOPIA',
    'NIGHTFLEX_SYMMETRICAL_HIGH_MYOPIA',
    'NIGHTFLEX_SYMMETRICAL_HYPEROPIA',
    'NIGHTFLEX_SYMMETRICAL_PRESBYOPIA',
    'NIGHTFLEX_SYMMETRICAL_RELAX',
    'NIGHTFLEX_ASYMMETRICAL_MYOPIA',
    'NIGHTFLEX_ASYMMETRICAL_HIGH_MYOPIA',
    'NIGHTFLEX_ASYMMETRICAL_HYPEROPIA',
    'NIGHTFLEX_ASYMMETRICAL_PRESBYOPIA',
    'NIGHTFLEX_ASYMMETRICAL_RELAX',
];

export default
{
    name: 'OrderLenses',

    components:
    {
        EyesHeader,
        RenewalForm,
        SelectedPrototypes,
        PrototypesList,
        ChooseEntityModal,
        ChoosePatientModal,
        ChooseSideModal,
        LensOrderForm,
        SlOfg,
        SlOverRefractionCalculator,
        SlFittingSelector,
        SlCalculators,
    },

    setup()
    {
        const {
            getLensLetter,
        } = useFittingComposable();

        return {
            getLensLetter,
        };
    },

    data()
    {
        return {
            initializing:            false,
            loading:                 false,
            editPrototypesSelection: false,
            showChooseEntityModal:   false,
            showChoosePatientModal:  false,
            showChooseSideModal:     false,
            showPricingError:        false,
            dispatchOnRouteChange:   true,
            isEditButtonActive:      false,
            fittingsPanelOpen:       false,
            calculatorsPanelOpen:    false,

            /** @type {LensOrderUrlManager} */
            lensOrderUrlManager: null,

            sides:
            {
                right:
                {
                    orderIdentifier: null,
                    prototypeCode:   null,
                    previousOrder:   null,
                    error:           null,
                },
                left:
                {
                    orderIdentifier: null,
                    prototypeCode:   null,
                    previousOrder:   null,
                    error:           null,
                },
            },
            dispatchError: null,
        };
    },

    computed:
    {
        isNextButtonActive()
        {
            return !this.sides.right.error &&
                !this.sides.left.error     &&
                (
                    this.sides.left.prototypeCode   ||
                    this.sides.right.prototypeCode  ||
                    this.sides.left.orderIdentifier ||
                    this.sides.right.orderIdentifier
                );
        },

        nextButtonClass()
        {
            return this.isNextButtonActive
                ? 'lsn-btn--primary sl-order__submit'
                : 'lsn-btn--gray-outline lsn-btn--disabled';
        },

        showPrototypesSelection()
        {
            return !this.loading && (!this.showLensForms || this.editPrototypesSelection);
        },

        showLensForms()
        {
            const rightPrototype = this.$store.getters['lensOrder/getPrototype'](SIDE_RIGHT);
            const leftPrototype  = this.$store.getters['lensOrder/getPrototype'](SIDE_LEFT);

            return !this.loading && (rightPrototype || leftPrototype);
        },

        hasCalculators()
        {
            const rightPrototypeCode = this.$store.getters['lensOrder/getPrototype'](SIDE_RIGHT)?.code;
            const leftPrototypeCode  = this.$store.getters['lensOrder/getPrototype'](SIDE_LEFT) ?.code;

            return !PROTOTYPES_WITHOUT_CALCULATORS.some(
                p => [rightPrototypeCode, leftPrototypeCode].includes(p)
            );
        },

        rightOfgPanelOpen()
        {
            return this.$store.getters['lensOrder/getFitting']('right')?.getOfgOpen() || false;
        },

        leftOfgPanelOpen()
        {
            return this.$store.getters['lensOrder/getFitting']('left')?.getOfgOpen() || false;
        },

        overRefractionPanelOpen()
        {
            return this.$store.state.calculators.overRefraction.panelOpen;
        },

        /**
         * Used in handlers!
         */
        cEntity()
        {
            return this.$store.state.account.cEntity;
        },

        isOrderEdition()
        {
            return this.$route.name === 'edit-order-lenses';
        },

        viewAsCustomer()
        {
            return this.$store.getters['lensOrder/viewAsCustomer'];
        },

        dispatchErrorTitle()
        {
            if(this.dispatchError === null)
            {
                return null;
            }

            if(this.dispatchError instanceof UnknownPrototypeError)
            {
                return this.$t('order.errors.dispatch.unknown_prototype.title');
            }

            return this.dispatchError.message ||
                this.$t('order.errors.dispatch.unknown.title');
        },

        dispatchErrorText()
        {
            if(this.dispatchError === null)
            {
                return null;
            }

            if(this.dispatchError instanceof UnknownPrototypeError)
            {
                return this.$t('order.errors.dispatch.unknown_prototype.text', {
                    proto: this.dispatchError.prototypeCode,
                });
            }

            return this.dispatchError.message ||
                this.$t('order.errors.dispatch.unknown.text');
        },

        addToBasketButtonLabel()
        {
            const label = [this.$t('order.lenses.sections.add-to-basket-btn')];
            const hasRightFitting = !!this.$store.getters['lensOrder/getFitting']('right');
            const hasLeftFitting  = !!this.$store.getters['lensOrder/getFitting']('left');

            if(
                this.$store.state.lensOrder.fittings.right.length <= 2 &&
                this.$store.state.lensOrder.fittings.left.length <= 2
            )
            {
                return label[0];
            }

            if(hasRightFitting || hasLeftFitting)
            {
                label.push(' (');
            }

            if(hasRightFitting)
            {
                label.push(this.getLensLetter(this.$store.state.lensOrder.fittings.current.right));
            }

            if(hasRightFitting && hasLeftFitting)
            {
                label.push('/');
            }

            if(hasLeftFitting)
            {
                label.push(this.getLensLetter(this.$store.state.lensOrder.fittings.current.left));
            }

            if(hasRightFitting || hasLeftFitting)
            {
                label.push(')');
            }

            return label.join('');
        },
    },


    // ------------------------------------------------------------ WATCHERS

    watch:
    {
        /**
         * Unset right prototye code if a non-null order identifier is given.
         *
         * @param {*} orderIdentifier
         */
        'sides.right.orderIdentifier'(orderIdentifier)
        {
            if(orderIdentifier)
            {
                this.sides.right.prototypeCode = null;
            }

            this.sides.right.error = null;
            this.isEditButtonActive = true;
        },

        'sides.right.prototypeCode'()
        {
            this.isEditButtonActive = true;
        },

        /**
         * Unset left prototye code if a non-null order identifier is given.
         *
         * @param {*} orderIdentifier
         */
        'sides.left.orderIdentifier'(orderIdentifier)
        {
            if(orderIdentifier)
            {
                this.sides.left.prototypeCode = null;
            }

            this.sides.left.error = null;
            this.isEditButtonActive = true;
        },

        'sides.left.prototypeCode'()
        {
            this.isEditButtonActive = true;
        },

        '$route.params'(newParams, oldParams)
        {
            // Avoid retemplating when adding to basket
            if(!['order-lenses', 'edit-order-lenses'].includes(this.$route.name))
            {
                console.log('route has changed: dispatch avoided.');
                return;
            }

            this.lensOrderUrlManager.setFromRoute(this.$route);

            // Exception when user clicks on "New Order" menu
            if(!newParams.sides)
            {
                this.sides.right.orderIdentifier = null;
                this.sides.right.previousOrder   = null;
                this.sides.right.prototypeCode   = null;

                this.sides.left.orderIdentifier  = null;
                this.sides.left.previousOrder    = null;
                this.sides.left.prototypeCode    = null;

                this.editPrototypesSelection = false;

                // Reset both sides
                this.$store.commit('lensOrder/resetFitting');
            }
            else if(this.dispatchOnRouteChange)
            {
                console.log('reacting to route changes!', oldParams, newParams);

                this.syncDataFromUrl();
                this.dispatch();
            }
            else
            {
                console.log('not reacting to route changes!');
            }
        },
    },


    // ------------------------------------------------------------ LIFECYCLE

    created()
    {
        this.setupDispatchQueue();
        this.setupAddToBasketQueue();
        this.setupUpdateOrdersQueue();

        this.lensOrderUrlManager = new LensOrderUrlManager(this.$route);

        this.syncDataFromUrl();

        // Lens URL manager "has()" method will return "true" if any of "lens sn", "order reference" or "prototype code" is present.
        if(this.lensOrderUrlManager.has(SIDE_RIGHT) || this.lensOrderUrlManager.has(SIDE_LEFT))
        {
            this.initializing = true;
            this.dispatch();
        }
        else
        {
            console.log('Nothing on any sides at creation');
        }
    },

    unmounted()
    {
        delete this.$route.params.sides; // IMPORTANT
        this.$store.commit('lensOrder/resetFitting'); // reset both sides
    },


    // ------------------------------------------------------------ METHODS

    methods:
    {
        // ---------------------------------------- helper

        syncDataFromUrl()
        {
            for(const side of [SIDE_RIGHT, SIDE_LEFT])
            {
                this.sides[side].orderIdentifier = SUPPORTED_ID_TYPES.includes(this.lensOrderUrlManager.getIdentifierType(side))
                    ? this.lensOrderUrlManager.getOrderIdentifier(side)
                    : null;
                this.sides[side].prototypeCode = this.lensOrderUrlManager.getPrototypeCode(side);

                if(!this.lensOrderUrlManager.has(side))
                {
                    this.sides[side].previousOrder = null;
                }
            }
        },

        // ---------------------------------------- dispatch

        async dispatch(from = null)
        {
            console.log(`DISPATCH START! (from: ${from})`);

            let firstHandlerFound = false;

            // If no "from" has been given.
            if(!from)
            {
                // Set first key as "from".
                from = dispatchQueue.keys().next().value;
            }

            this.loading = true;
            this.editPrototypesSelection = false;

            for(const [key, handler] of dispatchQueue.entries())
            {
                if(key.includes(from))
                {
                    firstHandlerFound = true;
                }

                if(firstHandlerFound)
                {
                    console.groupCollapsed('Handler: ' + key);

                    try
                    {
                        const result = await handler.handle();
                        if(result === false)
                        {
                            console.groupEnd();
                            console.log('DISPATCH BREAK!');
                            break;
                        }
                    }
                    catch(error)
                    {
                        console.groupEnd();
                        console.log('DISPATCH BREAK!', error);
                        this.dispatchError = error;
                        break;
                    }

                    console.groupEnd();
                }
            }

            this.loading = false;
            this.initializing = false;

            console.log('DISPATCH END!');
        },

        // ---------------------------------------- add to basket

        async addToBasket()
        {
            console.log('ADD TO BASKET START!');

            this.$store.commit('overlay/setVisibility', true);

            for(const [key, handler] of addToBasketQueue.entries())
            {
                console.groupCollapsed('Handler: ' + key);

                try
                {
                    const result = await handler.handle();

                    if(result === false)
                    {
                        console.groupEnd();
                        console.log('ADD TO BASKET BREAK!');
                        break;
                    }
                }
                catch(error)
                {
                    console.groupEnd();
                    console.log('ADD TO BASKET BREAK!', error);
                    break;
                }

                console.groupEnd();
            }

            this.$store.commit('overlay/setVisibility', false);

            console.log('ADD TO BASKET END!');
        },


        // ---------------------------------------- update

        async updateOrders()
        {
            console.log('UPDATE ORDERS START!');

            this.$store.commit('overlay/setVisibility', true);

            for(const [key, handler] of updateOrderQueue.entries())
            {
                console.groupCollapsed('Handler: ' + key);

                try
                {
                    const result = await handler.handle();

                    if(result === false)
                    {
                        console.groupEnd();
                        console.log('UPDATE ORDERS BREAK!', result);
                        break;
                    }
                }
                catch(error)
                {
                    console.groupEnd();
                    console.log('UPDATE ORDERS BREAK!', error);
                    break;
                }

                console.groupEnd();
            }

            this.$store.commit('overlay/setVisibility', false);

            console.log('UPDATE ORDERS END!');
        },

        // ---------------------------------------- Next

        next()
        {
            if(!this.isNextButtonActive || !this.isEditButtonActive)
            {
                return;
            }

            for(const side of [SIDE_RIGHT, SIDE_LEFT])
            {
                const orderIdentifier = this.sides[side].orderIdentifier;

                // Detect if the serial number is from SwissLens or an OEM
                if(ONLY_NUMBERS.test(orderIdentifier))
                {
                    if(orderIdentifier < 30_000_000)
                    {
                        // Only numbers < 30'000'000 => SwissLens Serial Number
                        this.lensOrderUrlManager.setLensSn(side, orderIdentifier);
                    }
                    else
                    {

                        // Only numbers > 30'000'000 => SwissLens Order reference
                        this.lensOrderUrlManager.setOrderReference(side, orderIdentifier);
                    }
                }
                else
                {
                    // Not only numbers => OEM Number
                    this.lensOrderUrlManager.setOemNumber(side, orderIdentifier);
                }

                this.lensOrderUrlManager.setPrototypeCode(side, this.sides[side].prototypeCode);
            }

            this.$router.push({ path: this.lensOrderUrlManager.getPath() });
        },


        // ---------------------------------------- Invert

        invertAndDispatch(from)
        {
            [this.sides.left, this.sides.right] = [this.sides.right, this.sides.left];
            this.lensOrderUrlManager.invert();

            this.dispatch(from);
        },


        // ------------------------------------------------------------ PROTOTYPE SET FROM LIST

        onPrototypeSet(side, prototypeCode)
        {
            this.sides[side].prototypeCode = prototypeCode;
        },


        // ------------------------------------------------------------ FITTING

        toggleFittingsPanel()
        {
            this.fittingsPanelOpen = !this.fittingsPanelOpen;
        },

        closeFittingsPanel()
        {
            this.fittingsPanelOpen = false;
        },


        // ------------------------------------------------------------ CALCULATORS

        closeOverRefractionPanel()
        {
            this.$store.dispatch('calculators/closeOverRefractionPanel');
        },

        toggleCalculatorsPanel()
        {
            this.calculatorsPanelOpen = !this.calculatorsPanelOpen;
            this.closeOverRefractionPanel();
        },

        closeCalculatorsPanel()
        {
            this.calculatorsPanelOpen = false;
            this.closeOverRefractionPanel();
        },


        // ------------------------------------------------------------ VIEW AS CUSTOMER

        toggleViewAsCustomer()
        {
            this.$store.dispatch('lensOrder/toggleViewAsCustomer');
            this.$store.dispatch('lensOrder/templateAndValidate', { options: { only_present: true, no_required: true } });
        },


        // ------------------------------------------------------------ ONLINE FITTING GUIDES (OFG)

        closeRightOfgPanel()
        {
            this.$store.dispatch('lensOrder/closeOfg', { side: SIDE_RIGHT });
        },

        closeLeftOfgPanel()
        {
            this.$store.dispatch('lensOrder/closeOfg', { side: SIDE_LEFT });
        },


        // ------------------------------------------------------------ SET UP CHAINS OF RESPONSIBILITY

        setupDispatchQueue()
        {
            dispatchQueue.set('ResetErrors',                                     new ResetErrors(this));
            dispatchQueue.set('ResetSideFittingOnConditions-right',              new ResetSideFittingOnConditions(this, SIDE_RIGHT));
            dispatchQueue.set('ResetSideFittingOnConditions-left',               new ResetSideFittingOnConditions(this, SIDE_LEFT));
            dispatchQueue.set('FetchPreviousOrderByReference-right',             new FetchPreviousOrderByReference(this, SIDE_RIGHT));     // if previous order has changed
            dispatchQueue.set('FetchPreviousOrderByReference-left',              new FetchPreviousOrderByReference(this, SIDE_LEFT));      // if previous order has changed
            dispatchQueue.set('FetchPreviousOrderByLensSn-right',                new FetchPreviousOrderByLensSn(this, SIDE_RIGHT));        // if previous order has changed
            dispatchQueue.set('FetchPreviousOrderByLensSn-left',                 new FetchPreviousOrderByLensSn(this, SIDE_LEFT));         // if previous order has changed
            dispatchQueue.set('FetchPreviousOrderByLensOemNumber-right',         new FetchPreviousOrderByLensOemNumber(this, SIDE_RIGHT)); // if previous order has changed
            dispatchQueue.set('FetchPreviousOrderByLensOemNumber-left',          new FetchPreviousOrderByLensOemNumber(this, SIDE_LEFT));  // if previous order has changed
            dispatchQueue.set('CheckForSomethingOrStop',                         new CheckForSomethingOrStop(this));                       // if stop: loading = false
            dispatchQueue.set('ValidatePreviousOrdersEntitiesConsistency',       new ValidatePreviousOrdersEntitiesConsistency(this));     // only re-order
            dispatchQueue.set('ValidatePreviousOrdersPatientsConsistency',       new ValidatePreviousOrdersPatientsConsistency(this));     // only re-order
            dispatchQueue.set('ValidateLensSidesConsistency',                    new ValidateLensSidesConsistency(this));                  // only re-order
            dispatchQueue.set('SilentlySetPrototypeCodesInUrl',                  new SilentlySetPrototypeCodesInUrl(this));
            dispatchQueue.set('CreateFittingIfNotExists-right',                  new CreateFittingIfNotExists(this, SIDE_RIGHT));
            dispatchQueue.set('CreateFittingIfNotExists-left',                   new CreateFittingIfNotExists(this, SIDE_LEFT));
            dispatchQueue.set('SetFittingPrototype-right',                       new SetFittingPrototype(this, SIDE_RIGHT));
            dispatchQueue.set('SetFittingPrototype-left',                        new SetFittingPrototype(this, SIDE_LEFT));
            dispatchQueue.set('SetFittingValuesByPreviousOrder-right',           new SetFittingValuesByPreviousOrder(this, SIDE_RIGHT));
            dispatchQueue.set('SetFittingValuesByPreviousOrder-left',            new SetFittingValuesByPreviousOrder(this, SIDE_LEFT));
            dispatchQueue.set('SetFittingValuesByBasketLine-right',              new SetFittingValuesByBasketLine(this, SIDE_RIGHT));
            dispatchQueue.set('SetFittingValuesByBasketLine-left',               new SetFittingValuesByBasketLine(this, SIDE_LEFT));
            dispatchQueue.set('SetPrototypePreviousValuesByPreviousOrder-right', new SetPrototypePreviousValuesByPreviousOrder(this, SIDE_RIGHT));
            dispatchQueue.set('SetPrototypePreviousValuesByPreviousOrder-left',  new SetPrototypePreviousValuesByPreviousOrder(this, SIDE_LEFT));
            dispatchQueue.set('TemplatePrototypeForms-right',                    new TemplatePrototypeForms(this, SIDE_RIGHT));
            dispatchQueue.set('TemplatePrototypeForms-left',                     new TemplatePrototypeForms(this, SIDE_LEFT));
            dispatchQueue.set('SetPrototypesSide',                               new SetPrototypesSide(this));
            dispatchQueue.set('CompleteGroupedParameterValues',                  new CompleteGroupedParameterValues(this));
        },

        setupAddToBasketQueue()
        {
            addToBasketQueue.set('ValidateLenses',   new ValidateLenses(this));
            addToBasketQueue.set('PriceLenses',      new PriceLenses(this));
            addToBasketQueue.set('SetLensPairId',    new SetLensPairId(this));
            addToBasketQueue.set('AddToBasket',      new AddToBasket(this));
            addToBasketQueue.set('NavigateToBasket', new NavigateToBasket(this));
        },

        setupUpdateOrdersQueue()
        {
            updateOrderQueue.set('ValidateLenses',                    new ValidateLenses(this));
            updateOrderQueue.set('PriceLenses',                       new PriceLenses(this));
            updateOrderQueue.set('UpdateOrders',                      new UpdateOrders(this));
            updateOrderQueue.set('NavigateToWelcome',                 new NavigateToWelcome(this));
            updateOrderQueue.set('RefreshWaitingForReviewOrdersList', new RefreshWaitingForReviewOrdersList(this));
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">
.sl-eyes-header--fixed
{
    padding-top: 40px; // WARNING: Must be the same as eyes-header height!
}

.sl-order__submit
{
    @apply outline-none;

    &:hover,
    &:focus
    {
        @apply bg-primary-400;
    }

    &:focus-visible
    {
        @apply ring-2 ring-primary-400 ring-offset-2;
    }

    &:active
    {
        @apply bg-primary-600;
    }
}

.sl-widgets.sl-ofg-panel
{
    @apply mt-16 pb-16 overflow-auto;

    max-height: calc(100% - theme('spacing.16'));

    // Hide the scrollbar in IE, Edge
    -ms-overflow-style: none;

    // Hide the scrollbar in Firefox
    scrollbar-width: none;

    // Hide the scrollbar on Chrome, Safari, etc.
    &::-webkit-scrollbar
    {
        @apply hidden;
    }
}
</style>
