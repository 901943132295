export default Object.freeze(
    {
        // Generic tags
        PREPACKAGED:      'PREPACKAGED',
        ACCESSORY:        'ACCESSORY',
        CLEANING_PRODUCT: 'CLEANING_PRODUCT',
        ACUACARE:         'ACUACARE',
        TRIAL_SET:        'TRIAL_SET',

        // Tags for intended use
        SOFT:                  'SOFT',
        RGP:                   'RGP',
        MULTI_PURPOSE:         'MULTI_PURPOSE',
        OXIDIZER:              'OXIDIZER',
        PRESERVATION:          'PRESERVATION',
        CLEANER:               'CLEANER',
        MULTI_PACK:            'MULTI_PACK',
        FLIGHT_PACK:           'FLIGHT_PACK',
        ARTIFICIAL_TEARS:      'ARTIFICIAL_TEARS',
        PROSTHETIC_COMPATIBLE: 'PROSTHETIC_COMPATIBLE',

        // Tags for product types (Cleaning Products)
        ALL_CLEAN:  'ALL_CLEAN',
        ALL_IN_ONE: 'ALL_IN_ONE',
        CLEANFLEX:  'CLEANFLEX',
        ONE_STEP_T: 'ONE_STEP_T',
        STOREFLEX:  'STOREFLEX',
        ENZYME:     'ENZYME',
        FRESH:      'FRESH',
        UNIFRESH:   'UNIFRESH',

        // Tags for product types (Accessories)
        APPLICATOR:          'APPLICATOR',
        SUCTION_CUP:         'SUCTION_CUP',
        OPTOMETRY_EQUIPMENT: 'OPTOMETRY_EQUIPMENT',
        TWEEZERS:            'TWEEZERS',
        OTHER_ACCESSORY:     'OTHER_ACCESSORY',
        OPHTHALMIC_STRIP:    'OPHTHALMIC_STRIP',
        ACCOMPANYING_KIT:    'ACCOMPANYING_KIT',
        COLOR_CHART:         'COLOR_CHART',
    }
);
