import {
    get,
} from 'lodash-es';
import moment from 'moment';

export default class SetReturnQuestionnaireValues
{
    /**
     * Vue Object
     */
    v = null;

    /**
     * {string} "left" or "right"
     */
    side =  null;

    constructor(v, side)
    {
        this.v = v;
        this.side = side;
    }

    async handle()
    {
        const cReturn = this.v.$store.getters['lensReturn/getReturn'](this.side);

        if(cReturn)
        {
            const order = cReturn.getOrder();
            const shippingStatus = order.status.find(status => status.code == 'SHIPPED');

            cReturn.getReturnQuestionnaire().setValue('RETURN_LENS_SERIAL_NUMBER', order.serial_number);
            cReturn.getReturnQuestionnaire().setValue('RETURN_LENS_ARTICLE', order.article.label);
            cReturn.getReturnQuestionnaire().setValue('RETURN_LENS_PATIENT_REFERENCE1', get(order, 'article_data.PATIENT_REFERENCE1'));
            cReturn.getReturnQuestionnaire().setValue('RETURN_LENS_SHIPPING_DATE', moment(shippingStatus?.at).format('DD.MM.YYYY') ?? '-');
        }

        return true;
    }
}
