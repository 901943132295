export function decimal(val, nbDecimals, options)
{
    return Number.parseFloat(val).toFixed(nbDecimals);
};

export function round(val, nbDecimals, options)
{
    // Handle default value
    if(typeof nbDecimals === 'object' && typeof options === 'undefined')
    {
        options = nbDecimals;
        nbDecimals = 0;
    }

    const floatVal = parseFloat(val);
    const base = Math.pow(10, nbDecimals);

    return Math.round(floatVal * base) / base;
};

export function thousand(val, options)
{
    return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1'");
};

export function italic(val, options)
{
    return '<span class="italic">' + val + '</span>';
};

export function prefix(val, prefix, options)
{
    return val.toString().includes(prefix) ? val : prefix + val;
};

export function suffix(val, suffix, options)
{
    return val.toString().includes(suffix) ? val : val + suffix;
};

export function sign(val, options)
{
    return val > 0 ? `+${val}` : `${val}`;
};

export function date(val, options)
{
    return val;
};

export function translate(val, options)
{
    // Determine translation key depending on context.
    const key = (options.context === 'value'                // For example:
        ? `parameters.${options.pcode}.values.${val}.label` // If context is "value" => "parameters.LENS_PUPIL_STYLE.values.CLEAR.label"
        : `parameters.${val}.label`);                       // else                  => "parameters.LENS_PUPIL_STYLE.label"

    if(options.i18n.global.te(key))
    {
        return options.i18n.global.t(key);
    }

    return val;
};

export function vTranslation(val, options)
{
    if(!options.i18n.global.te(`parameters.${options.pcode}.info`))
    {
        return val;
    }

    // Fix: ensure `null` is rendered as an empty string
    for(const key in val)
    {
        if(val[key] === null)
        {
            val[key] = '';
        }
    }

    // Replace :params in the translated message before returning it
    let message = options.i18n.global.t(`parameters.${options.pcode}.info`, val);
    const params = message.match(/:\w[\w\d\-]*\b/g, '');
    for(const param of params)
    {
        message = message.replace(param, val[param.slice(1)]);
    }

    return message;
};

export function yesno(val, options)
{
    return val
        ? options.i18n.global.t('common.yes')
        : options.i18n.global.t('common.no');
};

export function address(address, options)
{
    let html = '<span class="sl-address">';

    html += address.recipient_name;

    if(address.recipient_name_affix)
    {
        html += '<br>' + address.recipient_name_affix;
    }

    if(address.fao) // For the attention of
    {
        html += '<br>' + options.i18n.global.t('common.address.fao') + address.fao;
    }

    html += '<br>' + address.line1;

    if(address.line2)
    {
        html += '<br>' + address.line2;
    }

    if(address.line3)
    {
        html += '<br>' + address.line3;
    }

    if(address.line4)
    {
        html += '<br>' + address.line4;
    }

    if(address.po_box)
    {
        html += '<br>' + options.i18n.global.t('common.address.po_box') + ' ' + address.po_box;
    }

    html += '<br>' + address.postcode + " " + address.city;

    if(address.county)
    {
        html += ' (' + address.county + ')';
    }

    html += '</span>';

    return html;
};

export function multiply(val, multiplier)
{
    return val * multiplier;
};

export default
{
    decimal,
    round,
    thousand,
    italic,
    prefix,
    suffix,
    sign,
    translate,
    vTranslation,
    yesno,
    address,
    multiply,
    date,
};
