<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="sl-form-dual">
        <transition-group name="list" tag="div">
            <form-section
                v-for="(formSection, index) in formSections"
                :key="index"
                :form-section="formSection"
                @update="update"
            />
        </transition-group>
    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import FormSection from '@/components/forms/form-dual/form-sections/FormSection';

export default
{
    name: 'FormDual',

    components:
    {
        FormSection,
    },

    props:
    {
        formDual:
        {
            type:     Object,
            required: true,
        },
    },

    emits: ['update'],

    computed:
    {
        formSections()
        {
            console.log('FORM HAS CHANGED !!', this.formDual.formSections);
            return this.formDual.formSections;
        },
    },

    methods:
    {
        update(paramCode, paramValue, side)
        {
            console.log('FormDual', 'update', paramCode, paramValue, side);
            this.$emit('update', paramCode, paramValue, side);
        },
    },
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">

// Transitions
.list-enter-active, .list-leave-active
{
    transition: all 0.5s ease;
}

.list-enter-to, .list-leave-from
{
    @apply max-h-[1000px] opacity-100;
}

.list-enter-from, .list-leave-to
{
    @apply max-h-0 opacity-0;
}

</style>
