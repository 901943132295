/**
 * @typedef {import('@/composables/GraphComposable.js').Surface}  Surface
 */

import { radiusDeltaSagEx, daSag } from '@/utils/Formulas';
import ColoringStrategy2D from './ColoringStrategy2D';
import useGraphComposable from '@/composables/GraphComposable.js';
import constants from '@/constants/constants';
import Meridian from '@/models/Meridian';

const DEFAULT_COLOR_SCHEME = 'OCULUS';

const colors = constants.colorScales.BEST_SPHERE;

/**
 * Color the squares using their height difference from the best sphere.
 */
export default class BestSphereColoringStrategy2D extends ColoringStrategy2D
{
    /** @type {number} The radius of the best sphere. */
    bsRad = 0;

    /**
     * @param {number} bsRad
     */
    constructor(bsRad)
    {
        super();
        this.bsRad = bsRad;
    }

    /**
     * Get the color for the specified point.
     *
     * @param {Meridian} meridian The meridian being evalutated.
     * @param {number}   dia      The diameter being evaluated.
     * @param {string}   scheme   The color scheme to use.
     * @returns {string} The evaluated color code.
     */
    getColor(meridian, dia, scheme = DEFAULT_COLOR_SCHEME)
    {
        const kSag = meridian.sag(dia);
        const bsSag = daSag(this.bsRad, 0, 0, dia) - kSag;
        const val = Math.round(1000 * -bsSag);

        return colors[scheme].findLast(({ value }) => val > value)?.color
            ?? '#fff';
    }
}
