<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="sl-form-dual__line-side" :class="`sl-form-dual__line-side-${side}`">

        <!-- Helper (grey vertical band) -->
        <div class="sl-form-dual__line-band-wrapper">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="sl-form-dual__line-band-content" v-html="currentField.getHelper()"></div>
        </div>

        <!-- Error Wrapper -->
        <div class="sl-form-dual__line-content-wrapper" :class="{ 'sl-form-dual__line-content-wrapper--error': currentField.getError() }">

            <!-- Field Wrapper -->
            <div
                class="sl-form-dual__field-wrapper lsn-form__radio-item-wrapper"
                :tabindex="tabIndex"
                @keydown.up.prevent="selectPrevious()"
                @keydown.left.prevent="selectPrevious()"
                @keydown.right.prevent="selectNext()"
                @keydown.down.prevent="selectNext()"
            >
                <!-- Field -->
                <div
                    v-for="item in items"
                    :key="item.ovalue"
                    class="lsn-form__radio-item"
                    :class="{ 'lsn-form__radio-item--selected': item.ovalue == currentField.getValue(), 'lsn-form__radio-item--disabled': !item.is_enabled }"
                    @click="setValue(item)"
                >
                    <!-- Item label -->
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <div v-html="item.label"></div>

                    <!-- Item help -->
                    <div
                        v-if="item.help"
                        v-tippy="{ animation : 'fade' }"
                        class="lsn-form__radio-item-help"
                        :content="item.help"
                    >
                        <mdi-icon icon="mdiInformationOutline" />
                    </div>

                </div>
            </div>

            <!-- Supplement -->
            <div v-if="supplement" class="sl-form-dual__supplement-wrapper">
                <div class="sl-form-dual__supplement">
                    {{ supplement }}
                </div>
            </div>

            <!-- Note -->
            <div v-if="currentField.getNote()" class="sl-form-dual__note-wrapper">
                <div class="sl-form-dual__note">
                    {{ currentField.getNote() }}
                </div>
            </div>

            <!-- Error -->
            <div v-if="currentField.getError()" class="sl-form-dual__error-wrapper">
                <div class="sl-form-dual__error">
                    {{ currentField.getError() }}
                </div>
            </div>
        </div>

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

//import FormFieldAbstract from '@/components/forms/form-dual/form-sections/form-lines/FormFieldAbstract';

export default
{
    name: 'FormFieldRadioButton',

    //extends: FormFieldAbstract,

    props:
    {
        index:
        {
            type:     Number,
            required: true,
        },

        side:
        {
            type:     String,
            required: true,
        },

        field:
        {
            type:     Object,
            required: true,
        },
    },

    emits: ['update'],

    data()
    {
        return {
            isTouched:    false,
            currentField: this.field,
        };
    },

    computed:
    {
        tabIndex()
        {
            return this.side === 'left' ? 2 : 1;
        },

        items()
        {
            return this.currentField.getItems();
        },

        cIndex()
        {
            return this.items.findIndex(i => i.ovalue == this.currentField.getValue());
        },

        supplement()
        {
            const pricedArticleCode = this.currentField.getPricedArticleCode();

            if(pricedArticleCode)
            {
                const article = this.$store.getters['catalog/getByCode'](pricedArticleCode);

                if(article)
                {
                    return `+ ${this.max2decimals(article.price)} ${article.currency_code}`;
                }
            }

            return null;
        },
    },

    watch:
    {
        field(field)
        {
            this.currentField = field;
        },
    },

    methods:
    {
        setValue(item)
        {
            if(!item.is_enabled)
            {
                return;
            }

            this.isTouched = true;

            this.currentField.setValue(item.ovalue);

            this.$emit('update', this.currentField.getCode(), this.currentField.getValue(), this.side);
        },

        selectPrevious()
        {
            let prevIndex = this.cIndex;

            for(let i = (this.cIndex === -1 ? this.items.length : this.cIndex) - 1; i >= 0; i--)
            {
                if(this.items[i].is_enabled)
                {
                    prevIndex = i;
                    break;
                }
            }

            if(prevIndex !== this.cIndex)
            {
                this.setValue(this.items[prevIndex]);
            }
        },

        selectNext()
        {
            let nextIndex = this.cIndex;

            for(let i = this.cIndex + 1; i < this.items.length; i++)
            {
                if(this.items[i].is_enabled)
                {
                    nextIndex = i;
                    break;
                }
            }

            if(nextIndex !== this.cIndex)
            {
                this.setValue(this.items[nextIndex]);
            }
        },

        max2decimals(price)
        {
            return parseFloat(price).toFixed(2).replace(/\.0{0,2}$/, '');
        },
    },
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">

.sl-form-dual__line-side
{
    &.sl-form-dual__line-side-right .lsn-form__radio-item-wrapper
    {
        @apply -mr-px;
    }

    &.sl-form-dual__line-side-left .lsn-form__radio-item-wrapper
    {
        @apply -ml-px;
    }
}

</style>
