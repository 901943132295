const SURFACE_NAMES = ['cornea', 'back'];
const MIN_RESOLUTION = 0.001; // 1 µm

export default class GraphRequest
{
    /** @type {number} The ID of the fitting, or zero if N/A. */
    fittingId = 0;

    /** @type {string} */
    prototypeCode = '';

    /** @type {"cornea"|"back"} */
    surface = '';

    /** @type {Object.<string, any>} The form values. */
    values = {};

    /** @type {{resolution?: number, y_offset?: number}} */
    options = {};

    /** @type {function({id: number, data: any}): void} The callback that will handle  */
    handler = ({ id, data } = {}) => void 0;

    /**
     * @param {Object}               arg0
     * @param {number}               [arg0.fittingId]
     * @param {string}               arg0.prototypeCode
     * @param {"cornea"|"back"}      arg0.surface
     * @param {Object.<string, any>} arg0.values
     * @param {Object}               [arg0.options]
     * @param {number}               [arg0.options.resolution]
     * @param {number}               [arg0.options.y_offset]
     * @param {function({id: number, data: any}): void} [arg0.handler]
     */
    constructor({
        fittingId,
        prototypeCode,
        surface,
        values,
        options,
        handler,
    })
    {
        this.fittingId = parseInt(Math.max(0, fittingId || 0));

        if(typeof prototypeCode !== 'string' || prototypeCode.length === 0)
        {
            throw new TypeError('Missing prototypeCode');
        }
        this.prototypeCode = prototypeCode;

        if(!SURFACE_NAMES.includes(surface))
        {
            throw new TypeError(`Unknown surface: ${surface}`);
        }
        this.surface = surface;

        if(typeof values !== 'object')
        {
            throw new TypeError('Missing values');
        }
        this.values = values;

        if(typeof options?.resolution === 'number')
        {
            this.options.resolution = Math.max(
                MIN_RESOLUTION,
                Math.abs(Math.round(1000 * options.resolution) / 1000)
            );
        }

        if(typeof options?.y_offset === 'number')
        {
            this.options.y_offset = options.y_offset;
        }

        if(typeof handler === 'function')
        {
            this.handler = handler;
        }
    }

    /**
     * Pass the response data to the handler.
     *
     * @param {number} id   The fitting ID associated with the request.
     * @param {*}      data The response data.
     * @returns {void}
     */
    handleResponse(id, data)
    {
        this.handler({ id, data });
    }
}
