/**
 * @typedef {{x: number, y: number}} Point
 */

import AbstractSurfaceFilter from './AbstractSurfaceFilter';
import Objects from '@/utils/Objects';

export default class OffsetFilter extends AbstractSurfaceFilter
{
    offset = 0;

    constructor(options = {})
    {
        super(options);
        this.offset = options.offset;
    }

    /**
     * Run the filter.
     *
     * @param {Point[]} points
     * @returns {Point[]}
     */
    run(points)
    {
        return points.map(
            ({ x, y }) => ({ x, y: y + this.offset })
        );
    }
}
