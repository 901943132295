import axios from '@/axios';
import Order from '@/models/Order';
import Return from '@/models/Return';
import LensReturnUrlManager from '../LensReturnUrlManager';

export default class FetchOrderByLensSn
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        /** @type {LensReturnUrlManager} */
        const lensReturnUrlManager = this.v.lensReturnUrlManager;

        // Skip unless there's a lens serial number in the URL
        if(!lensReturnUrlManager.hasLensSn())
        {
            console.log(`No lens SN in URL`);
            return true;
        }

        const urlLensSn = lensReturnUrlManager.getLensSn();
        console.log(`There is a lens SN in URL: ${urlLensSn}`);

        return axios.get(`/api/order/lens-sn/${urlLensSn}`)
            .then(response =>
            {
                const order = new Order(response.data);

                if(order.has_been_returned)
                {
                    this.v.orderIdentifierFormError = this.v.$t('return.form.error.already_returned');

                    return false;
                }

                this.v.leftReturn = new Return(order);

                console.log(`Main return has been set`);

                return true;
            })
            .catch(err =>
            {
                this.v.leftReturn = null;
                this.v.orderIdentifierFormError = this.v.$t('return.form.error.not_found');

                return false;
            });
    }
}
