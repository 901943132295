
import FormFieldCustom from '../form-fields/FormFieldCustom';
import FormFieldDate from '../form-fields/FormFieldDate';
import FormFieldInfo from '../form-fields/FormFieldInfo';
import FormFieldLongText from '../form-fields/FormFieldLongText';
import FormFieldNumber from '../form-fields/FormFieldNumber';
import FormFieldRadioButton from '../form-fields/FormFieldRadioButton';
import FormFieldSelector from '../form-fields/FormFieldSelector';
import FormFieldText from '../form-fields/FormFieldText';

export default class FormLine
{
    getFormField(parameter)
    {
        if(!parameter)
        {
            return null;
        }

        switch(parameter.form_display)
        {
            case 'NUMBER_INPUT':
                return new FormFieldNumber(parameter);

            case 'TEXT_INPUT':
                return new FormFieldText(parameter);

            case 'DATE_INPUT':
                return new FormFieldDate(parameter);

            case 'LONG_TEXT_INPUT':
                return new FormFieldLongText(parameter);

            case 'SELECTOR':
                return new FormFieldSelector(parameter);

                // case 'OPEN_SELECTOR':
                //     return new OpenSelectorFormField(parameter);

            case 'RADIO_BUTTON':
                return new FormFieldRadioButton(parameter);

                // case 'CHECKBOX':
                //     return new CheckboxFormField(parameter);

            case 'INFO':
                return new FormFieldInfo(parameter);

            case 'CUSTOM':
                return new FormFieldCustom(parameter);

            default:
                console.log('PARAMETER', parameter);
                throw new Error(`Lens parameter splitted form display "${parameter.form_display}" does not exist for parameter with code "${parameter.code}" on the "${this.side}" side.`);
        }
    }
}
