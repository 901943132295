<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <transition-group name="list" tag="div">
        <component
            :is="componentFactory(parameter)"
            :key="parameter.code"
            v-model="pValue"
            side="both"
            :index="index"
            :name="name"
            :parameter="parameter"
            :error="error"
            :options="parameter.list_items"
        >
            <template #label>
                <div v-if="parameter.form_label != 'NO_LABEL'" class="sl-order-form__label-wrapper">
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <label class="sl-order-form__label text-center block" v-html="parameter.form_label"></label>

                    <span v-if="parameter.unit" class="sl-order-form__unit">
                        [&nbsp;{{ parameter.unit }}&nbsp;]
                    </span>
                </div>
            </template>

            <template v-if="parameter.is_grouped != 'F'" #splitter>
                <div class="sl-splitter-btn" @click="ungroup(parameter.code)">
                    <mdi-icon icon="mdiArrowSplitVertical" />
                </div>
            </template>
        </component>
    </transition-group>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { upperFirst, camelCase } from 'lodash-es';
import GroupedCheckboxField      from '@/views/order/lenses/fields/GroupedCheckboxField.vue';
import GroupedInputTextField     from '@/views/order/lenses/fields/GroupedInputTextField.vue';
import GroupedInfoField          from '@/views/order/lenses/fields/GroupedInfoField.vue';
import GroupedSelectorField      from '@/views/order/lenses/fields/GroupedSelectorField.vue';
import GroupedOpenSelectorField  from '@/views/order/lenses/fields/GroupedOpenSelectorField.vue';
import GroupedRadioButtonField   from '@/views/order/lenses/fields/GroupedRadioButtonField.vue';
import GroupedPatientReference1  from '@/views/order/lenses/fields/custom/GroupedPatientReference1.vue';

// Used for custom parameter form display.
const custom = {
    PatientReference1: GroupedPatientReference1,
};

export default
{
    name: 'FormLineGrouped',

    props:
    {
        index:
        {
            type:     Number,
            required: true,
        },

        code:
        {
            type:     String,
            required: true,
        },

        line:
        {
            type:     Object,
            required: true,
        },
    },

    emits: ['ungroup'],

    computed:
    {
        parameter()
        {
            return this.line.leftParameter;
        },

        error()
        {
            const prototype = this.$store.getters['lensOrder/getPrototype']('left');

            return prototype.getErrorByParameterCode(this.code);
        },

        name()
        {
            return "grouped_" + this.code;
        },

        pValue:
        {
            get()
            {
                return this.parameter.getValue();
            },

            set(value)
            {
                // Do not template if value has not changed.
                if(value !== null && value === this.parameter.getValue())
                {
                    console.log('NO VALUE CHANGE', this.parameter.code, value);
                    return;
                }

                console.log('GROUPED VALUE SET', this.parameter.code, value);

                this.$store.commit('lensOrder/setPrototypeValue', {
                    paramCode:  this.parameter.code,
                    paramValue: value,
                });

                this.$store.dispatch('lensOrder/templateAndValidate', { options: { only_present: true, no_required: true } });
            },
        },
    },

    methods:
    {
        componentFactory(parameter)
        {
            const display = parameter.form_display;
            switch(display)
            {
                case 'TEXT_INPUT':
                    return GroupedInputTextField;

                case 'SELECTOR':
                    return GroupedSelectorField;

                case 'OPEN_SELECTOR':
                    return GroupedOpenSelectorField;

                case 'RADIO_BUTTON':
                    return GroupedRadioButtonField;

                case 'CHECKBOX':
                    return GroupedCheckboxField;

                case 'CUSTOM':
                    const field = upperFirst(camelCase(parameter.code));

                    return custom[field];

                case 'INFO':
                    return GroupedInfoField;

                default:
                    throw new Error(`Lens parameter grouped form display "${display}" does not exist for parameter with code "${this.code}".`);
            }
        },

        ungroup()
        {
            this.$emit('ungroup', this.code);
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.list-enter-active, .list-leave-active
{
    transition: all 0.5s ease;
}

.list-enter-to, .list-leave-from
{
    @apply max-h-[1000px] opacity-100;
}

.list-enter-from, .list-leave-to
{
    @apply max-h-0 opacity-0;
}
</style>
