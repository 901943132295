<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="sl-form-dual__line-side" :class="`sl-form-dual__line-side-${side}`">

        <!-- Helper (grey vertical band) -->
        <div class="sl-form-dual__line-band-wrapper">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="sl-form-dual__line-band-content" v-html="currentField.getHelper()"></div>
        </div>

        <!-- Error Wrapper -->
        <div class="sl-form-dual__line-content-wrapper" :class="{ 'sl-form-dual__line-content-wrapper--error': currentField.getError() }">

            <!-- Field Wrapper -->
            <div class="sl-form-dual__field-wrapper">
                <!-- Field -->
                <select
                    v-model="tempValue"
                    class="sl-form-dual__select"
                    :name="name"
                    :tabindex="tabIndex"
                    @focus="onFocus"
                    @blur="onBlur"
                >
                    <option
                        v-for="item in items"
                        :key="item.ovalue"
                        :value="item.ovalue"
                        :disabled="!item.is_enabled"
                    >
                        {{ item.label }}

                        <template v-if="item.help">
                            ({{ item.help }})
                        </template>
                    </option>
                </select>
            </div>

            <!-- Supplement -->
            <div v-if="supplement" class="sl-form-dual__supplement-wrapper">
                <div class="sl-form-dual__supplement">
                    {{ supplement }}
                </div>
            </div>

            <!-- Note -->
            <div v-if="currentField.getNote()" class="sl-form-dual__note-wrapper">
                <div class="sl-form-dual__note">
                    {{ currentField.getNote() }}
                </div>
            </div>

            <!-- Error -->
            <div v-if="currentField.getError()" class="sl-form-dual__error-wrapper">
                <div class="sl-form-dual__error">
                    {{ currentField.getError() }}
                </div>
            </div>
        </div>

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

//import FormFieldAbstract from '@/components/forms/form-dual/form-sections/form-lines/FormFieldAbstract';

export default
{
    name: 'FormFieldSelector',

    //extends: FormFieldAbstract,

    props:
    {
        index:
        {
            type:     Number,
            required: true,
        },

        side:
        {
            type:     String,
            required: true,
        },

        field:
        {
            type:     Object,
            required: true,
        },
    },

    emits: ['update'],

    data()
    {
        return {
            isTouched:    false,
            currentField: this.field,
        };
    },

    computed:
    {
        tempValue:
        {
            get()
            {
                return this.currentValue;
            },

            set(value)
            {
                this.setValue(value);
            },
        },

        tabIndex()
        {
            return this.side === 'left' ? 2 : 1;
        },

        items()
        {
            return this.currentField.getItems();
        },

        cIndex()
        {
            return this.items.findIndex(i => i.ovalue == this.currentField.getValue());
        },

        currentValue()
        {
            return this.currentField.getValue();
        },

        supplement()
        {
            // if(this.parameter.priced_article_code)
            // {
            //     const article = this.$store.getters['catalog/getByCode'](this.parameter.priced_article_code);
            //     if(article)
            //     {
            //         return `+ ${this.max2decimals(article.price)} ${article.currency_code}`;
            //     }
            // }

            return null;
        },

        name()
        {
            return this.side + '-' + this.currentField.getCode();
        },
    },

    watch:
    {
        field(field)
        {
            this.currentField = field;
        },
    },

    methods:
    {
        setValue(value)
        {
            this.isTouched = true;

            this.currentField.setValue(value);

            this.$emit('update', this.currentField.getCode(), this.currentValue, this.side);
        },
    },
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">

.sl-form-dual__select:focus
{
    @apply outline-none border-primary-400;
}

</style>
