<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <lsn-table-cell class="sl-return-cell__date">
        {{ rdate }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-return-cell__reference">
        {{ oReturn.reference }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-return-cell__sn">
        {{ oReturn.order.serial_number }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-return-cell__article">
        {{ oReturn.order.article.label }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-return-cell__patient-reference">
        {{ patient }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-return-cell__return-reason">
        {{ oReturn.return_reason_label }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-return-cell__credit">
        {{ formattedCredit }}
    </lsn-table-cell>
</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import moment from 'moment';
import LsnTableCell from '@/components/LsnTable/LsnTableCell.vue';

export default
{
    name: 'ReturnLineSummary',

    components:
    {
        LsnTableCell,
    },

    props:
    {
        oReturn:
        {
            type:     Object,
            required: true,
        },
    },

    computed:
    {
        rdate()
        {
            return moment(this.oReturn.rdate).format('DD.MM.YYYY');
        },

        patient()
        {
            if(this.oReturn.order.article_data.PATIENT_REFERENCE1)
            {
                return this.oReturn.order.article_data.PATIENT_REFERENCE1;
            }
            else if(this.oReturn.order.article_data.PATIENT_ID)
            {
                let patient = this.$store.getters['patients/getById'](this.oReturn.order.article_data.PATIENT_ID);

                return patient.reference1 ? patient.reference1 : '-';
            }
            else
            {
                return '-';
            }
        },

        formattedCredit()
        {
            let price = (this.oReturn.special_price !== null) ? this.oReturn.special_price : this.oReturn.price;

            return this.$f(price, 'decimal:2') + " " + this.oReturn.currency_code;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

</style>
