export default
{
    countLeaves(obj)
    {
        let count = 0;

        function recurse(value)
        {
            if(typeof value !== 'object' || value === null)
            {
                count++;
            }
            else
            {
                for(const key in value)
                {
                    if(value.hasOwnProperty(key))
                    {
                        recurse(value[key]);
                    }
                }
            }
        }

        recurse(obj);
        return count;
    },

    /**
     * Make a shallow copy of `model` with its existing values overwritten by
     * those in `values`.
     * If a key doesn't exist in `model`, it won't exist in the output.
     *
     * @param {Object} model
     * @param {Object} values
     * @returns {Object}
     */
    fromModel(model, values)
    {
        return Object.keys(model).reduce(
            (acc, key) => ({
                ...acc,
                [key]: values[key] ?? model[key],
            }),
            {}
        );
    },

    /**
     * Like `Array.prototype.filter()`, but iterating over an object.
     *
     * @param {Object} obj
     * @param {{ (val: *, key: string, obj: Object): boolean }} filterFunc
     * @returns {Object}
     */
    filter(obj, filterFunc)
    {
        return Object.keys(obj).reduce(
            (acc, key) =>
            {
                const val = obj[key];
                if(filterFunc(val, key, obj))
                {
                    return {
                        ...acc,
                        [key]: val,
                    };
                }

                return { ...acc };
            },
            {}
        );
    },
};
