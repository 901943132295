export default class NavigateToReturnConfirmation
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        this.v.$router.push({
            name: 'return-confirmation',
            params:
            {
                lang:      this.v.$route.params.lang,
                entity_id: this.v.$route.params.entity_id,
            },
        });
    }
}
