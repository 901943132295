<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-main">
        <div class="sl-page sl-page--full">
            <!-- ------------------------------------------------------------ Accessories -->

            <div id="sl-accessories">
                <!-- Page Header -->
                <div class="sl-page__header">
                    <div class="sl-page__title">
                        {{ $t('accessories.page_title') }}
                    </div>
                </div>

                <!-- Page Content -->
                <div class="sl-page__content text-gray-500">
                    <div class="flex">
                        <div class="w-1/6 shrink-0 mr-2">
                            <div class="mb-2 text-primary-500 text-lg">
                                {{ $t('accessories.filter_by_product_type') }}
                            </div>

                            <!-- Filters -->
                            <div class="-ml-4">
                                <a
                                    v-for="tag in productTypeFilters"
                                    :key="tag"
                                    href="#!"
                                    class="sl-article-grid-product-filter"
                                    :class="{ 'sl-article-grid-product-filter--active': productTypeFilter === tag }"
                                    @click.prevent.stop="setProductTypeFilter(tag)"
                                >
                                    {{ $store.getters['catalog/getTagLabelByCode'](tag) }}
                                </a>

                                <a
                                    v-if="hasArticlesWithTag($const.tags.TRIAL_SET)"
                                    href="#trial-sets"
                                    class="sl-article-grid-product-filter"
                                >
                                    {{ $store.getters['catalog/getTagLabelByCode']($const.tags.TRIAL_SET) }}
                                </a>
                            </div>

                            <!-- Discount Rules Explanation -->
                            <div class="mt-4 mb-2 text-primary-500 text-lg">
                                {{ $t('article_list.discounts.title') }}
                            </div>

                            <p class="mr-6 text-sm">
                                {{ $t('article_list.discounts.intro') }}
                            </p>

                            <div
                                tabindex="0"
                                class="cursor-pointer flex items-center mt-2 text-sm transition select-none"
                                :class="{ 'text-primary-500': showOnlyPrepackaged }"
                                @click="showOnlyPrepackaged = !showOnlyPrepackaged"
                            >
                                <mdi-icon
                                    :icon="showOnlyPrepackaged ? 'mdiCheck' : 'mdiCheckboxBlankOutline'"
                                    class="w-5 h-5 mr-1"
                                />

                                <span>
                                    {{ $t('article_list.discounts.show_boxes_only') }}
                                </span>
                            </div>

                            <div class="mt-2 mr-6 text-sm">
                                <div class="flex flex-wrap items-center border-b border-primary-100 w-full">
                                    <div class="pr-2 py-1 w-2/3 text-primary-500">
                                        {{ $t('article_list.discounts.table.condition') }}
                                    </div>

                                    <div class="pl-2 py-1 w-1/3 text-primary-500 text-right">
                                        {{ $t('article_list.discounts.table.discount') }}
                                    </div>
                                </div>

                                <div
                                    v-for="rule in discountRules"
                                    :key="rule.i18nNum"
                                    class="flex flex-wrap items-center border-b border-primary-100 w-full"
                                >
                                    <div class="pr-2 py-1 w-2/3">
                                        {{ $t('article_list.discounts.condition', { num: rule.num }, rule.i18nNum) }}
                                    </div>

                                    <div class="pl-2 py-1 w-1/3 text-right" :class="{ 'text-gray-400': rule.discount === $t('article_list.discounts.no_discount') }">
                                        {{ rule.discount }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <articles-grid
                            :articles="accessories"
                            :badges-callback="getBadgesCallback()"
                        />
                    </div>
                </div>
            </div>


            <!-- ------------------------------------------------------------ Trial Sets -->

            <div v-if="hasArticlesWithTag($const.tags.TRIAL_SET)" id="trial-sets" class="sl-trial-sets">
                <!-- Page Header -->
                <div class="sl-page__header">
                    <div class="sl-page__title">
                        {{ $t('trial_sets.page_title') }}
                    </div>
                </div>

                <!-- Page Content -->
                <div class="sl-page__content text-gray-500">
                    <div class="flex mb-4">
                        <articles-grid :articles="trialSets" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { getBadgesCallback } from '@/shared/ArticlesGridSharedObjects';
import constants             from '@/constants/constants';
import ArticlesGrid          from '@/components/articles-grid/ArticlesGrid.vue';

export default
{
    name: 'AccessoriesList',

    components:
    {
        ArticlesGrid,
    },

    data()
    {
        return {
            productTypeFilter:   null,
            showOnlyPrepackaged: false,
        };
    },

    computed:
    {
        accessories()
        {
            let accessories = this.$store.state.catalog.articles
                .filter(a => a.status !== constants.articles.status.INACTIVE && a.tags.some(t => t.code === constants.tags.ACCESSORY))
                .sort((a, b) => (a.extra?.sort_order || 0) - (b.extra?.sort_order || 0));

            if(this.productTypeFilter)
            {
                accessories = accessories.filter(a => a.tags.some(t => t.code === this.productTypeFilter));
            }

            if(this.showOnlyPrepackaged)
            {
                accessories = accessories.filter(a => a.tags.some(t => t.code === constants.tags.PREPACKAGED));
            }

            return accessories;
        },

        trialSets()
        {
            return this.$store.state.catalog.articles
                .filter(a => a.tags.some(t => t.code === constants.tags.TRIAL_SET))
                .sort((a, b) => (a.extra?.sort_order || 0) - (b.extra?.sort_order || 0));
        },

        productTypeFilters()
        {
            const productTypeFilters = [
                constants.tags.APPLICATOR,
                constants.tags.SUCTION_CUP,
                constants.tags.OPTOMETRY_EQUIPMENT,
                constants.tags.TWEEZERS,
                constants.tags.OTHER_ACCESSORY,
                constants.tags.OPHTHALMIC_STRIP,
                constants.tags.ACCOMPANYING_KIT,
                constants.tags.COLOR_CHART,
            ];

            return productTypeFilters.filter(t => this.hasArticlesWithTag(t));
        },

        discountRules()
        {
            return [
                {
                    i18nNum:  2,
                    num:      '1-2',
                    discount: this.$t('article_list.discounts.no_discount'),
                },
                {
                    i18nNum:  3,
                    num:      '3',
                    discount: '4%',
                },
                {
                    i18nNum:  4,
                    num:      '4',
                    discount: '8%',
                },
                {
                    i18nNum:  5,
                    num:      '5',
                    discount: '12%',
                },
                {
                    i18nNum:  6,
                    num:      '6',
                    discount: '16%',
                },
                {
                    i18nNum:  7,
                    num:      '≥ 7',
                    discount: '20%',
                },
            ];
        },
    },

    methods:
    {
        setProductTypeFilter(tag)
        {
            this.productTypeFilter = (this.productTypeFilter === tag ? null : tag);
        },

        hasArticlesWithTag(tag)
        {
            return this.$store.state.catalog.articles
                .filter(a => a.status !== constants.articles.status.INACTIVE)
                .some(a => a.tags.some(t => t.code === tag));
        },

        getBadgesCallback,
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
#trial-sets
{
    @apply mt-8;
}
</style>
