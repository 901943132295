export default class RenderFormDual
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        let formDual = this.v.$store.state.lensReturn.formDual;
        formDual.render();

        return true;
    }
}
