/**
 * @typedef {{x: number, y: number}} Point
 */

import { daSag, deg2rad } from '@/utils/Formulas';
import AbstractSurfaceFilter from './AbstractSurfaceFilter';
import Meridian from '@/models/Meridian';

export default class DilationFilter extends AbstractSurfaceFilter
{
    coeff = 1;

    /** @type {Meridian} */
    kMeridianPos = null;

    /** @type {Meridian} */
    kMeridianNeg = null;

    limitPos = 0;
    limitNeg = -0;

    constructor(options = {})
    {
        super(options);
        this.coeff = options.coeff;
        this.kMeridianPos = options.kMeridianPos;
        this.kMeridianNeg = options.kMeridianNeg;
        this.limitPos = Math.abs(options.limitPos);
        this.limitNeg = -Math.abs(options.limitNeg);
    }

    /**
     * Run the filter.
     *
     * @param {Point[]} points
     * @returns {Point[]}
     */
    run(points)
    {
        // Calculate limitOffset on positive meridian
        const limitClSagPos = points.findLast(p => p.x <= this.limitPos).y;
        const limitKSagPos = this.kMeridianPos.sag(this.limitPos) * -1;
        const limitOffsetPos = (limitClSagPos - limitKSagPos) * this.coeff + limitKSagPos - limitClSagPos;

        // Calculate limitOffset on negative meridian
        const limitClSagNeg = points.find(p => p.x >= this.limitNeg).y;
        const limitKSagNeg = this.kMeridianNeg.sag(Math.abs(this.limitNeg)) * -1;
        const limitOffsetNeg = (limitClSagNeg - limitKSagNeg) * this.coeff + limitKSagNeg - limitClSagNeg;

        return points.map(({ x, y }) =>
        {
            if(x > this.limitPos)
            {
                return {
                    x,
                    y: y + limitOffsetPos,
                };
            }
            else if(x < this.limitNeg)
            {
                return {
                    x,
                    y: y + limitOffsetNeg,
                };
            }

            const kSag = (x >= 0
                ? this.kMeridianPos.sag(x)
                : this.kMeridianNeg.sag(-x)) * -1;

            return {
                x,
                y: (y - kSag) * this.coeff + kSag,
            };
        });
    }
}
