<template>
    <div class="relative">
        <!-- 2D Graph -->
        <div
            class="cornea2d"
            v-bind="$attrs"
            :style="{
                width: `${elementWidth}px`,
                height: `${elementHeight}px`,
            }"
        >
            <canvas
                ref="graphArea"
                class="cursor-crosshair w-full h-full"
                :width="canvasWidth"
                :height="canvasHeight"
                @mousemove="onMouseMove"
            ></canvas>
        </div>

        <!-- Pointer Data -->
        <div
            v-if="showPointerData"
            class="cornea2d__data data-grid"
            :style="{
                top: `${posY * DPR + 3}px`,
                left: `${posX * DPR + 3}px`,
            }"
        >
            <!-- <div
                v-if="kGeometry !== 'SYMMETRICAL'"
                class="data-grid col-span-2"
            >
                <template v-if="kGeometry === 'ASYMMETRICAL'">
                    <template
                        v-for="(angleName, j) in ['KFlat', 'KSteep']"
                        :key="j"
                    >
                        <div>
                            {{ angleName }}
                        </div>

                        <div class="flex justify-between items-center gap-1">
                            <div class="shrink-0">
                                {{ meridianAngles[j] }}°
                            </div>

                            <div
                                class="grow max-w-[40px] h-px"
                                :style="{ backgroundColor: meridianColors[j] }"
                            >
                            </div>
                        </div>
                    </template>
                </template>

                <template v-else-if="kGeometry === 'QUADRANT'">
                    <template v-for="(angle, j) in meridianAngles" :key="j">
                        <div>
                            Angle {{ j + 1 }}
                        </div>

                        <div class="flex justify-between items-center gap-1">
                            <div class="shrink-0">
                                {{ angle }}°
                            </div>

                            <div
                                class="grow max-w-[40px] h-px"
                                :style="{ backgroundColor: meridianColors[j] }"
                            >
                            </div>
                        </div>
                    </template>
                </template>
            </div>

            <div>
                Best Sphere
            </div>

            <div>
                {{ bsRad.toFixed(2) }}&nbsp;mm
            </div>

            <div>
                Apex
            </div>

            <div>
                {{ (apex * 1000).toFixed(0) }}&nbsp;µm
            </div> -->

            <div>
                <!-- @i18n -->
                Angle
            </div>

            <div>
                {{ pointed.angle.toFixed(2) }}°
            </div>

            <div>
                <!-- @i18n -->
                DIA
            </div>

            <div>
                {{ pointed.dia.toFixed(1) }}&nbsp;mm
            </div>

            <div>
                <!-- @i18n -->
                SAG
            </div>

            <div>
                {{ (pointed.sag * 1000).toFixed(0) }}&nbsp;µm
            </div>

            <div>
                <!-- @i18n -->
                Axial Radius
            </div>

            <div>
                {{ pointed.radius.toFixed(2) }}&nbsp;mm
            </div>

            <div>
                <!-- @i18n -->
                Axial Power
            </div>

            <div>
                {{ pointed.power.toFixed(2) }}&nbsp;dpt
            </div>

            <div>
                <!-- @i18n -->
                ∆ Best Sphere
            </div>

            <div>
                {{ pointed.bsSag > 0 ? '+' : '' }}{{ pointed.bsSag.toFixed(0) }}&nbsp;µm
            </div>

            <div>
                <!-- @i18n -->
                Tear Film
            </div>

            <div>
                {{ pointed.tearFilm.toFixed(0) }}&nbsp;µm
            </div>
        </div>
    </div>
</template>

<script setup>
/**
 * @typedef {import('@/composables/GraphComposable.js').Surface} Surface
 * @typedef {[x: number, y: number]} Point
 */

import { computed, nextTick, onMounted, ref, watch } from 'vue';
import useGraphComposable from '@/composables/GraphComposable';
import {
    bestSphereRadius,
    daSag,
    deg2rad,
    rad2deg,
    radiusDeltaSagEx,
} from '@/utils/Formulas';
import constants from '@/constants/constants';
import ColoringStrategy2D from './ColoringStrategy2D';
import AxialRadiusColoringStrategy2D from './AxialRadiusColoringStrategy2D';
import SagittalColoringStrategy2D from './SagittalColoringStrategy2D';
import BestSphereColoringStrategy2D from './BestSphereColoringStrategy2D';
import TearFilmColoringStrategy2D from './TearFilmColoringStrategy2D';
import Meridian from '@/models/Meridian';
import Parameter from '@/models/Parameter';


// ------------------------------------------------------------ CONSTANTS

/** The device pixel ratio. */
const DPR = devicePixelRatio;

/** The width of the cornea, in mm. */
const K_WIDTH = 14;

/** The height of the cornea, in mm. */
const K_HEIGHT = 14;

const step = 0.1;

const baseSquareSize = 2 * DPR;

/** The size of a rendered square, in px. */
// const squareSize = Math.round((baseSquareSize * DPR + 1) / 2) * 2 - 1;
const squareSize = baseSquareSize;

/** The width of the rendering context, in px. */
const canvasWidth = K_WIDTH / step * squareSize;

/** The height of the rendering context, in px. */
const canvasHeight = K_HEIGHT / step * squareSize;

const borderWidth = 1;

/** The width of the HTML canvas element, in px. */
const elementWidth = Math.ceil(canvasWidth / DPR) + 2 * borderWidth;

/** The height of the HTML canvas element, in px. */
const elementHeight = Math.ceil(canvasHeight / DPR) + 2 * borderWidth;

/** The number of squares on the horizontal (X) axis. */
const hSquares = canvasWidth / squareSize;

/** The number of squares on the vertical (Y) axis. */
const vSquares = canvasHeight / squareSize;

/**
 * The center of the canvas.
 * @type {Point}
 * @readonly
 */
const center = Object.freeze([
    Math.ceil(canvasWidth / 2),
    Math.ceil(canvasHeight / 2),
]);

/**
 * The coordinates of the central square.
 * @type {Point}
 * @readonly
 */
const sqCenter = Object.freeze(getSquareCoordinates(center));

const backgroundColor = '#888';

const meridianColors = [
    '#f0f',
    '#f00',
    '#0f0',
    '#0ff',
];

const DioK = 337.5;


// ------------------------------------------------------------ OPTIONS

defineOptions({
    inheritAttrs: false,
});


// ------------------------------------------------------------ PROPS

/**
 * @type {{
 *     corneaPoints:      Surface,
 *     backSurfacePoints: Surface,
 *     viewingAngle:      Number,
 *     coloringMode:      "AXIAL_RADIUS"|"SAGITTAL"|"BEST_SPHERE"|"TEAR_FILM",
 *     params:            Record<string, any>,
 *     showPointerData:   boolean,
 * }}
 */
const props = defineProps({
    corneaPoints:
    {
        type:     Array,
        required: true,
    },

    backSurfacePoints:
    {
        type:    Array,
        default: () => [],
    },

    viewingAngle:
    {
        type:    Number,
        default: NaN,
    },

    coloringMode:
    {
        type:      String,
        default:   'AXIAL_RADIUS',
        validator: value => [
            'AXIAL_RADIUS',
            'SAGITTAL',
            'BEST_SPHERE',
            'TEAR_FILM',
        ].includes(value),
    },

    params:
    {
        type:     Object,
        required: true,
    },

    showPointerData:
    {
        type:    Boolean,
        default: true,
    },
});


// ------------------------------------------------------------ COMPOSABLES

const {
    // Constants
    MIN_TEAR,

    // Methods
    getMeridian,
    getApexAlt360,
} = useGraphComposable();


// ------------------------------------------------------------ EMITS

const emit = defineEmits(['pointed']);


// ------------------------------------------------------------ DATA

/**
 * The `canvas` DOM element.
 * @type {import('vue').Ref<HTMLCanvasElement>}
 */
const graphArea = ref(null);

/**
 * The last drawing completed.
 * @type {import('vue').Ref<?HTMLImageElement>}
 */
const lastDrawing = ref(null);

/** Whether the cornea is currently being drawn. */
const drawingCornea = ref(false);

/** The X position of the square pointed to by the mouse cursor. */
const posX = ref(0);

/** The Y position of the square pointed to by the mouse cursor. */
const posY = ref(0);

/** @type {import('vue').Ref<?ColoringStrategy2D>} The selected coloring strategy. */
const coloringStrategy = ref(null);

/** The radius of the best sphere for this cornea. */
const bsRad = ref(0);

/** The maximum difference in elevation between the cornea and the lens. */
const apex = ref(0);


// ------------------------------------------------------------ COMPUTED

/**
 * The canvas' 2D drawing context.
 */
const canvasContext = computed(() => graphArea.value.getContext('2d'));

/**
 * The diameter of the corneal zone.
 */
const cornealDiameter = computed(() =>
{
    return props.corneaPoints[0].zones.CORNEAL.dia;
});

/**
 * KFlat is the angle of the first meridian.
 */
const kFlat = computed(() => props.corneaPoints[0].angle);

/**
 * KSteep is the angle of the second meridian.
 */
const kSteep = computed(() => props.corneaPoints[1].angle);

/**
 * The angles of each and every meridian of the cornea.
 */
const meridianAngles = computed(() => props.corneaPoints.map(m => m.angle));

const kGeometry = computed(() => props.params.EYE_CORNEA_GEOMETRY);

const lensDiameter = computed(() => props.params.LENS_DTOT);


/**
 * The values for the square being pointed at.
 */
const pointed = computed(() =>
{
    const [x, y] = [posX.value, posY.value];
    const angle = getSquareAngle([x, y]);
    const dia = getSquareDistance([x, y]) * 2;
    const meridian = getMeridian(props.corneaPoints, angle);
    const sag = dia > cornealDiameter.value
        ? 0
        : (meridian?.sag(dia) ?? 0);
    const radius = dia > cornealDiameter.value
        ? 0
        : radiusDeltaSagEx(sag, 0, 0, dia);
    const bsSag = dia > cornealDiameter.value || !bsRad.value
        ? 0
        : 1000 * -(daSag(bsRad.value, 0, 0, dia) - sag);
    const power = dia > cornealDiameter.value
        ? 0
        : DioK / radius;
    const clSag = dia > lensDiameter.value
        ? 0
        : (getMeridian(props.backSurfacePoints, angle)?.sag(dia) ?? 0);
    const tearFilm = dia > lensDiameter.value || dia > cornealDiameter.value
        ? 0
        : Math.round((sag - clSag + apex.value + MIN_TEAR) * 1000);

    return {
        apex:  apex.value ?? 0,
        bsRad: bsRad.value ?? 0,
        meridianColors,
        angle,
        dia,
        sag,
        radius,
        bsSag,
        power,
        clSag,
        tearFilm,
    };
});


// ------------------------------------------------------------ WATCHERS

watch(() => props.corneaPoints, init);

watch(() => props.backSurfacePoints, async() =>
{
    // The tear film visualization uses the back surface points, so we need to
    // refresh the drawing when those change.
    if(coloringStrategy.value instanceof TearFilmColoringStrategy2D)
    {
        init();
    }
    else if(props.corneaPoints.length > 0 && props.backSurfacePoints.length > 0)
    {
        apex.value = getApexAlt360(props.corneaPoints, props.backSurfacePoints);
    }
});

watch(() => props.params, () =>
{
    setBestSphereRadius();
});

watch(() => props.viewingAngle, async() => await onMouseMove());

watch(() => props.coloringMode, init);


// ------------------------------------------------------------ METHODS

/**
 * Initialize the component.
 * - Calculate the radius of the best sphere
 * - Calculate the apex
 * - Redraws the cornea from scratch
 * - Draws the HUD
 */
async function init()
{
    if(
        !props.params ||
        !Object.keys(props.params).length ||
        !props.corneaPoints.length ||
        !props.backSurfacePoints.length
    )
    {
        clearCanvas();
        return;
    }

    // Calculate the radius of the best sphere
    setBestSphereRadius();


    // Calculate the radius of the best sphere
    setBestSphereRadius();

    // Calculate the apex
    apex.value = getApexAlt360(props.corneaPoints, props.backSurfacePoints);

    // console.log({ K_WIDTH, K_HEIGHT, step, DPR, baseSquareSize,
    //     squareSize, canvasWidth, canvasHeight, elementWidth, elementHeight,
    //     hSquares, vSquares, apex: apex.value }); // @xxx

    lastDrawing.value = null;

    setupColoringStrategy();
    await drawCornea();
    await onMouseMove();
    emit('pointed', pointed.value);
}

function setBestSphereRadius()
{
    if(
        typeof props.params.EYE_CORNEA_RADIUS === 'undefined' ||
        typeof props.params.EYE_CORNEA_ECCENTRICITY === 'undefined'
    )
    {
        console.warn('setBestSphereRadius: missing required parameters');
        bsRad.value = 0;
        return;
    }

    bsRad.value = bestSphereRadius(
        props.params.EYE_CORNEA_DIAMETER,
        props.params.EYE_CORNEA_RADIUS,
        props.params.EYE_CORNEA_ECCENTRICITY,
        props.params.EYE_CORNEA_RADIUS_2,
        props.params.EYE_CORNEA_ECCENTRICITY_2,
        props.params.EYE_CORNEA_RADIUS_3,
        props.params.EYE_CORNEA_ECCENTRICITY_3,
        props.params.EYE_CORNEA_RADIUS_4,
        props.params.EYE_CORNEA_ECCENTRICITY_4
    );
}

/**
 * Convert from element coordinates to canvas coordinates.
 *
 * @param {Point} elementPoint
 * @returns {Point} The coordinates on the canvas.
 */
function getLocalCoordinates([relX, relY])
{
    return [
        Math.floor(relX / elementWidth * canvasWidth),
        Math.floor(relY / elementHeight * canvasHeight),
    ];
}

/**
 * Given a point in the canvas, get the coordinates of the top left corner of
 * one square.
 *
 * @param {Point} point The point in the canvas' coordinates system.
 * @returns {Point}
 */
function getSquareCoordinates([x, y])
{
    return [
        Math.floor(x / squareSize),
        Math.floor(y / squareSize),
    ];
}

/**
 * Given the coordinates of a square, get its angle.
 *
 * @param {Point} squarePosition
 * @returns {number} The angle in degrees.
 */
function getSquareAngle([x, y])
{
    const [cx, cy] = sqCenter;
    const [dx, dy] = [x - cx, y - cy];

    if(dx === 0 && dy < 0)
        return 90;

    if(dx === 0 && dy > 0)
        return 270;

    if(dy === 0 && dx > 0)
        return 0;

    if(dy === 0 && dx < 0)
        return 180;

    if(dy === 0 && dx === 0)
        return 0;

    const angle = rad2deg(Math.atan(dy / dx));

    if(dx >= 0 && dy < 0) // 1st quadrant
        return -angle;

    if(dx < 0 && dy < 0) // 2nd quadrant
        return 180 - angle;

    if(dx < 0 && dy > 0) // 3rd quadrant
        return 180 - angle;

    if(dx >= 0 && dy > 0) // 4th quadrant
        return 360 - angle;

    return angle;
}

/**
 * Given the coordinates of a square, get its distance from the center.
 *
 * @param {Point} squarePoint
 * @returns {number} The distance in millimeters.
 */
function getSquareDistance([x, y])
{
    const [cx, cy] = sqCenter;
    const [dx, dy] = [x - cx, y - cy];

    return Math.sqrt((dx * step) ** 2 + (dy * step) ** 2);
}

/**
 * Handler called whenever the mouse moves over the canvas.
 * - Redraws the cornea (from memory if possible)
 * - Draws the HUD
 * - Records pointer position (if `event` is not `null`)
 *
 * @param {?MouseEvent} event
 */
async function onMouseMove(event = null)
{
    const ctx = canvasContext.value;

    if(lastDrawing.value !== null)
    {
        // Render the last known cornea
        ctx.drawImage(lastDrawing.value, 0, 0);
    }
    else
    {
        // Render the cornea from scratch
        await drawCornea();
    }

    if(!drawingCornea.value)
    {
        // Draw horizontal and vertical lines passing through the center
        const centerOffset = squareSize / DPR;
        ctx.strokeStyle = '#000';
        ctx.lineWidth = 1;
        ctx.setLineDash([]);
        ctx.beginPath();
        ctx.moveTo(center[0] + centerOffset, 0);
        ctx.lineTo(center[0] + centerOffset, canvasHeight);
        ctx.moveTo(0, center[1] + centerOffset);
        ctx.lineTo(canvasWidth, center[0] + centerOffset);
        ctx.stroke();

        // Draw cornea meridians
        await drawCorneaMeridians();

        // Draw a line at the viewing angle
        if(!isNaN(props.viewingAngle))
        {
            ctx.strokeStyle = '#46f';
            ctx.lineWidth = 1 * DPR;
            ctx.setLineDash([10 * DPR, 5 * DPR]);
            await drawLineFromCenter(props.viewingAngle);
            await drawLineFromCenter((props.viewingAngle + 180) % 360);
        }
    }

    if(!event)
    {
        return;
    }

    // Record pointer coordinates
    const [x, y] = getLocalCoordinates([event.layerX, event.layerY]);
    const [sqx, sqy] = getSquareCoordinates([x, y]);
    [posX.value, posY.value] = [sqx, sqy];

    emit('pointed', pointed.value);

    // // Draw a red line from the center to the mouse pointer
    // ctx.strokeStyle = '#f00';
    // ctx.beginPath();
    // ctx.moveTo(center[0], center[1]);
    // ctx.lineTo(x, y);
    // ctx.stroke();
}

/**
 * Draw the 2D representation of the cornea.
 */
async function drawCornea()
{
    if(drawingCornea.value)
    {
        return;
    }
    drawingCornea.value = true;

    clearCanvas();
    const ctx = canvasContext.value;
    for(let y = 0; y < canvasHeight; y += squareSize)
    {
        for(let x = 0; x < canvasWidth; x += squareSize)
        {
            const [sqx, sqy] = getSquareCoordinates([x, y]);
            const dia = getSquareDistance([sqx, sqy]) * 2;
            const angle = getSquareAngle([sqx, sqy]);

            if(x === 0 && y % (25 * squareSize) === 0)
            {
                // Give the browser some time to render what's been drawn so far
                await (new Promise(r => setTimeout(r, 0)));
            }

            if(dia > cornealDiameter.value)
            {
                continue;
            }
            else
            {
                const meridian = getMeridian(props.corneaPoints, angle);
                ctx.fillStyle = coloringStrategy.value.getColor(meridian, dia);
            }
            ctx.fillRect(x, y, squareSize, squareSize);
        }
    }

    // Save the drawing
    const img = new Image();
    img.src = graphArea.value.toDataURL('image/png');
    lastDrawing.value = img;

    drawingCornea.value = false;
}

async function drawCorneaMeridians()
{
    const ctx = canvasContext.value;
    const length = cornealDiameter.value / K_WIDTH * canvasWidth / 2;

    const prevStrokeStyle = ctx.strokeStyle;
    const prevLineWidth = ctx.lineWidth;
    const prevLineDash = ctx.getLineDash();
    ctx.lineWidth = 1 * DPR;
    ctx.setLineDash([]);

    switch(kGeometry.value)
    {
        case 'SYMMETRICAL':
            // The angles don't matter; draw nothing.
            break;

        case 'ASYMMETRICAL':
            // Draw KFlat and KSteep as full-width axes
            for(let j = 0; j < 2; j++)
            {
                ctx.strokeStyle = meridianColors[j];
                const angle = meridianAngles.value[j];
                await drawLineFromCenter(angle, length);
                await drawLineFromCenter((angle + 180) % 360, length);
            }
            break;

        case 'QUADRANT':
            // Draw all four meridians
            for(let j = 0; j < 4; j++)
            {
                ctx.strokeStyle = meridianColors[j];
                const angle = meridianAngles.value[j];
                await drawLineFromCenter(angle, length);
            }
            break;
    }

    ctx.strokeStyle = prevStrokeStyle;
    ctx.lineWidth = prevLineWidth;
    ctx.setLineDash(prevLineDash);
}

/**
 * Draw a line from the center of the canvas.
 *
 * @param {number} degrees
 * @param {number} length
 */
async function drawLineFromCenter(degrees, length = canvasWidth)
{
    const radians = deg2rad(-degrees);

    const centerOffset = squareSize / DPR;
    const [cx, cy] = center.map(c => c + centerOffset);

    // Calculate the endpoints of the line using trigonometry
    const x2 = cx + length * Math.cos(radians);
    const y2 = cy + length * Math.sin(radians);

    // Draw the line
    const ctx = canvasContext.value;
    ctx.beginPath();
    ctx.moveTo(cx, cy);
    ctx.lineTo(x2, y2);
    ctx.stroke();
}

/**
 * Clear the entire canvas.
 */
function clearCanvas()
{
    const ctx = canvasContext.value;
    const { width, height } = graphArea.value;

    const prevFillStyle = ctx.fillStyle;
    ctx.fillStyle = backgroundColor;
    ctx.fillRect(0, 0, width, height);

    ctx.fillStyle = prevFillStyle;
}

/**
 * Initialize the coloring strategy based on the `coloringMode` prop.
 */
function setupColoringStrategy()
{
    switch(props.coloringMode)
    {
        case 'AXIAL_RADIUS':
            coloringStrategy.value = new AxialRadiusColoringStrategy2D();
            break;

        case 'SAGITTAL':
            coloringStrategy.value = new SagittalColoringStrategy2D();
            break;

        case 'BEST_SPHERE':
            coloringStrategy.value = new BestSphereColoringStrategy2D(
                bsRad.value
            );
            break;

        case 'TEAR_FILM':
            if(props.backSurfacePoints.length === 0)
            {
                coloringStrategy.value = new ColoringStrategy2D();
                console.warn('setupColoringStrategy: cannot render tear film without a back surface');
                return;
            }

            coloringStrategy.value = new TearFilmColoringStrategy2D(
                props.params.LENS_DTOT,
                props.corneaPoints,
                props.backSurfacePoints
            );
            break;

        default:
            coloringStrategy.value = new ColoringStrategy2D();
            break;
    }
}


// ------------------------------------------------------------ LIFECYCLE HOOKS

onMounted(init);
</script>

<style lang="scss" scoped>
.data-grid
{
    @apply grid grid-cols-[100px_80px] gap-x-2 gap-y-1;
}

.cornea2d
{
    @apply rounded-md overflow-hidden;
}

.cornea2d__data
{
    @apply absolute top-0 left-0 z-10 hidden rounded-md p-2
        bg-neutral-900 bg-opacity-85 text-white text-xs
        backdrop-blur-xs pointer-events-none;
}

.cornea2d:hover + .cornea2d__data
{
    @apply grid;
}
</style>
