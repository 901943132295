<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <lsn-table
        ref="returnTable"
        :data="returns"
        class="sl-returns"
    >

        <!-- Header -->
        <template #header>
            <lsn-table-cell class="sl-return-cell__date">
                {{ $t("return_table.headers.date") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-return-cell__reference">
                {{ $t("return_table.headers.reference") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-return-cell__sn">
                {{ $t("return_table.headers.serial_number") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-return-cell__article">
                {{ $t("return_table.headers.article") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-return-cell__patient-reference">
                {{ $t("return_table.headers.patient_reference") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-return-cell__return-reason">
                {{ $t("return_table.headers.return_reason") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-return-cell__credit">
                {{ $t("return_table.headers.credit") }}
            </lsn-table-cell>
        </template>

        <!-- Table Body -->
        <template #summary="{ item }">
            <return-line-summary :o-return="item" />
        </template>
    </lsn-table>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import LsnTable from '@/components/LsnTable/LsnTable.vue';
import LsnTableCell from '@/components/LsnTable/LsnTableCell.vue';
import ReturnLineSummary from '@/components/return/ReturnLineSummary.vue';

export default
{
    name: 'ReturnTable',

    components:
    {
        LsnTable,
        LsnTableCell,
        ReturnLineSummary,
    },

    props:
    {
        returns:
        {
            type:     Array,
            required: true,
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.sl-returns
{
    // Column width definitions
    --column-width-date:                100px;
    --column-width-reference:           100px;
    --column-width-sn:                  100px;
    --column-width-article:             220px;
    --column-width-patient-reference:   1fr;
    --column-width-return-reason:       120px;
    --column-width-credit:              100px;
}

.sl-returns.lsn-table:not(.lsn-table--selectable) :deep(> .lsn-table__header)
{
    grid-template-columns:
        var(--column-width-date)
        var(--column-width-reference)
        var(--column-width-sn)
        var(--column-width-article)
        var(--column-width-patient-reference)
        var(--column-width-return-reason)
        var(--column-width-credit);
}

.sl-returns.lsn-table:not(.lsn-table--selectable) :deep(> .lsn-table__body > .lsn-table__line > .lsn-table__line-summary)
{
    grid-template-columns:
        var(--column-width-date)
        var(--column-width-reference)
        var(--column-width-sn)
        var(--column-width-article)
        var(--column-width-patient-reference)
        var(--column-width-return-reason)
        var(--column-width-credit);

    @apply py-1;
}

.sl-returns :deep(.sl-return-cell__date)
{
    @apply text-center;
}

.sl-returns :deep(.sl-return-cell__reference)
{
    @apply text-center;
}

.sl-returns :deep(.sl-return-cell__sn)
{
    @apply text-center;
}

.sl-returns :deep(.sl-return-cell__article)
{
    @apply text-left;
}

.sl-returns :deep(.sl-return-cell__patient-reference)
{
    @apply text-left;
}

.sl-returns :deep(.sl-return-cell__return-reason)
{
    @apply text-left;
}

.sl-returns :deep(.sl-return-cell__credit)
{
    @apply text-right;
}

</style>
