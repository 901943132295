/**
 * @typedef {import('@/composables/GraphComposable.js').Surface}  Surface
 */

import { radiusDeltaSagEx } from '@/utils/Formulas';
import ColoringStrategy2D from './ColoringStrategy2D';
import useGraphComposable from '@/composables/GraphComposable.js';
import constants from '@/constants/constants';
import Meridian from '@/models/Meridian';

const DEFAULT_COLOR_SCHEME = 'OCULUS';

const colors = constants.colorScales.AXIAL_RADIUS;

/**
 * Color the squares using their equivalent axial radii.
 */
export default class AxialRadiusColoringStrategy2D extends ColoringStrategy2D
{
    /**
     * Get the color for the specified point.
     *
     * @param {Meridian} meridian The meridian being evalutated.
     * @param {number}   dia      The diameter being evaluated.
     * @param {string}   scheme   The color scheme to use.
     * @returns {string} The evaluated color code.
     */
    getColor(meridian, dia, scheme = DEFAULT_COLOR_SCHEME)
    {
        const sag = meridian.sag(dia);
        const rad = radiusDeltaSagEx(sag, 0, 0, dia);

        return colors[scheme].findLast(({ value }) => rad > value)?.color
            ?? '#fff';
    }
}
