export default class CheckReturnOrStop
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        /*
         * Check if there is anything that can
         * be processable to display lens return form.
         */

        if(this.v.leftReturn)
        {
            return true;
        }

        this.v.loading = false;

        return false;
    }
}
