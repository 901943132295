export default class Order
{
    id                          = null;
    ide                         = null;
    reference                   = null;
    serial_number               = null;
    odate                       = null;
    quantity                    = null;
    article_data                = {};
    logistics_data              = {};
    return_data                 = {};
    price                       = null;
    catalog_price               = null;
    total                       = null;
    catalog_total               = null;
    special_price               = null;
    currency_code               = null;
    dependency_code             = null;
    previous_order_reference    = null;
    ecom                        = null;
    order_by                    = null;
    delivery_to                 = null;
    entity_app_code             = null;
    user_id                     = null;
    user_app_code               = null;
    company_code                = null;
    order_class                 = null;
    extra                       = {};
    article                     = {};
    supplements                 = [];
    position                    = null;
    status                      = [];
    has_been_returned           = null;

    //-------------------------------------------------------------------------- CONSTRUCTOR

    constructor(attributes = {})
    {
        if(attributes)
        {
            this.setAttributes(attributes);
        }
    }

    //-------------------------------------------------------------------------- ATTRIBUTES

    setAttributes(attributes)
    {
        for(let key in attributes)
        {
            if(this[key] !== undefined)
            {
                this[key] = attributes[key];
            }
        }
    }

    //-------------------------------------------------------------------------- SETTERS

    setArticleDataAttribute(key, val)
    {
        this.article_data[key] = val;
    }

    setLogisticsDataAttribute(key, val)
    {
        this.logistics_data[key] = val;
    }

    setReturnDataAttribute(key, val)
    {
        this.return_data[key] = val;
    }

    setExtraAttribute(key, val)
    {
        this.extra[key] = val;
    }

    //-------------------------------------------------------------------------- GETTERS

    getArticleDataAttribute(key)
    {
        return this.article_data[key];
    }

    getLogisticsDataAttribute(key)
    {
        return this.logistics_data[key];
    }

    getReturnDataAttribute(key)
    {
        return this.return_data[key];
    }

    getExtraAttribute(key)
    {
        return this.extra[key];
    }
}
