import axios from "axios";

export default class CreateReturns
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        const returns = [];
        const rightReturn = this.v.$store.getters['lensReturn/getReturn']('right');
        const leftReturn  = this.v.$store.getters['lensReturn/getReturn']('left');

        // Set overlay text
        this.v.$store.commit('overlay/setText', this.v.$t('overlay.placing'));

        if(rightReturn)
        {
            returns.push(rightReturn.toApi());
        }

        if(leftReturn)
        {
            returns.push(leftReturn.toApi());
        }

        const data = {
            returns: returns,
            options: {},
        };

        let url = ('/api/return/:eid').replace(':eid', this.v.cEntity.id);

        return axios.post(url, data)
            .then(response =>
            {
                console.log('Creating OK !');
                console.log('ZZZ', response.data, response);
                this.v.$store.commit('lensReturn/setLastReturns', response.data);

                return Promise.resolve(response);
            })
            .catch(error =>
            {
                console.log('Creating error:', error);
                return Promise.reject(error);
            });
    }
}
