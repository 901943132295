<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="sl-form-dual__line-side" :class="`sl-form-dual__line-side-${side}`">

        <!-- Helper (grey vertical band) -->
        <div class="sl-form-dual__line-band-wrapper">
            <!-- Helper -->
            <div
                v-if="field.getHelper()"
                v-tippy="{ animation : 'fade' }"
                class="sl-form-dual__info-help"
                :content="field.getHelper()"
            >
                <mdi-icon icon="mdiInformationOutline" />
            </div>
        </div>

        <!-- Error Wrapper -->
        <div class="sl-form-dual__line-content-wrapper">

            <!-- Prefix -->
            <div v-if="field.getPrefix() !== null" class="sl-form-dual__prefix-wrapper">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div class="sl-form-dual__prefix" v-html="field.getPrefix()"></div>
            </div>

            <!-- Field Wrapper -->
            <div class="sl-form-dual__field-wrapper flex-col">
                <!-- Field -->
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div class="sl-form-dual__info px-4" v-html="field.getDisplayedValue()"></div>

                <!-- Error -->
                <div v-if="field.getError()" class="mt-1 text-red-500 text-xs text-center">
                    {{ field.getError() }}
                </div>
            </div>

            <!-- Suffix -->
            <div v-if="field.getSuffix() !== null" class="sl-order-form__suffix-wrapper">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div class="sl-order-form__suffix" v-html="field.getSuffix()"></div>
            </div>

            <!-- Note -->
            <div v-if="field.getNote()" class="sl-form-dual__note-wrapper">
                <div class="sl-form-dual__note">
                    {{ field.getNote() }}
                </div>
            </div>

        </div>

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

//import FormFieldAbstract from '@/components/forms/form-dual/form-sections/form-lines/FormFieldAbstract';

export default
{
    name: 'FormFieldInfo',

    //extends: FormFieldAbstract,

    props:
    {
        side:
        {
            type:     String,
            required: true,
        },

        field:
        {
            type:     Object,
            required: true,
        },
    },
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">

.sl-form-dual__select:focus
{
    @apply outline-none border-primary-400;
}

</style>
