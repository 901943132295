<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="sl-form-dual__line-side" :class="`sl-form-dual__line-side-${side}`">

        <!-- Helper (grey vertical band) -->
        <div class="sl-form-dual__line-band-wrapper">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="sl-form-dual__line-band-content" v-html="currentField.getHelper()"></div>
        </div>

        <!-- Error Wrapper -->
        <div class="sl-form-dual__line-content-wrapper" :class="{ 'sl-form-dual__line-content-wrapper--error': currentField.getError() }">

            <!-- Field Wrapper -->
            <div class="sl-form-dual__field-wrapper w-full relative">
                <!-- Field -->
                <textarea
                    ref="field"
                    v-model.lazy="tempValue"
                    class="sl-form-dual__textarea"
                    :name="name"
                    :tabindex="tabIndex"
                    rows="1"
                    :disabled="!currentField.isEnabled()"
                    autocomplete="off"
                    @focus="onFocus"
                    @blur="onBlur"
                    @input="checkIfHasScrollbar(); checkIfIsEmpty();"
                ></textarea>
                <!-- Clear Field Button -->
                <mdi-icon
                    v-if="!isEmpty"
                    v-tippy="{ content: $t('common.clear_field') }"
                    icon="mdiClose"
                    class="sl-form-dual__clear-button"
                    :class="{ 'sl-form-dual__clear-button--has-scrollbar': hasScrollbar }"
                    @click="clearField(true)"
                />
            </div>

            <!-- Supplement -->
            <div v-if="supplement" class="sl-form-dual__supplement-wrapper">
                <div class="sl-form-dual__supplement">
                    {{ supplement }}
                </div>
            </div>

            <!-- Note -->
            <div v-if="currentField.getNote()" class="sl-form-dual__note-wrapper">
                <div class="sl-form-dual__note">
                    {{ currentField.getNote() }}
                </div>
            </div>

            <!-- Error -->
            <div v-if="currentField.getError()" class="sl-form-dual__error-wrapper">
                <div class="sl-form-dual__error">
                    {{ currentField.getError() }}
                </div>
            </div>
        </div>

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

//import FormFieldAbstract from '@/components/forms/form-dual/form-sections/form-lines/FormFieldAbstract';

export default
{
    name: 'FormFieldLongText',

    //extends: FormFieldAbstract,

    props:
    {
        index:
        {
            type:     Number,
            required: true,
        },

        side:
        {
            type:     String,
            required: true,
        },

        field:
        {
            type:     Object,
            required: true,
        },
    },

    emits: ['update'],

    data()
    {
        return {
            isTouched:      false,
            isFocused:      false,
            hasScrollbar:   false,
            isEmpty:        true,
            resizeObserver: null,
            currentField:   this.field,
        };
    },

    computed:
    {
        tempValue:
        {
            get()
            {
                if(this.isFocused)
                {
                    return this.currentField.getValue();
                }
                else
                {
                    return this.currentField.getFormattedValue();
                }
            },

            set(value)
            {
                this.setValue(value);
            },
        },

        tabIndex()
        {
            return this.side === 'left' ? 2 : 1;
        },

        cIndex()
        {
            return this.items.findIndex(i => i.ovalue == this.currentField.getValue());
        },

        currentValue()
        {
            return this.currentField.getValue();
        },

        supplement()
        {
            // if(this.parameter.priced_article_code)
            // {
            //     const article = this.$store.getters['catalog/getByCode'](this.parameter.priced_article_code);
            //     if(article)
            //     {
            //         return `+ ${this.max2decimals(article.price)} ${article.currency_code}`;
            //     }
            // }

            return null;
        },

        name()
        {
            return this.side + '-' + this.currentField.getCode();
        },
    },

    watch:
    {
        field(field)
        {
            this.currentField = field;
        },
    },

    mounted()
    {
        this.checkIfIsEmpty();
        this.checkIfHasScrollbar();

        // Track scrollbar status on field resize
        this.resizeObserver = new ResizeObserver(this.checkIfHasScrollbar);
        this.resizeObserver.observe(this.$refs.field);
    },

    beforeUnmount()
    {
        // Stop tracking scrollbar status on field resize
        this.resizeObserver?.disconnect();
    },

    methods:
    {
        setValue(value)
        {
            this.isTouched = true;

            this.currentField.setValue(value);

            this.$emit('update', this.currentField.getCode(), this.currentValue, this.side);

            this.$nextTick(() =>
            {
                this.checkIfHasScrollbar();
                this.checkIfIsEmpty();
            });
        },

        onFocus()
        {
            this.isFocused = true;
        },

        onBlur()
        {
            this.isFocused = false;
            this.isTouched = true;
        },

        clearField(focus = false)
        {
            // Empty the field
            this.tempValue = null;

            if(focus)
            {
                // Focus the field
                this.$nextTick(() =>
                {
                    this.$refs.field?.focus();
                });
            }
        },

        checkIfHasScrollbar()
        {
            const elem = this.$refs.field;

            if(elem)
            {
                this.hasScrollbar = (elem.clientHeight < elem.scrollHeight);
            }
            else
            {
                this.hasScrollbar = false;
            }

            return this.hasScrollbar;
        },

        checkIfIsEmpty()
        {
            const fieldValue = this.$refs.field?.value;
            this.isEmpty = (typeof fieldValue === 'undefined' || fieldValue === null || fieldValue === '');

            return this.isEmpty;
        },
    },
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">

.sl-form-dual__textarea
{
    @apply pr-5;
}

.sl-form-dual__clear-button
{
    @apply cursor-pointer absolute top-1.5 right-1.5 w-4 h-4 text-gray-600 transition hover:text-primary-500;
}

.sl-form_dual__clear-button--has-scrollbar
{
    @apply right-5;
}

</style>
