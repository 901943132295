import { isEmpty } from 'lodash-es';

export const ID_TYPES = Object.freeze({
    LENS_SN:         'sn',
    LENS_OEM_NUMBER: 'oem',
    ORDER_REFERENCE: 'order-reference',
});

export default class LensReturnUrlManager
{
    lang = null;

    entity_id = null;

    identifierType =  null;

    orderIdentifier = null;

    returnBothLenses = false;

    action = null;

    queryParams = {};

    constructor(route = null)
    {
        if(route)
        {
            this.setFromRoute(route);
        }
    }

    setFromRoute(route)
    {
        this.setLanguage(route.params.lang);
        this.setEntity(route.params.entity_id);

        const lenses = route.params.lenses || [];
        this.parseLensesParams(lenses);

        // if(route.path.includes('return/lenses/edit'))
        // {
        //     this.setAction('edit');
        // }
        // else
        // {
        //     this.setAction(null);
        // }

        // this.queryParams = route.queryParams;
    }

    parseLensesParams(lenses)
    {
        /*
         * URL: .../en/1V1/return/lenses/sn/1234
         * URL: .../en/1V1/return/lenses/sn/1234/both
         * URL: .../en/1V1/return/lenses/order-reference/5678
         * URL: .../en/1V1/return/lenses/order-reference/5678/both
         * URL: .../en/1V1/return/lenses/oem/9876
         * URL: .../en/1V1/return/lenses/oem/9876/both
         */

        let startIndex = -1;

        for(let idType of Object.values(ID_TYPES))
        {
            if(lenses[0] == idType)
            {
                this.setIdentifierType(idType);
                startIndex = 0;
                break;
            }
        }

        /*
         * If :
         * - not order identifier type was given
         * - an order identifier type was given but without identifier
         * - if order identifier is missing (found "both")
         */
        if(startIndex == -1 || lenses.length < 2 || lenses[1] == 'both')
        {
            this.setIdentifierType(null);
            this.setOrderIdentifier(null);
            return;
        }

        // Set parameters from URL
        this.setIdentifierType(lenses[0]);
        this.setOrderIdentifier(lenses[1]);
        this.setReturnBothLenses(lenses.includes('both'));
    }


    // ------------------------------------------------------------ PATH

    getPath()
    {
        let parts = ['', this.lang, this.entity_id, 'return', 'lenses'];
        let params = [];

        if(this.action)
        {
            parts.push('edit');
        }

        if(this.has())
        {
            parts.push(this.getIdentifierType());
            parts.push(this.getOrderIdentifier());

            if(this.returnBothLenses)
            {
                parts.push('both');
            }
        }

        let url = parts.join('/');

        if(!isEmpty(this.queryParams))
        {
            for(const key in this.queryParams)
            {
                params.push(key + '=' + this.queryParams[key]);
            }

            url += '?' + params.join('&');
        }

        return url;
    }


    // ------------------------------------------------------------ LANGUGAGE

    setLanguage(lang)
    {
        this.lang = lang;
    }


    // ------------------------------------------------------------ ENTITY

    setEntity(hashedEid)
    {
        this.entity_id = hashedEid;
    }


    // ------------------------------------------------------------ ACTION

    setAction(action)
    {
        this.action = action;
    }


    // ------------------------------------------------------------ EXISTS

    has()
    {
        return this.getIdentifierType() !== null && this.getOrderIdentifier() !== null;
    }


    // ------------------------------------------------------------ ORDER IDENTIFIER

    hasOrderIdentifier()
    {
        return this.getOrderIdentifier() !== null;
    }

    getOrderIdentifier()
    {
        const orderIdentifier = this.orderIdentifier;

        if(orderIdentifier === null)
        {
            return null;
        }

        const type = this.getIdentifierType();

        switch(type)
        {
            // Integers
            case ID_TYPES.LENS_SN:
                return parseInt(orderIdentifier);

            // Strings
            case ID_TYPES.LENS_OEM_NUMBER:
            case ID_TYPES.ORDER_REFERENCE:
                return orderIdentifier;

            default:
                throw new RangeError(`Invalid order identifier type: "${type}"; raw value: ${orderIdentifier}`);
        }
    }

    setOrderIdentifier(identifier)
    {
        this.orderIdentifier = identifier;
    }

    getIdentifierType()
    {
        return this.identifierType;
    }

    setIdentifierType(identifierType)
    {
        this.identifierType = identifierType;
    }

    // ------------------------------------------------------------ LENS SN

    hasLensSn()
    {
        return this.getIdentifierType() === ID_TYPES.LENS_SN && this.getOrderIdentifier() !== null;
    }

    getLensSn()
    {
        return this.getOrderIdentifier();
    }

    setLensSn(sn)
    {
        this.setIdentifierType(ID_TYPES.LENS_SN);
        this.setOrderIdentifier(sn);
    }

    // ------------------------------------------------------------ LENS OEM NUMBER

    hasOemNumber()
    {
        return this.getIdentifierType() === ID_TYPES.LENS_OEM_NUMBER && this.getOrderIdentifier() !== null;
    }

    getOemNumber()
    {
        return this.getOrderIdentifier();
    }

    setOemNumber(oemNumber)
    {
        this.setIdentifierType(ID_TYPES.LENS_OEM_NUMBER);
        this.setOrderIdentifier(oemNumber);
    }


    // ------------------------------------------------------------ ORDER REFERENCE

    hasOrderReference()
    {
        return this.getIdentifierType() === ID_TYPES.ORDER_REFERENCE && this.getOrderIdentifier() !== null;
    }

    getOrderReference()
    {
        return this.getOrderIdentifier();
    }

    setOrderReference(orderReference)
    {
        this.setIdentifierType(ID_TYPES.ORDER_REFERENCE);
        this.setOrderIdentifier(orderReference);
    }


    // ------------------------------------------------------------ RETURN BOTH LENSES

    getReturnBothLenses()
    {
        return this.returnBothLenses;
    }

    setReturnBothLenses(returnBothLenses)
    {
        this.returnBothLenses = returnBothLenses;
    }

    // ------------------------------------------------------------ QUERY PARAMS

    bypassEntityCheck(bypass = true)
    {
        this.queryParams.force_current_entity = (bypass) ? 1 : 0;

        return this;
    }

    // ------------------------------------------------------------ RESET

    reset()
    {
        this.identifierType = null;
        this.orderIdentifier = null;
        this.returnBothLenses = false;
    }
}
