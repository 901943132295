<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-order-form__line-side" :class="'sl-order-form__line-side-' + side">
        <!-- Helper -->
        <div class="sl-order-form__line-band-wrapper">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="sl-order-form__line-band-content" v-html="helper"></div>
        </div>

        <!-- Error Wrapper -->
        <div class="sl-order-form__line-content-wrapper" :class="{ 'sl-order-form__field-error-wrapper--error': error }">
            <!-- Field Wrapper -->
            <div class="sl-order-form__field-wrapper">

                <!-- Field -->
                <select
                    v-model="prototypeCode"
                    class="sl-order-form__select"
                    :name="name"
                    :tabindex="tabIndex"
                    @change="onPrototypeCodeChange($event.target.value)"
                >
                    <option
                        v-for="opt in options"
                        :key="opt.code"
                        :value="opt.code"
                    >
                        {{ opt.label }}
                    </option>
                </select>
            </div>

            <!-- Note -->
            <div v-if="parameter.note" class="sl-order-form__note-wrapper">
                <div class="sl-order-form__note">
                    {{ parameter.note }}
                </div>
            </div>

            <!-- Error -->
            <div v-if="error" class="sl-order-form__error-wrapper">
                <div class="sl-order-form__error">
                    {{ error }}
                </div>
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import FormPrototype from '@/models/FormPrototype';
import LensOrderUrlManager from '../../LensOrderUrlManager';

export default
{
    name: 'SelectorField',

    props:
    {
        index:
        {
            type:     Number,
            required: true,
        },

        side:
        {
            type:     String,
            required: true,
        },

        parameter:
        {
            type:    Object,
            default: function()
            {
                return {};
            },
        },

        name:
        {
            type:     String,
            required: true,
        },

        error:
        {
            type:    String,
            default: null,
        },

        modelValue:
        {
            type:    [String, Number, Object, Boolean, Array],
            default: null,
        },
    },

    data()
    {
        return {
            prototypeCode: null,
        };
    },

    computed:
    {
        tabIndex()
        {
            return this.side === 'left' ? 2 : 1;
        },

        /**
         * @returns {FormPrototype}
         */
        prototype()
        {
            return this.$store.getters['lensOrder/getPrototype'](this.side);
        },

        options()
        {
            /** @type {FormPrototype[]} */
            const prototypes = this.$store.getters['prototypes/allByModelCode'](this.prototype.model_code);
            const options = [];

            prototypes.forEach(prototype =>
            {
                options.push({
                    label: prototype.variant_label || prototype.variant_code,
                    code:  prototype.code,
                });
            });

            return options;
        },

        helper()
        {
            return null;
        },
    },

    created()
    {
        this.$store.commit('lensOrder/setPrototypeValue', {
            side:       this.side,
            paramCode:  'LENS_VARIANT',
            paramValue: this.prototype.variant_code,
        });

        this.prototypeCode = this.prototype.code;
    },

    methods:
    {
        onPrototypeCodeChange(newPrototypeCode)
        {
            const newPrototype = this.$store.getters['prototypes/getByCode'](newPrototypeCode);
            this.$store.commit('lensOrder/setPrototypeValue', {
                side:       this.side,
                paramCode:  'LENS_VARIANT',
                paramValue: newPrototype.variant_code,
            });

            const lensOrderUrlManager = new LensOrderUrlManager(this.$route);
            lensOrderUrlManager.setPrototypeCode(this.side, newPrototypeCode);

            this.$router.replace({ path: lensOrderUrlManager.getPath() });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-order-form__select:focus
{
    @apply outline-none border-primary-400;
}
</style>
