import {
    sortBy,
} from 'lodash-es';
import FormLineSplit from "../form-lines/FormLineSplit";
import FormLineGrouped from "../form-lines/FormLineGrouped";
import FormLineInline from "../form-lines/FormLineInline";

export default class FormSection
{
    code = null;

    isOpen = true;

    parameters = {
        left:  null,
        right: null,
    };

    formLines = [];

    constructor(code)
    {
        this.code = code;

        this.parameters.left = new Map();
        this.parameters.right = new Map();
    }

    getCode()
    {
        return this.code;
    }

    addParameter(side, parameter)
    {
        this.parameters[side].set(parameter.code, parameter);
    }

    getLines()
    {
        return this.formLines;
    }

    render()
    {
        /**
         * There is two possible map entry :
         * 1) For "FormLineInline":
         *  - lineObject
         *  - sort_order
         * 2) For "SplitFormLine" or "FormLineGrouped":
         * - code (=> "parameter.code") => also Map Key
         * - sort_order
         */
        let orders = new Map();

        this.parameters.left.forEach(parameter =>
        {
            let parameterLineCode = parameter.line;

            // If current parametr is part of an "FormLineInline"
            if(parameterLineCode)
            {
                // If current parameter "FormLineInline" does not already exist
                if(!orders.has(parameterLineCode))
                {
                    orders.set(parameterLineCode, {
                        lineObject: new FormLineInline(parameterLineCode),
                        sort_order: parameter.sort_order,
                    });
                }

                // Add current parameter to the corresponding "FormLineInline"
                orders.get(parameterLineCode).lineObject.addParameter('left', parameter);
            }
            else // Current parameter is part of a "SplitFormLine" or "FormLineGrouped"
            {
                orders.set(parameter.code, {
                    code:       parameter.code,
                    sort_order: parameter.sort_order,
                });
            }
        });

        this.parameters.right.forEach(parameter =>
        {
            let parameterLineCode = parameter.getLine();

            // If current parametr is part of an "FormLineInline"
            if(parameterLineCode)
            {
                // If current parameter "FormLineInline" does not already exist
                if(!orders.has(parameterLineCode))
                {
                    orders.set(parameterLineCode, {
                        lineObject: new FormLineInline(parameterLineCode),
                        sort_order: parameter.sort_order,
                    });
                }

                // Add current parameter to the corresponding "FormLineInline"
                orders.get(parameterLineCode).lineObject.addParameter('right', parameter);
            }
            else // Current parameter is part of a "SplitFormLine" or "FormLineGrouped"
            {
                orders.set(parameter.code, {
                    code:       parameter.code,
                    sort_order: parameter.sort_order,
                });
            }
        });

        orders = Array.from(orders.values()); // Map to array
        orders = sortBy(orders, 'sort_order');

        console.log('Section parameters order:', orders);

        let formLines = [];

        for(let order of orders)
        {
            let formLine;

            if(order.lineObject)
            {
                formLines.push(order.lineObject);
                continue;
            }

            let leftParameter = this.parameters.left.get(order.code);
            let rightParameter = this.parameters.right.get(order.code);

            if(leftParameter && rightParameter)
            {
                if((['Y', 'F']).includes(leftParameter.is_grouped) && (['Y', 'F']).includes(rightParameter.is_grouped))
                {
                    // Group parameters if both the grouping keys and the values are identical
                    const groupingKeysMatch = leftParameter.grouping_key === rightParameter.grouping_key;
                    const valuesMatch = leftParameter.getValue() === rightParameter.getValue();

                    if(groupingKeysMatch && valuesMatch)
                    {
                        formLine = new FormLineGrouped(leftParameter, rightParameter);
                        formLines.push(formLine);
                        continue;
                    }
                }

                formLine = new FormLineSplit(leftParameter, rightParameter);
                formLines.push(formLine);
                continue;
            }

            if(leftParameter)
            {
                formLine = new FormLineSplit(leftParameter, null);
                formLines.push(formLine);
                continue;
            }

            if(rightParameter)
            {
                formLine = new FormLineSplit(null, rightParameter);
                formLines.push(formLine);
                continue;
            }
        }

        console.log('Form lines:', formLines);

        for(let cFormLine of formLines)
        {
            cFormLine.render();
        }

        this.formLines = formLines;
    }
}
